

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../styles/addhomework.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL } from "../../App";






function EditFile () {
  const navigate = useNavigate();
  const {id} = useParams();


  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
 
});


  const getFile = async () => {
    try {
  
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/materials/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
        });
        setEdit(response.data.data[0]?.attributes)
        console.log(response.data.data);
   
   const data = response.data.data;
                 return data;
    } catch (error) {
        console.log(error);
    }
  }
  
  
  useEffect (() =>{
  getFile();
  }, [])
  
  const [errors, setErrors] = useState("");
  const [edit , setEdit] = useState({
    title: "",
    cdn: "",
    week_id: "",
  });


  
  

  const handleEdit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("title", edit.title);
      formData.append("cdn", edit.cdn);
      formData.append("week_id", edit.week_id);
        await axiosInstance.post(`/api/update-material/${id}`, 
        formData
        ,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
                "ngrok-skip-browser-warning": "any",
            }
          
        });
       setEdit({
        title: "",
        cdn: "",
        week_id: "",
       })
         
       swal({
        title: "تم تعديل الملف بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
      navigate("/admin/filedata")
    } catch(e) {
        if(e.response.status === 500) {
       
            setErrors(e.response.data.errors);
            console.log(e);   
        }
    }
}


// const [file , setFile] = useState([]);
// const getEditFile = async () => {
//   try {
//     const token = localStorage.getItem('token');
//     const response = await axiosInstance.get('/api/weeks-selection' , {
//         headers: {
//           'Content-Type': 'application/json',
//           "ngrok-skip-browser-warning": "any",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       // console.log(response.data.data.map(item => item.id))
//       console.log(response.data.data);
//       setFile(response.data.data)
//       const data = response.data.data;
//                return data;
//   } catch (error) {
//       console.log(error);
//   }
// }

// useEffect (() =>{
//   getEditFile();
// }, [])


    
  
   
    return (
        <>
                  <div className="d-flex">
    
       <div className="add-homework ">
      
      <div className="main-title"> تعديل ملف </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                       <div className="codes pt-5">
                      
                        <div className="create-box">
                        <h2 className="create mb-4">  تعديل الملف </h2>
                        <Form onSubmit={handleEdit}> 
        

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group className="mb-3" controlId="formBasicText">
        <Form.Label className="ms-2">اسم الملف</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الملف" name="title" value={edit.title} onChange={(e) => setEdit((prev) =>({...prev, title: e.target.value}))}/>
      </Form.Group>
      
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label> الملف</Form.Label>
        <Form.Control type="file" name="cdn"   onChange={(e) => setEdit((prev) =>({...prev, cdn: e.target.files[0]}))}/>
      </Form.Group>
     </div>
     {/* <Form.Select aria-label="Default select example" value={edit.week_id} name="week_id" onChange={(e) => setEdit((prev) =>({...prev, week_id: e.target.value}))}> 
      <option>اختر اسم الحصة </option>
      {
        file.map(item => (
          <option key={item.id} value={item.id} name="week_id">{item.name}</option>
        ))
      }
    </Form.Select> */}
   
  
   
        

      <Button variant="primary" type="submit" className="create-button">
        تعديل
      </Button>
    </Form>
                        </div>
                  
                        </div> 
              </div>
                   </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
        
    )
}

export default EditFile;


