


import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate, useNavigation } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { BASE_URL } from '../../App';
import '../../styles/registeruser.css'


function Editstudent() {

    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    // const [fname, setFname] = useState("");
    // const [lname, setLname] = useState("");
    // const [pphone, setPphone] = useState("");
    // const [phone, setPhone] = useState("");
    // const [level, setLevel] = useState("");
    // const [government, setGovernment] = useState("");
    // const [password_confirmation, setPassword_confirmation] = useState("");
    // const [errors, setErrors] = useState("");
    // const [edit , setEdit] = useState("")
    const { id } = useParams();
    const navigate = useNavigate();
  
    const [errors, setErrors] = useState({});
    const [edit, setEdit] = useState({
      fname: "",
      lname: "",
      email: "",
      password: "",
      pphone: "",
      phone: "",
      level: "",
      government: "",
      password_confirmation: "",
    });
  
    const token = localStorage.getItem("token");
    const axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  
    const getStudent = async () => {
      try {
        const response = await axiosInstance.get(`/api/user/${id}`);
        setEdit(response.data.data.attributes);
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      getStudent();
    }, []);
  
    const handleEditStudent = async (event) => {
      event.preventDefault();
  
      try {
        await axiosInstance.patch(`/api/user/${id}`, edit);
        setEdit({
          fname: "",
          lname: "",
          email: "",
          password: "",
          pphone: "",
          phone: "",
          level: "",
          government: "",
          password_confirmation: "",
        });
        swal({
          title: "تم تعديل حساب الطالب بنجاح!",
          icon: "success",
          button: "حسناّ!",
        });
        navigate("/admin/allstudentdata");
      } catch (error) {
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        } else {
          console.log(error);
        }
      }
    };
  
    const handleChange = (event) => {
      setEdit({ ...edit, [event.target.name]: event.target.value });
    };

  
      
    return (
        <>
        <div className="register-user pt-5 pb-5">
        <div className="main-title">تعديل الحساب</div>
            <Container>
                
                <Row>
                    <Col lg={12}>
                        <div className="box mt-2">
                        <Form onSubmit={handleEditStudent}>
                    <div className="input-group">
                    <Form.Group className="mb-3 big-input" controlId="formBasicText">
        <Form.Label>الأسم الأخير</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الأخير" name="lname" value={edit.lname}  onChange={handleChange}/>
        <Form.Text className="text-muted">
        {errors.lname && (
                                <div className="error">{errors.lname[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    <Form.Group className="big-input mb-3" controlId="formBasicText" >
        <Form.Label>الأسم الأول</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الأول" name="fname" value={edit.fname}  onChange={handleChange}/>
        <Form.Text className="text-muted">
        {errors.fname && (
                                <div  className="error">{errors.fname[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
  
                    </div>
                    <div className="input-group">
                    <Form.Group className="mb-3 big-input" controlId="formBasicPhone" >
        <Form.Label>رقم تليفون ولي الأمر</Form.Label>
        <Form.Control type="number" placeholder="ادخل  رقم تليفون ولي الأمر" value={edit.pphone} name="pphone" onChange={handleChange}/>
        <Form.Text className="text-muted">
        {errors.pphone && (
                                <div className="error">{errors.pphone[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3 big-input" controlId="formBasicPhone" >
        <Form.Label>رقم تليفون الطالب</Form.Label>
        <Form.Control type="number" placeholder="ادخل  رقم تليفون الطالب" value={edit.email}  name="email"  onChange={handleChange}/>
        <Form.Text className="text-muted">
        {errors.email && (
                                <div  className="error">{errors.email[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    </div>
                    <Form.Group className="mb-3 w-100" controlId="formBasicPassword">
        <Form.Label> البريد الألكتروني للطالب</Form.Label>
        <Form.Control type="email"  required  placeholder="ادخل البريد الألكتروني" value={edit.phone} name="phone"  onChange={handleChange}/>
        <Form.Text className="text-muted">
        {errors.phone && (
                                <div  className="error">{errors.phone[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
       <div className="input-group">
                    <Form.Group className="mb-3 big-input" controlId="formBasicPassword">
                    <Form.Label>برجاء اختيار المحافظة</Form.Label>
                    <Form.Select aria-label="Default select example" name="government" value={edit.government} onChange={handleChange}>

      <option >اختر المحافظة</option>
 <option value="القاهرة">القاهرة</option>
 <option value="الجيزة">الجيزة</option>
<option value="الإسكندرية">الإسكندرية</option>
<option value="الإسماعيلية">الإسماعيلية</option>
<option value="كفر الشيخ">كفر الشيخ</option>
<option value="أسوان">أسوان</option>
<option value="أسيوط">أسيوط</option>
<option value="الأقصر">الأقصر</option>
<option value="الوادي الجديد">الوادي الجديد</option>
<option value ="شمال سيناء">شمال سيناء</option>
<option value="البحيرة">البحيرة</option>
<option value="بني سويف">بني سويف</option>
<option value="بورسعيد">بورسعيد</option>
<option value="البحر الأحمر">البحر الأحمر</option>
<option value="الدقهلية">الدقهلية</option>
<option value="جنوب سيناء">جنوب سيناء</option>
<option value="دمياط">دمياط</option>
<option value="سوهاج">سوهاج</option>
<option value="السويس">السويس</option>
<option value="الشرقية">الشرقية</option>
<option value="الغربية">الغربية</option>
<option value="الفيوم">الفيوم</option>
<option value="القليوبية">القليوبية</option>
<option value="قنا">قنا</option>
<option value="مطروح">مطروح</option>
<option value="المنوفية">المنوفية</option>
<option value="المنيا">المنيا</option>
    </Form.Select>
    <Form.Text className="text-muted">
        {errors.government && (
                                <div  className="error">{errors.government[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
                    <Form.Group className="big-input mb-3" controlId="formBasicEmail">
                    <Form.Label>برجاء اختيار السنة الدراسية</Form.Label>
                    <Form.Select aria-label="Default select example" name="level" value={edit.level} onChange={handleChange}>
                    <option >اختر السنة الدراسية</option>
                          <option  value="1">الصف الأول الثانوي</option>
                           <option value="2">الصف الثاني الثانوي</option>
                           <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
    <Form.Text className="text-muted">
        {errors.level && (
                                <div  className="error">{errors.level[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
  
                    </div>
                    <div className="input-group">
                    <Form.Group className="mb-3 big-input" controlId="formBasicPassword">
        <Form.Label> تأكيد كلمة المرور</Form.Label>
        <Form.Control type="password" placeholder="تأكيد كلمة المرور" name="password_confirmation" onChange={handleChange}/>
        <Form.Text className="text-muted">
        <Form.Text className="text-muted">
        {errors.password_confirmation && (
                                <div  className="error">{errors.password_confirmation[0]}</div>
                            )}

        </Form.Text>
        </Form.Text>
      </Form.Group>
                    <Form.Group className="big-input mb-3" controlId="formBasicEmail">
        <Form.Label>كلمة المرور</Form.Label>
        <Form.Control type="password" placeholder="ادخل  كلمة المرور" name="password"  onChange={handleChange}/>
        <Form.Text className="text-muted">
        <Form.Text className="text-muted">
        {errors.password && (
                                <div  className="error">{errors.password[0]}</div>
                            )}
        </Form.Text>
        </Form.Text>
      </Form.Group>
  
                    </div>
     
      <Button variant="primary" className="mt-3 mb-3"  type="submit">
        تعديل الحساب
      </Button>
      {/* <Link to="/loginuser">! لديك حساب بالفعل</Link> */}
    </Form>
 
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
    
}

export default Editstudent;


