import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import { BASE_URL } from "../App";
import "../styles/homepage.css";
import ScrollTopButton from "./ScrollTop";
import ReactConfetti from "react-confetti";

function Homepage({ loggedIn, auth }) {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // ريكويست الخاص بصفحة homepage

    const fetchDetails = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/level`, {
          method: "GET",
          headers: config.headers,
        });
        const data = await response.json();
        setDetails(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDetails();
  }, []);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <div className="landing d-flex align-items-center justify-content-center">
        <Container>
          {/* <img className="ramadan " src="/images/3596144-removebg-preview (1).png" alt=""/> */}

          <Row className="align-items-center">
            <Col lg={5}>
              <div className="image">
                <img
                  src="/images/Mohamedsaad.png"
                  className="animate-image"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={7}>
              <h1 className="type">
                <Typewriter
                  loop
                  cursor
                  cursorStyle="_"
                  typeSpeed={200}
                  deleteSpeed={100}
                  delaySpeed={4000}
                  words={["Mr/ Mohamed Saad "]}
                />
              </h1>
              <div className="text">
                <h3>
                  منصة كنوز في الرياضيات <span>للثانوية العامة</span>
                </h3>
                {loggedIn ? (
                  <Link to="/numberphone" className="d-block text-center sub-2">
                    تواصل معنا
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </Link>
                ) : (
                  <>
                    <Link
                      to={"/registeruser"}
                      className="d-block text-center sub"
                    >
                      !أشترك الأن
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </Link>
                    <Link
                      to="/numberphone"
                      className="d-block text-center sub-2"
                    >
                      تواصل معنا
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </Link>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="feature pt-5">
        <Container>
          <div className="btn-feature text-center">
            <button class="button-85 mb-5 " role="button">
              {" "}
              مميزات المنصة
            </button>
          </div>
          <Row className="">
            <Col lg={4}>
              <div className="boxl-1">
                <div className="box">
                  <img src="/images/4891584.jpg" alt="" />
                </div>
                <h2>وفر وقت المواصلات والسنتر</h2>
              </div>
            </Col>
            <Col lg={4}>
              <div className="boxl-2">
                <div className="box">
                  <img src="/images/8177774.jpg" alt="" />
                </div>
                <h2>متابعة متواصلة مع ولي الأمر</h2>
              </div>
            </Col>
            <Col lg={4}>
              <div className="boxl-3">
                <div className="box">
                  <img src="/images/106771-OMUS26-706.jpg" alt="" />
                </div>
                <h2>احضر امتحانات دورية ومستمرة</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="main-courses">
        <div className="waves">
          <h2>الحصص</h2>
          <div className="wave wave-1"></div>
          <div className="wave wave-2"></div>
          <div className="wave wave-3"></div>
          <div className="wave wave-4"></div>
        </div>
        <h2 className="edu">
          الصفوف <span>الدراسية</span>
        </h2>
        <div className="courses">
          <Container>
            <Row>
              {details.map((props) => {
                return (
                  <Col lg={4} md={12} key={props.id}>
                    <div className="card">
                      <div className="lines"></div>
                      <div className="imgBx">
                        <img src="/images/5682633.jpg" alt="" />
                      </div>
                      <div className="content">
                        <div className="details">
                          <h2>
                            الصف <span>{props.title}</span> الثانوي
                          </h2>

                          <div className="data">
                            <span className="data-content">
                              <span>{props.weeks}</span> :عدد الحصص
                            </span>
                            <span className="data-content">
                              <span>{props.videos}</span> :عدد الفيديوهات
                            </span>
                            <span className="data-content">
                              <span>{props.questions}</span> :عدد الأسئلة في
                              جميع الحصص
                            </span>
                            <span className="data-content">
                              <span>{props.quizzes}</span> :عدد الكويزات
                            </span>
                            <span className="data-content">
                              <span>{props.homeworks}</span> :عدد الواجبات
                            </span>
                          </div>
                          <div className="actionBtn">
                            {loggedIn ? (
                              <>
                                {auth.level === props.id ? (
                                  <Link className="last" to={`/mycourses`}>
                                    مشاهدة الكورسات
                                  </Link>
                                ) : (
                                  <Link className="last">مشاهدة الكورسات</Link>
                                )}
                              </>
                            ) : (
                              <>
                                <Link className="first" to="/registeruser">
                                  اشترك الأن
                                </Link>
                                <Link
                                  className="last"
                                  to={`/courses/${props.id}`}
                                >
                                  مشاهدة الكورسات
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </div>

      <ScrollTopButton />
    </>
  );
}

export default Homepage;
