import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../App";
import "../../styles/toprated.css"
import ReactConfetti from "react-confetti";
import swal from "sweetalert";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";



function TopRatedQuiz() {
    const {id} = useParams()

    const [rate , setRate] = useState([]);
    const [title , setTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
  
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        'Authorization': `Bearer ${token}`,
      }
    };       
  
         axios.get(`${BASE_URL}/api/top-rated-in-quiz/${id}`, config)
   
      .then(response => {
        setRate(response.data[1]);
        setTitle(response.data[0]);
        // console.log(response.data);
          if(response.data[0].myDegree >= response.data[0].prizeDegree) {
            swal({
              title: "❤️ عااااااش ",
              text: `${response.data[0].prize === null ? "" : `مبروك كسبت معانا 
              ${response.data[0].prize} ` } 
                   بالتوفيق ودائماّ من الأوائل والمتفوقين`,
               icon: "success",
              button: "حسناَ",
              dangerMode: true,
              content: 'text-align-center' 
            });      
           }
           else if(response.data[0].myDegree === 0){

            
           }
           else {
            swal({
              title: " 😠 لازم نذاكر اكتر",
              text: " المستر بيقولك المره الجاية لازم تبقي من الأوائل",
              icon: "warning",
              button: "حاضر",
              dangerMode: true,

            });
           }
      })

      .catch(error => {
        // console.log(error);
      })
      
      .finally(() => {
        setIsLoading(false);
      });

  }, []);


  const [auth , setAuth] = useState("")

  const getToken = async () => {
         try {
           const token = localStorage.getItem('token');
             const response = await axios.get(`${BASE_URL}/api/Auth` , {
               headers: {
                 'Content-Type': 'application/json',
                 "ngrok-skip-browser-warning": "any",
                 Authorization: `Bearer ${token}`,
               },
             }
             );
             setAuth(response.data)

         } 
         catch (error) {
         }
       }
     useEffect(() => {
      getToken()
     }, []);




  // console.log(rate);

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Africa/Cairo',
        numberingSystem: 'arab'
      };
      const formatter = new Intl.DateTimeFormat('ar-EG', options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    } catch (e) {
      return '';
    }
  }



  // const [dimensions, setDimensions] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // useEffect(() => {
  //   function handleResize() {
  //     setDimensions({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // const confettiOptions = {
  //   numberOfPieces: 100,
  //   recycle: true,
  //   gravity: 0.03,
  //   style: { width: '100%' },
  // };

  // if (dimensions.width <= 768) {
  //   confettiOptions.width = dimensions.width;
  //   confettiOptions.height = dimensions.height;
  // } else {
  //   confettiOptions.width = dimensions.width;
  //   confettiOptions.height = dimensions.height;
  
  // }
    
  // const [rowsToShow, setRowsToShow] = useState(30);

  // const handleShowMore = () => {
  //   setRowsToShow(rowsToShow + 30);
  // };


  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const displayRates = rate
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((rated, index) => (
      <tr key={rated.id}>
        <td>{index + pagesVisited + 1}</td>
        <td>{rated.id}</td>
        <td>{rated.name}</td>
        <td>{rated.score}</td>
        <td>{rated.government}</td>
        <td style={{ width: '50%' }}>{`${formatDate(rated.time)}`}</td>
      </tr>
    ));

  const pageCount = Math.ceil(rate.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const [showCongrats, setShowCongrats] = useState(false);

  const handleChestClick = () => {
    setShowCongrats(true);
  };

  const handleOverlayClick = () => {
    setShowCongrats(false);
  };
    return(
        <>
        {
            isLoading
             ?
             <div className="main-loading">
             <div className="loading-course"> 
           <h2>  .... جاري تحميل بيانات الأوائل</h2>
           </div>; 
           </div>
             :
<>

        <div className="TopRated pt-5 pb-5">
     
   
        <div className="react-confetti-container">
      <ReactConfetti
        numberOfPieces={120}
        recycle={true}
        width={window.innerWidth}
        height={window.innerHeight}
        gravity={0.04}
      />
    </div>      
    <Container>
                <Row>
                    <Col lg={12}>
                 
                <div className="btn-topRated mb-5">
                <button class="button-85" role="button"> أوائل {title.title}</button>
                </div>

                  

              
                        <div className="table-rated">

              {
                auth.role > 0
                ?
                <CSVLink
                    data = {rate}
                    filename = "الطلاب الممتحنين"
                    className = "btn btn-success mb-3 mt-4 me-2" > Export File Excel </CSVLink> 
                    :
                    ""
              }
                              {showCongrats && (
        <div className="box-surprize"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "1rem",
            // boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)",
            zIndex: 100,
          }}
        >
        
            <h1 className="title-surprize" style={{ textAlign: "center" }}> <span className="d-block">{title.prize}</span></h1>
               
            <button
          className="surprize"
            style={{ display: "block", margin: "auto", marginTop: "2rem" }}
            onClick={handleOverlayClick}
          >
            إغلاق
          </button>
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 99,
          display: showCongrats ? "block" : "none",
        }}
        onClick={handleOverlayClick}
      ></div>
            {
              title.prize !== null
              ?
              <div className="div-surprize">
      <button className="surprize" onClick={handleChestClick}> دوس هنا علشان تشوف الجائزة</button>
      </div>
      :
      ""
            }

<Table striped bordered hover responsive style={{ overflowY: 'scroll' }}>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>اسم الطالب</th>
            <th>الدرجة</th>
            <th>المحافظة</th>
            <th>وقت دخول الكويز</th>
          </tr>
        </thead>
        <tbody>{displayRates}</tbody>
      </Table>
      <ReactPaginate
        previousLabel={'السابق'}
        nextLabel={'التالي'}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={'pagination'}
        previousLinkClassName={'page-link'}
        nextLinkClassName={'page-link'}
        disabledClassName={'disabled'}
        activeClassName={'active'}
      />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
</>
        }
       
        </>
    )

}


export default TopRatedQuiz;