
import axios from "axios";
import { useState } from "react";
          import { useEffect } from "react";
          import { Col, Container, Row } from "react-bootstrap";
          import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
          import '../../styles/years.css'
import ScrollTopButton from "../ScrollTop";




          function MyCourses({loggedIn ,setLoggedIn}) {
            const navigate = useNavigate();
            const [cards , setCards] = useState([]);
            const [levelTitle , setLevelTitle] = useState("");
            const [isLoading, setIsLoading] = useState(true);


            // الريكويست الخاص بعرض الكورسات 
  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        'Authorization': `Bearer ${token}`,
      }
    };

    try {
      const response = await axios.get(`${BASE_URL}/api/auth-weeks`, config);
      setLevelTitle(response.data);
      setCards(response.data.data);
      setIsLoading(false); 
    } catch (error) {
      setIsLoading(false); 
    }
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
  });

  const handleBuyCourses = async (id) => {
    try {
        await axiosInstance.get(`/api/user/enrollWeek/${id}`)
        .then(response => {
          // console.log(response);
          setCards(response.data.data);
          swal({
            title: "تم شراء الكورس   بنجاح!",
            icon: "success",
            button: "حسنا!",
          });
          navigate(`/contentcourse/${id}`)
        })
        
    } catch(e) {
        setIsLoading(false); 
        if(e.response.status === 404) {
          swal({
            title: "!رصيد غير كافي برجاء شحن المحفظة ",
            icon: "warning",
            button: "حسنا!",
          });
        }
    }
  }


            function formatDate(dateString) {
              try {
                const date = new Date(dateString);
                const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
                const formatter = new Intl.DateTimeFormat('ar-EG', options);
                const formattedDate = formatter.format(date);
                return formattedDate;
              } catch (e) {
                // console.error('Error formatting date', e);
                return '';
              }
            }

            if (isLoading) {
              return <div className="main-loading">
                <div className="loading-course"> 
              <h2>  <span>.</span><span>.</span><span>.</span><span>.</span> جاري تحميل كورساتك</h2>
              </div>
              </div>
            }

        

              return (
                  <>  
                  {
                    cards.length > 0
                    ?
                    <div className="grades mt-5 ">
                    {/* {
                      loggedIn ? 
                        <div></div>
                      :
                      <h2 className="main-title">{levelTitle.message}</h2>
                    } */}
                  <div className="grade  pb-5 mb-5 mt-4">
                    
                    <div className="container">
                      <Row>
                  {
                    cards.map((props) => {
                      return(
                        <>
                   <Col lg={4} md={6} key={props.id} className="mb-5">
                     <div className="main">
                        <div className="card shadow2">
                            <div className="images">
                          <img src={`${Image_URL}/storage/${props.attributes.img}`} alt=""/>
                          {/* <img src="/images/f1b7a8ca-a93c-416a-9784-370e97e1e0c0.jpg" alt=""/> */}
                          {/* <img src="/images/Logo.jpeg" alt=""/> */}
                        </div>
                        <div className="details mt-4">
                        <h2>{props.attributes.name}</h2>
                        <div className="line"></div>
                        <p>{props.attributes.description}</p>
                        </div>
                        <div className="price-date">

                            <div className="dateandbox">
                            <div className="test">
                         {
                                props.attributes.price === "0"
                                ?
                                <div className="subed">
                                           ! هذا الكورس مجاني
                                  </div>
                               
                                  :
                                props.attributes.Owned === "false" 
                                ?  
                                <div className="price-big">
                                <span className="price">{props.attributes.price}.00</span>
                                <span className="ms-3">جنيهاَ</span>
                                </div>   
                              :  
                              <div className="subed">
                                      أنت مشترك في هذا الكورس
                                                       !
                                </div>

                              }
                         </div>

              <div className="dates ms-2">
                  <span className="date d-flex">
                      {formatDate(props.attributes.created_at)}
                    <img src="/images/email.png" alt=""/>
                    </span>
                  <span className="date d-flex mt-1">
                  {formatDate(props.attributes.updated_at)}
                    <img src="/images/add-friend.png" alt=""/>
                    </span>
                </div>    
                            </div>
                     <div className="btns">
           {
            props.attributes.Owned === "false"
            ?
<Link className="sub" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(props.id);
    }
  })}>! اشتري الأن</Link>          
    :
            <div></div>
           }
              <Link to={`/contentcourse/${props.id}`}  className="buy">الدخول للكورس </Link>
            </div>      
                 </div>
                        
          </div>
              </div>
            </Col>
                   </>
                      )
                    })
                }
                 </Row>
                      </div>
                  </div>
                  </div>
                  :
                  <div className="no-courses">
                    
                  <h2> !لا تتوفر كورسات حتي الأن </h2>
              </div>
                  }
                              < ScrollTopButton />

                
                  </>
              )
          }
          export default MyCourses;