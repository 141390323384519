import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import "../../styles/addquiz.css";
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { Navigate, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { BASE_URL, Image_URL, tableCustomStyles } from "../../App";
import { getIn, useFormik } from "formik";
import { createGlobalStyle } from "styled-components";

function QuestionSelector() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [question, setQuestion] = useState([]);

  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "any",
      Authorization: `Bearer ${token}`,
    },
  });

  const formik = useFormik({
    onSubmit: async (data) => {},
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getQuestion = async () => {
    try {
      const response = await axiosInstance.get(`/api/get-quiz-checked/${id}`);

      // console.log(response.data.data);
      setQuestion(response.data.data);
      setFilteredData(response.data.data);
      const data = response.data.data;
      formik.resetForm({ values: data });
      return data;
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getQuestion();
  }, []);

  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const searchResults = question.filter((item) => {
      return item.attributes.question.toLowerCase().includes(e.target.value.toLowerCase()) 
    });
    setFilteredData(searchResults);
  };

  const [question_id, setQuestion_id] = useState([]);
  const [degree, setDegree] = useState([]);

  const handleAddQuestion = async (event) => {
    event.preventDefault();
  
    try {
      let selectedIds = [];
      let selectedDegrees = [];
      let allDegreesEntered = true;
  
      formik.values
        ?.filter((el) => {
          return el?.attributes?.state == 1;
        })
        ?.forEach((correctQuesion) => {
          selectedIds.push(+correctQuesion?.id);
          selectedDegrees.push(correctQuesion?.attributes?.degree);
  
          if (!correctQuesion?.attributes?.degree) {
            allDegreesEntered = false;
          }
        });
  
      if (!allDegreesEntered) {
        swal({
          title: "يجب إدخال الدرجة لجميع الأسئلة المحددة!",
          icon: "warning",
          button: "حسناّ!",
        });
        return;
      }
  
      let data = {
        question_id: selectedIds,
        degree: selectedDegrees,
      };
      const response = await axiosInstance.post(
        `/api/edit-quiz-questions/${id}`,
        data
      );
      setQuestion_id([]);
      setDegree([]);
      swal({
        title: "تم إضافة الأسئلة بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
      navigate("/admin/quizdata");
      // console.log(response.data);
  
      // console.log(degree);
      // console.log(question_id);
    } catch (e) {
      if (e.response.status === 500) {
        // console.log(e);
        // console.log(degree);
        // console.log(question_id);
      }
    }
  };
  
 
  // console.log(formik.values);
  // const handleQuestionIdsChange = (event) => {
  //   const { target } = event;
  //   const { value } = target;
  //   const checked = target.checked;
  //   const id = parseInt(value, 10);
  //   setQuestion_id((prevQuestionIds) => {
  //     const index = prevQuestionIds.indexOf(id);
  //     if (checked && index === -1) {
  //       return [...prevQuestionIds, id];
  //     } else if (!checked && index !== -1) {
  //       return prevQuestionIds.filter((questionId) => questionId !== id);
  //     } else {
  //       return prevQuestionIds;
  //     }
  //   });
  // };

  // const handleDegreesChange = (event) => {
  //   const { target } = event;
  //   const { value } = target;
  //   const index = parseInt(target.dataset.index, 10);
  //   setDegree((prevDegrees) => {
  //     return [
  //       ...prevDegrees.slice(0, index),
  //       value,
  //       ...prevDegrees.slice(index + 1),
  //     ];
  //   });
  // };


  
  

  const columns = [
    {
      name: "SelectQuestion",
      cell: (row, index) => {
        const dynamicCheckedKey = `${index}.attributes.state`;
        return (
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              name="question_id[]"
              checked={Number(getIn(formik.values, dynamicCheckedKey))}
              value={row.id}
              onChange={(e) => {
                // handleQuestionIdsChange(index);
                formik.setFieldValue(
                  dynamicCheckedKey,
                  Number(e.target.checked)
                );
              }}
            />
          </Form.Group>
        );
        //  row.attributes.state === "1" ? (

        // ) : (
        //   <Form.Group className="mb-3" controlId="formBasicCheckbox">
        //     <Form.Check
        //       type="checkbox"
        //       name="question_id[]"
        //       value={row.id}
        //       onChange={handleQuestionIdsChange}
        //     />
        //   </Form.Group>
        // );
      },
    },
    {
      name: "Degree",
      cell: (row, index) => {
        const dynamicDegreeKey = `${index}.attributes.degree`;
        return (
          <Form>
            <Form.Control
              type="text"
              placeholder="ادخل الدرجة"
              name="degree[]"
              data-index={index}
              value={getIn(formik.values, dynamicDegreeKey)}
              onChange={(e) => {
                formik.setFieldValue(dynamicDegreeKey, e.target?.value);
                // handleDegreesChange
              }}
            />
          </Form>
        );
      },
    },

    {
      name: "Question ",
      selector: (row) => row.attributes.question,
      sortable: true,
      grow: 10,
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="image-container">
          <img
            height={100}
            width={200}
            src={`${Image_URL}/storage/${row.attributes.img}`}
            alt=""
            onClick={(e) => {
              e.target.classList.toggle("clicked");
              const overlay = e.target.nextElementSibling;
              overlay.style.display = overlay.style.display === "block" ? "none" : "block";
            }}
          />
          <div className="overlay"></div>
        </div>
      ),
      sortable: true,
      grow: 10,
    },
    

    {
      name: "Lesson",
      selector: (row) => row.attributes.lesson,
      sortable: true,
      grow: 4,

    },
    {
      name: "Answer",
      selector: (row) => row.attributes.answer,
      sortable: true,
      grow: 2,

    },
    {
      name: "Level",
      selector: (row) => row.attributes.level,
    },
  ];
  return (
    <>
      <div className="d-flex">
        <div className="add-quiz ">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="quiz-content">
                  <div className="codes pt-5">
                    <div className="create-box">
                      <h2 className="create mb-4"> أسئلة الكويز </h2>

                      <Form onSubmit={handleAddQuestion}>
                        <DataTable
                                       customStyles={tableCustomStyles}

                          className="table"
                          columns={columns}
                          data={filteredData}
                          // pagination
                          fixedHeader
                          highlightOnHover
                          subHeader
                          subHeaderComponent={
                            <input
                              type="text"
                              className="form-control mt-4 mb-4"
                              placeholder="Search of Question"
                              onChange={handleSearch}
                            />
                          }
                        />
                        <Button
                          variant="primary"
                          type="submit"
                          className="create-button"
                        >
                          اضف
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="sidebar">
          <SidebarAdminMain />
        </div>
      </div>
    </>
  );
}

export default QuestionSelector;
