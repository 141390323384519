import { useState } from "react";
import { Link } from "react-router-dom";

import '../styles/sidebarProfile.css'





function SidebarProfile() {

    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    
    const menuItem=[
      {
        path:"/profile",
        name:"ملف الطالب",
        icon: <i class="fa-solid fa-house" ></i>
      },
      {
        path:"/profile/courses",
        name:"  الكورسات  ",
        icon: <i class="fa-solid fa-book"></i>
      },
      {
        path:"/profile/quiz",
        name:"نتائج الامتحانات",
        icon: <i class="fa-solid fa-circle-question"></i>
      },
      {
        path:"/profile/homeworks",
        name:"نتائج الواجبات",
        icon: <i class="fa-solid fa-circle-question"></i>
      },
      {
        path:"/chargewallet",
        name:"الاشتراكات",
        icon: <i class="fa-sharp fa-solid fa-cart-shopping"></i>
      },
    ]
    return (
        <>
                <div className="sidebar-profile">
                <div className="navigation">
    <ul>
            {
                menuItem.map((item , index) =>(
                        <li key={index} className="list">

                            <b></b>
                            <b></b>
                            <Link to={item.path}>
                                <span className="icon">{item.icon}</span>
                                <span className="title">{item.name}</span>
                            </Link>
                        </li>
                ))
            }
    </ul>
  </div>
  {/* <div class="toggle" onClick={toggle} style={{background : isOpen ? "#FFBF00" : "#e03a56"}}>
             <ion-icon name="menu-outline" class="open" style={{display : isOpen ? "none" :  "block"}}></ion-icon>
            <ion-icon name="close-outline" class="close" style={{display : isOpen ? "block" :  "none"}}></ion-icon>
             </div>
             style={{width : isOpen ? "300px" : "70px"}} */}
                </div>
 
        </>
    )
}

export default SidebarProfile;