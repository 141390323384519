
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { BASE_URL, Image_URL } from '../../App';
import '../../styles/profilecourses.css'
import SidebarProfileWithAdmin from './SidebarProfileWithAdmin';

function StudentCourseWithAdmin () {
      const {id} =  useParams()
      const [cards , setCards] = useState([]);
    
    useEffect(() => {
              const token = localStorage.getItem('token');
              const config = {
                headers: {
                  'Content-Type': 'application/json',
                  "ngrok-skip-browser-warning": "any",
                  'Authorization': `Bearer ${token}`,
                }
              };       
              axios.get(`${BASE_URL}/api/user-weeks/${id}` , config)
                .then(response => {
                  // console.log(response.data.data);
                  setCards(response.data.data);
                })
                .catch(error => {
                  // console.error(error);
                });
            }, [])

            function formatDate(dateString) {
              try {
                const date = new Date(dateString);
                const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
                const formatter = new Intl.DateTimeFormat('ar-EG', options);
                const formattedDate = formatter.format(date);
                return formattedDate;
              } catch (e) {
                // console.error('Error formatting date', e);
                return '';
              }
            }

    return (
        <>
             <div className="profile-course">
             <div className="profile-ground">
                                <img src="/images/bg.3b55416e926d51a05f75.png" alt=""/>
                        </div>
            <Container>
                <Row>
                    <Col lg={12}>
                       
                        <div className="profile-user pt-5">
                        <div className="title-profile mt-5 mb-5">
                  <button class="button-prof" role="button"> كورسات الطالب </button>
                       </div>                              
                          <Container>
                                <Row >
                                    <Col lg={9} className="">
                                  <div className="main-info ">
                                        <Container>
                                            <Row className="" >
                          {
                            cards.map((props) => {
                              return(
                              
                             <Col lg={6} md={12} key={props.id}  className="mb-5"> 
              <div className="main">
                          <div className="card shadow2">
                        <div className="images">
                        <img src={`${Image_URL}/storage/${props.img}`} alt=""/>

                        {/* <img src="/images/Logo.jpeg" alt=""/> */}
                        </div>
                        <div className="details mt-4">
                        <h2>{props.name}</h2>
                        <div className="line"></div>
                        <p>{props.description}</p>
                        </div>
                        <div className="price-date">

                       
                                  
                              <div className="subed">
                                      أنت مشترك في هذا الكورس
                                                       !
                                </div>

              <div className="dates d-flex ms-2">
                  <span className="date d-flex">
                  {formatDate(props.created_at)}
                    <img src="/images/email.png" alt=""/>
                    </span>
                  <span className="date d-flex mt-1">
                   {formatDate(props.updated_at)}
                    <img src="/images/add-friend.png" alt=""/>
                    </span>
                </div>     
                     <div className="btns">
            <div></div>
              <Link to={`/contentcourse/${props.id}`}  className="buy">الدخول للكورس </Link>
            </div>      
                 </div>
                        
          </div>
              </div>
            </Col>
                                
                              )
                            })
                          }
                                            </Row>
                                        </Container>
                                  </div>
                                    </Col>
                                
                                    <Col lg={3}>
                                      <SidebarProfileWithAdmin />
                                    </Col>
                                </Row>
                                </Container>
                            </div>
                        
                    
                    </Col>
                </Row>

            </Container>
        </div>
        </>
    )
}

export default StudentCourseWithAdmin;