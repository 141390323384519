

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import '../../styles/addquestion.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
import { serialize } from "object-to-formdata";


function EditQuestion() {

    // console.log(id);


    // const [img , setImg] = useState("")
    // const [question , setQuestion] = useState("")
    // const [level , setLevel] = useState("")
    // const [c1 , setC1] = useState("")
    // const [c2 , setC2] = useState("")
    // const [c3 , setC3] = useState("")
    // const [c4 , setC4] = useState("")
    // const [c1_img , setC1_img] = useState("")
    // const [c2_img , setC2_img] = useState("")
    // const [c3_img , setC3_img] = useState("")
    // const [c4_img , setC4_img] = useState("")
    // const [answer , setAnswer] = useState("")
    // const [lesson , setLesson] = useState("")
    // const [errors , setErrors] = useState("")
    // const [edit , setEdit] = useState("")
  
    const token = localStorage.getItem('token');



  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
});

// const {id} = useParams();
const getQuestion = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/questions/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // setQuestion(response.data.data)
        setEdit(response.data.data.attributes);
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
  getQuestion();
}, [])


const [errors, setErrors] = useState({});
const Navigate = useNavigate();
const { id } = useParams();
const [edit, setEdit] = useState({
  img: "",
  question: "",
  level: "",
  c1: "",
  c2: "",
  c3: "",
  c4: "",
  c1_img: null,
  c2_img: null,
  c3_img: null,
  c4_img: null,
  answer: "",
  lesson: "",
});


const handleEdit = async (event) => {
  event.preventDefault();
  try {
   
    const formData = new FormData();
    formData.append("img", edit.img);
    formData.append("question", edit.question);
    formData.append("level", edit.level);
    formData.append("c1", edit.c1);
    formData.append("c2", edit.c2);
    formData.append("c3", edit.c3);
    formData.append("c4", edit.c4);
    formData.append("c1_img", edit.c1_img);
    formData.append("c2_img", edit.c2_img);
    formData.append("c3_img", edit.c3_img);
    formData.append("c4_img", edit.c4_img);
    formData.append("answer", edit.answer);
    formData.append("lesson", edit.lesson);

  const response =  await axiosInstance.post(
      `/api/edit-question/${id}`,
      
        // ...edit,
        formData,
      
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "ngrok-skip-browser-warning": "any",
        },
      }
    );
    
    setEdit({
      img: "",
      question: "",
      level: "",
      c1: "",
      c2: "",
      c3: "",
      c4: "",
      c1_img: null,
      c2_img: null,
      c3_img: null,
      c4_img: null,
      answer: "",
      lesson: "",
    });

    console.log(response);
    swal({
      title: "تم تعديل السؤال بنجاح!",
      icon: "success",
      button: "حسناّ!",
    });
        Navigate("/admin/questiondata");
  } catch (e) {
   if(e.response.status === 405) {
    console.log(e.response);
   }
    if (e.response.status === 500) {
 

      console.log(edit);
  
      setErrors(e.response.data.errors);
    }

  }
};



 
    
const [imagePreview, setImagePreview] = useState(null);

const handleImageChange = (event) => {
  const file = event.target.files[0];
  setEdit((prev) => ({ ...prev, img: file }));
  setImagePreview(URL.createObjectURL(file));
}




    return (
        <>
      <div className="d-flex">
    
       <div className="add-question">
      
      <div className="main-title">  تعديل السؤال رقم {id}</div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                    <div className="question">
                      
                        <div className="create-box">
                        <h2 className="title mt-4 mb-4">تعديل السؤال</h2>
                        <Form onSubmit={handleEdit}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>* السؤال</Form.Label>
        <Form.Control as="textarea" rows={2}  name="question"  value={edit.question}  onChange={(e) => setEdit((prev) => ({ ...prev, question: e.target.value }))}/>
      </Form.Group>
      <img className="edit-image" src={imagePreview || `${Image_URL}/storage/${edit.img}`} alt="" />

      <Form.Group controlId="formFile" className="mb-3">  
        <Form.Label>صورة السؤال</Form.Label>
      <Form.Control type="file" name="img" onChange={handleImageChange} />
      </Form.Group>

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group className="mb-3" controlId="formBasicText  ">
        <Form.Label>الأختيار الأول</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأجابة"   name="c1"  value={edit.c1}  onChange={(e) => setEdit((prev) => ({ ...prev, c1: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3" controlId="formBasicText">
        <Form.Label>الأختيار الثاني</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأجابة"  name="c2"  value={edit.c2}  onChange={(e) => setEdit((prev) => ({ ...prev, c2: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3" controlId="formBasicText ">
        <Form.Label>الأختيار الثالث</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأجابة" name="c3"  value={edit.c3}  onChange={(e) => setEdit((prev) => ({ ...prev, c3: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3" controlId="formBasicText ">
        <Form.Label>الأختيار الرابع</Form.Label>
        <Form.Control type="text" placeholder="ادخل الأجابة" name="c4"  value={edit.c4}  onChange={(e) => setEdit((prev) => ({ ...prev, c4: e.target.value }))}/>
      </Form.Group>
     </div>
     <div className="chooices  d-lg-flex align-items-center mt-5">
 
     <Form.Group controlId="formFile" className="image mb-3" >
          {
            edit.c1_img === null
            ?
            ""
            :
            <img className="edit-image" src={`${Image_URL}/storage/${edit.c1_img}`} alt=""/>
          }
        <Form.Label>صورة الإجابة الأولي</Form.Label>
        <Form.Control type="file" name="c1_img"  onChange={(e) => setEdit((prev) => ({ ...prev, c1_img: e.target.files[0] }))}/>
      </Form.Group>

     <Form.Group controlId="formFile" className=" image mb-3" >
          {
            edit.c2_img === null
            ?
            ""
            :
              <img height={100} className="edit-image" src={`${Image_URL}/storage/${edit.c2_img}`} alt=""/>

          }
        <Form.Label>صورة الإحابة الثانية</Form.Label>
        <Form.Control type="file" name="c2_img"  onChange={(e) => setEdit((prev) => ({ ...prev, c2_img: e.target.files[0] }))}/>
      </Form.Group>
     <Form.Group controlId="formFile" className=" image mb-3" >
            {
              edit.c3_img === null
              ?
              ""
              :
              <img className="edit-image" src={`${Image_URL}/storage/${edit.c3_img}`} alt=""/>

            }
        <Form.Label>صورة الإجابة الثالثة</Form.Label>
        <Form.Control type="file" name="c3_img"   onChange={(e) => setEdit((prev) => ({ ...prev, c3_img: e.target.files[0] }))}/>
      </Form.Group>

          <Form.Group controlId="formFile" className="image mb-3">
      {edit.c4_img === null ? (
        ""
      ) : (
        <img className="edit-image" src={`${Image_URL}/storage/${edit.c4_img}`} alt="" />
      )}
      <Form.Label>صورة الإجابة الرابعة</Form.Label>
      <Form.Control type="file" name="c4_img" onChange={(e) => setEdit((prev) => ({ ...prev, c4_img: e.target.files[0] }))} />
    </Form.Group>
 
     </div>
    
    <Form.Group className="mt-4">
    <Form.Label > اختيار إجابة السؤال</Form.Label>
     <Form.Select aria-label="Default select example" className="mt-2" name="answer"  value={edit.answer} onChange={(e) => setEdit((prev) => ({ ...prev, answer: e.target.value }))}>
      <option >اختر الإجابة</option>
      <option value="1" >الإجابة الأولي</option>
      <option value="2" >الإجابة الثانية</option>
      <option value="3">الإجابة الثالثة</option>
      <option value="4">الإجابة الرابعة</option>
    </Form.Select>
    </Form.Group>



    <Form.Group className="mt-4">
    <Form.Label >الفصل</Form.Label>
     <Form.Control aria-label="Default select example" className="mt-2" name="lesson"  value={edit.lesson} onChange={(e) => setEdit((prev) => ({ ...prev, lesson: e.target.value }))}>
     </Form.Control>

      {/* <option >اختر الشابتر</option>
      <option value="1">الإجابة الأولي</option> */}
 
    </Form.Group>
    
   
    
    <Form.Group controlId="formFile" className="mb-3 mt-4">
      <Form.Select aria-label="Default select example" name="level" value={edit.level} onChange={(e) => setEdit((prev) => ({ ...prev, level: e.target.value }))}>
      <option> اختر السنه الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
      </Form.Group>


      
        

      <Button variant="primary" type="submit" className="create-button">
        تعديل
      </Button>
    </Form>
    </div>
                    </div>
              </div>
               </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
       </>
    ) 
}

export default EditQuestion;