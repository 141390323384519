

import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import '../../styles/videodata.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { BASE_URL, tableCustomStyles } from "../../App";
import swal from "sweetalert";

function Adminvideo() {
  
  
  const [video , setVideo] = useState([]);
  

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});

const getVideo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/api/videos' , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        setVideo(response.data.data)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
  getVideo();
}, [])






const [rows, setRows] = useState([]);

const deleteRow = async(id, token) => {
  try {
      const response = await axios.delete(`${BASE_URL}/api/videos/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
          
      })
      swal({
        title: "تم  حذف الفيديو   بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
  } catch (error) {
      // console.error(error);
  }

};
const handleDeleteClick = async (id) => {
  const token = localStorage.getItem('token');
  try {
      await deleteRow(id, token);
      const updateVideo = video.filter(row => row.id !== id);
      setVideo(updateVideo);
  } catch (error) {
      // console.error(error);
  }
};



const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = video.filter((item) => {
    return (
      item.attributes.title.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
  });
  setFilteredData(searchResults);
};


  
  
    const columns = [
        {
            name: "Id ",
            selector: (row) => row.id,
            sortable : true,
          //   style: {
          //     background: "#e6e6e6",
          //   }
          },
      {
        name: "Name ",
        selector: (row) => row.attributes.title,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:5,
      },
      {
        name: "Iframe ",
        selector: (row) => row.attributes.iframe,
        sortable : true,
    
      grow:3,
      },
      {
        name: "Type",
        selector: (row) => row.attributes.type,
        sortable : true,
      
      },
  
      
      {
        name: "No.Of.View",
        selector: (row) => row.attributes.noviews,
      
      },
      {
        name: "Minutes View",
        selector: (row) => row.attributes.minutes_views,
        // grow:3,
      },
      {
        name: "Video_Duration",
        selector: (row) => row.attributes.video_dauration,
        // grow:3,
      },
      {
        name: "Level",
        selector: (row) => row.attributes.level,
      },
      {
        name: "Week_id",
        selector: (row) => row.attributes.week_id,
      },
     
      {
        name: "created_at",
        selector: (row) => row.attributes.created_at,
        grow:3
      },
      {
        name: "updated_at",
        selector: (row) => row.attributes.updated_at,
        grow:3,
      },
     
      // {
      //   name: "Country Flag",
      //   selector: (row) => <img width={10} height={10} src={row.flag} alt="" />,
      // },
      {
        name: "Edit",
        cell: row => <Link to={`/admin/editvideo/${row.id}`}>
        <button style={{background:"#28a745" , border: "none"}} className="btn btn-success pe-3 ps-3 pt-2 pb-2">Edit</button>,
        </Link>

        
      },
      {
        name: "Views",
        cell: row => <Link to={`/admin/studentvideo/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-primary pe-lg-2 ps-lg-2 ps-1 pe-1 pt-2 pb-2" >Views</button>,
        </Link>,

        // grow:1
      },
      {
        name: "Delete",
        cell: row => <button style={{background:"#" , border: "none"}} className="btn btn-danger pe-lg-2 ps-lg-2 ps-1 pe-1 pt-2 pb-2" onClick={() => alert('هل انت متأكد من حذف هذا الفديو') | handleDeleteClick(row.id)}>Delete</button> ,
        // grow:4,
    },
     
    ];
    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  بيانات الفديوهات </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                                           customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : video} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                  <Link to="/admin/addvideo">
                                       <button className="btn btn-primary mt-4 mb-4 fs-5 fw-bold pt-2 pb-2 pe-4 ps-4 me-2">اضافة فيديو</button>   
                  </Link>
               }
                subHeader
                subHeaderComponent={
                  <input type="text" className="form-control mt-4 mb-4" placeholder="Search of Videos" onChange={handleSearch} />

                }
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default Adminvideo;