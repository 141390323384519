import { Col, Container, Row } from "react-bootstrap";
// import DataTable from "react-data-table-component";
import SidebarAdminMain from "./SidebarAdminMain";
import '../styles/years-admin.css'
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_URL } from "../App";



function AdminYear () {

    const {id} = useParams();
 

    const [details , setDetails] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem('token');
        
        const headers = {
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          'Authorization': `Bearer ${token}`
        };
    
        fetch(`${BASE_URL}/api/level/${id}`, {
          method: 'GET',
          headers
        })
        .then(response => response.json())
          .then(response => {
            // console.log(response);
            setDetails(response.data);
          })
          .catch(error =>   
              console.error(error)
            );
      }, []);
    



    
    return (
        <>
          <div className="d-flex">
      
      <div className="admin-year ">
     
           
             <div  className="main-title">الصف {details.title} الثانوي</div>
           
   
     <Container>
   
         <Row>
           
                 <Col lg={6}>
                        <Link to={`/admin/levelstudent/${id}`} className="text-decoration-none">
                        <div className="box d-flex p-5 mt-4">
                    <i class="fa-solid fa-users"></i>
                    <div className="details">
                        <span className="">عدد الطلاب</span>
                        <span className="d-block mt-2">{details.students}</span>
                    </div>
                    </div>
                        </Link>
                </Col>
                <Col lg={6}>
                <Link to={`/admin/levelweek/${id}`} className="text-decoration-none">
                <div className="box d-flex p-5 mt-4">
            <i class="fa-solid fa-calendar-week"></i>
            <div className="details">
                <span className="">الحصص</span>
                <span className="d-block mt-2">{details.weeks}</span>
            </div>
            </div>
                </Link>
                 </Col>

      
        <Col lg={6}>
                <Link to={`/admin/levelquestion/${id}`}className="text-decoration-none">
                <div className="box d-flex p-5 mt-4">
                <i class="fa-solid fa-circle-question"></i>
            <div className="details">
                <span className="">الأسئلة</span>
                <span className="d-block mt-2">{details.questions}</span>
            </div>
            </div>
                </Link>
        </Col>
      
      
      
        <Col lg={6}>
                <Link to={`/admin/levelquiz/${id}`} className="text-decoration-none">
                <div className="box d-flex p-5 mt-4">
                <i class="fa-solid fa-list-check"></i>
            <div className="details">
                <span className="">الكويزات</span>
                <span className="d-block mt-2">{details.quizzes}</span>
            </div>
            </div>
                </Link>
        </Col>
        <Col lg={6}>
                <Link to={`/admin/levelhomework/${id}`} className="text-decoration-none">
                <div className="box d-flex p-5 mt-4">
                <i class="fa-solid fa-list-check"></i>
            <div className="details">
                <span className="">الواجبات</span>
                <span className="d-block mt-2">{details.homeworks}</span>
            </div>
            </div>
                </Link>
        </Col> 
        <Col lg={6}>
                <Link to={`/admin/levelvideo/${id}`} className="text-decoration-none">
                <div className="box d-flex p-5 mt-4">
            <i class="fab fa-youtube"></i>
            <div className="details">
                <span className="">الفديوهات</span>
                <span className="d-block mt-2">{details.videos}</span>
            </div>
            </div>
                </Link>
        </Col>

      
        
      
         </Row>
     </Container>

   
     </div>
     <div className="sidebar">
      <SidebarAdminMain />
      </div>
      </div>
        </>
    )
}

export default AdminYear;
