
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Dropzone from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL } from "../../App";
import SidebarAdminMain from "../SidebarAdminMain";



function Addweek() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState(null);
  const [price, setPrice] = useState("");
  const [level, setlevel] = useState("");
  const [errors , setErrors] = useState("");
  const [file, setFile] = useState(null);

 

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      "Content-Type": "multipart/form-data" ,
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
});

  const handleAddWeek = async (event) => {
    event.preventDefault();
    try {
      const options = {
        indices: true,

        nullsAsUndefineds: true,

        booleansAsIntegers: true,

        allowEmptyArrays: true,

        noFilesWithArrayNotation: false,

        dotsForObjectNotation: false,
      };

      const data = {name , price , description , img ,level }
      const formData = serialize(data, options);
      // console.log([...formData]);

        await axiosInstance.post("/api/weeks" ,formData);
        setName("");
        setDescription("");
        setImg(null);
        setPrice("");
        setlevel("");
        swal({
            title: "تم إضافة الحصة بنجاح!",
            icon: "success",
            button: "حسناّ!",
          });
          navigate(`/admin/weekdata`)
      

    } catch(e) {
        if(e.response.status === 422) {
            setErrors(e.response.data.errors);
            // console.log(e);   
        }
    }
}

    return (
        <>
      <div className="d-flex">
       <div className="add-video">
      <div className="main-title"> اضافة حصة  </div>
      <Container>
          <Row>
          <Col lg={12}>
          <div className="quiz-content">
                    <div className="video">

                        <div className="create-box">
                        <h2 className="title mt-4 mb-4">اضافة حصة</h2>
                        <Form onSubmit={handleAddWeek}>
         


     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 me-lg-3 videoInput" controlId="formBasicText">
        <Form.Label> اسم الحصة</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الحصة" name="name"   onChange={(e) =>{setName(e.target.value)}}/>
        {errors.name && <div className="error">{errors.name[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText ">
        <Form.Label> بوستر الحصة </Form.Label>
        <Form.Control type="file"  name="img"   onChange={(e) =>{setImg(e.target.files[0])}}/>
        {errors.img && <div className="error">{errors.img[0]}</div>}


      </Form.Group>
    
     </div>

     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 videoInput me-lg-3" controlId="formBasicText ">
        <Form.Label>  وصف الحصة </Form.Label>
        <Form.Control type="text" placeholder="ادخل وصف الحصة" name="description"  onChange={(e) =>{setDescription(e.target.value)}}/>
        {errors.description && <div className="error">{errors.description[0]}</div>}

        </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText">
        <Form.Label> سعر الحصة</Form.Label>
        <Form.Control type="number" placeholder="ادخل سعر الحصة" name="price"  onChange={(e) =>{setPrice(e.target.value)}}/>
        {errors.price && <div className="error">{errors.price[0]}</div>}

      </Form.Group>
   
     </div>
    

        
  

     <div className="chooices d-lg-flex align-items-center mt-lg-4">
   

    <Form.Select aria-label="Default select example" className="mt-3" name="level"  onChange={(e) =>{setlevel(e.target.value)}}>
      <option>اخنر السنة الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>

    </Form.Select>

     </div>
     {errors.level && <div className="error mt-3">{errors.level[0]}</div>}

    
 




      
        

      <Button variant="primary" type="submit" className="create-button">
        اضف
      </Button>
    </Form>
    </div>
                    </div>
              </div>
               </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default Addweek;