import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
import "../../styles/modelanswer.css";
import ScrollTopButton from "../ScrollTop";

function ModelAnswerHomework() {
  const { id } = useParams();

  const [answer, setAnswer] = useState([]);
  const [numCorrect, setNumCorrect] = useState(0);
  const [numWrong, setNumWrong] = useState(0);
  const [numNoAnswer, setNumNoAnswer] = useState(0);
  const [totalDegree, setTotalDegree] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${BASE_URL}/api/homework-model-answer/${id}`, config)
      .then((response) => {
        // console.log(response.data.data);
        setAnswer(response.data.data);
        const total = response.data.data.reduce((acc, cur) => {
          if (cur.choosen === cur.answer) {
            return acc + parseInt(cur.degree);
          } else {
            return acc;
          }
        }, 0);
        setTotalDegree(total);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          swal({
            title: "! برجاء الأنتظار حتي ينتهي وقت الواجب ",
            text: "نتمني لك أعلي الدرجات",
            icon: "warning",
            button: "حسنا!",
          }).then(() => {
            window.history.go(-2);
          });
        }
      });
  }, []);

  useEffect(() => {
    let numCorrect = 0;
    let numWrong = 0;

    answer.forEach((answer) => {
      if (answer.choosen === answer.answer) {
        numCorrect += 1;
      } else {
        numWrong += 1;
      }
    });
    setNumCorrect(numCorrect);
    setNumWrong(numWrong);
  }, [answer]);

  const totalQuestions = answer.length;
  const correctAnswers = answer.filter((a) => a.answer === a.choosen);
  const correctDegreeSum = correctAnswers.reduce(
    (acc, cur) => acc + parseInt(cur.degree),
    0
  );
  const totalDegreeSum = answer.reduce(
    (acc, cur) => acc + parseInt(cur.degree),
    0
  );
  const correctDegreePercentage = (correctDegreeSum / totalDegreeSum) * 100;
  const roundedCorrectDegreePercentage = Math.round(correctDegreePercentage);

  // console.log(totalQuestions);
  // console.log(correctAnswers);
  // console.log(percentage);
  // console.log(per);
  let resultText;
  if (roundedCorrectDegreePercentage === 0) {
    resultText = "";
  } else if (roundedCorrectDegreePercentage < 50) {
    resultText = "مش هينفع كده خالص";
  } else if (
    roundedCorrectDegreePercentage >= 50 &&
    roundedCorrectDegreePercentage < 70
  ) {
    resultText = "شد حيلك";
  } else if (
    roundedCorrectDegreePercentage >= 70 &&
    roundedCorrectDegreePercentage < 80
  ) {
    resultText = " عايزين أحسن من كدة";
  } else if (roundedCorrectDegreePercentage >= 80) {
    resultText = "عااااش";
  }

  const scrollToQuestion = (questionId) => {
    const questionElement = document.getElementById(`question-${questionId}`);
    if (questionElement) {
      questionElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="model-answer pt-5 pb-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="model-btn text-center">
                <button class="button-86 mb-5" role="button">
                  اجاباتك كاملة{" "}
                </button>
              </div>{" "}
              <div className="boxanswer-main">
                <Row>
                  <Col lg={4}>
                    <div className="percentage-main">
                      <div className="box">
                        <div
                          style={{ width: "150px", height: "150px" }}
                          className="percentage"
                        >
                          <CircularProgressbar
                            value={correctDegreePercentage}
                            text={`${Math.round(
                              roundedCorrectDegreePercentage
                            )}%`}
                          />
                        </div>
                        <div className="score">
                          <p>
                            النتيجة النهائية:{" "}
                            <span>
                              {answer.reduce(
                                (acc, cur) => acc + parseInt(cur.degree),
                                0
                              )}{" "}
                              / {totalDegree}
                            </span>
                          </p>
                          <p>
                            النتيجة: <span>{resultText}</span>{" "}
                          </p>
                        </div>
                        {/* {percentage} */}
                      </div>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="answer-main">
                      <div className="box">
                        <h2 className="click-box">
                          دوس علي رقم السؤال علشان تروح للإجابة
                        </h2>
                        <div className="answers">
                          {answer.map((q, index) => (
                            <div
                              style={{
                                backgroundColor:
                                  q.choosen === q.answer
                                    ? "rgb(40, 167, 69)"
                                    : "#dc3545",
                                margin: "10px",
                              }}
                              className="box-answer"
                              onClick={() => scrollToQuestion(q.id)}
                              key={q.id}
                            >
                              {index + 1}
                            </div>
                          ))}
                        </div>
                        <div className="total-answer mt-5">
                          <div className="number">
                            <p> : عدد اجاباتك الصحيحة </p>
                            <span className="correct">{numCorrect}</span>
                          </div>
                          <div className="number">
                            <p> : عدد اجاباتك الخاطئة </p>
                            <span className="incorrect">{numWrong}</span>
                          </div>
                          {/* <p>  عدد الأسئلة الغير المحلولة   : <span>{numNoAnswer}</span></p> */}
                          <div className="info"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={12}>
                  <div className="answer-question">
                    {answer.map((props, index) => {
                      let color = "#dc3545";
                      let border = "6px solid rgba(168  6 0 / 0.5)";
                      if (props.choosen === props.answer) {
                        color = "rgb(40, 167, 69)";
                        border = "6px solid rgba(17 94 89 / 0.5)";
                      }

                      return (
                        <div
                          className="box mt-4"
                          key={props.id}
                          style={{ backgroundColor: color, border: border }}
                          id={`question-${props.id}`}
                        >
                          <span className="counter">{index + 1}</span>
                          <div className="box-answer">
                            <div className="non">
                              {props.choosen === "0"
                                ? " لم يتم حل هذا السؤال"
                                : ""}
                            </div>
                            <div className="question-box d-flex">
                              <h2 className="question">{props.question}</h2>
                            </div>
                            {props.img === "" ? (
                              ""
                            ) : (
                              <img
                                src={`${Image_URL}/storage/${props.img}`}
                                className="img-question"
                                alt=""
                              />
                            )}

                            {props.c1 === null ? (
                              ""
                            ) : (
                              <div className="choice">
                                {props.c1}
                                {props.answer === "1" ? (
                                  <span className="correct ">correct</span>
                                ) : props.choosen === "1" ? (
                                  <span className="incorrect">Wrong</span>
                                ) : null}
                              </div>
                            )}

                            {props.c1_img === "" ? (
                              ""
                            ) : (
                              <img
                                src={`${Image_URL}/storage/${props.c1_img}`}
                                className="img-question"
                                alt=""
                              />
                            )}

                            {props.c2 === null ? (
                              ""
                            ) : (
                              <div className="choice">
                                {props.c2}
                                {props.answer === "2" ? (
                                  <span className="correct">correct</span>
                                ) : props.choosen === "2" ? (
                                  <span className="incorrect">Wrong</span>
                                ) : null}
                              </div>
                            )}

                            {props.c2_img === "" ? (
                              ""
                            ) : (
                              <img
                                src={`${Image_URL}/storage/${props.c2_img}`}
                                className="img-question"
                                alt=""
                              />
                            )}

                            {props.c3 === null ? (
                              ""
                            ) : (
                              <div className="choice">
                                {props.c3}
                                {props.answer === "3" ? (
                                  <span className="correct">correct</span>
                                ) : props.choosen === "3" ? (
                                  <span className="incorrect">Wrong</span>
                                ) : null}
                              </div>
                            )}

                            {props.c3_img === "" ? (
                              ""
                            ) : (
                              <img
                                src={`${Image_URL}/storage/${props.c3_img}`}
                                className="img-question"
                                alt=""
                              />
                            )}

                            {props.c4 === null ? (
                              ""
                            ) : (
                              <div className="choice">
                                {props.c4}
                                {props.answer === "4" ? (
                                  <span className="correct">correct</span>
                                ) : props.choosen === "4" ? (
                                  <span className="incorrect">Wrong</span>
                                ) : null}
                              </div>
                            )}
                            {props.c4_img === "" ? (
                              ""
                            ) : (
                              <img
                                src={`${Image_URL}/storage/${props.c4_img}`}
                                className="img-question"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <ScrollTopButton />
    </>
  );
}

export default ModelAnswerHomework;
