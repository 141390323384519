
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import '../../styles/questiondata.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { BASE_URL, Image_URL, tableCustomStyles } from "../../App";







function Questiondata() {
  
  const navigate = useNavigate();
  
  
  const [question , setQuestion] = useState([]);
  

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});



const getQuestion = async () => {
    try {
      const token = localStorage.getItem('token' );
      const response = await axiosInstance.get('/api/questions' , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        setQuestion(response.data.data)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
  getQuestion();
}, [])






const [rows, setRows] = useState([]);

const deleteRow = async(id, token) => {
  try {
      const response = await axios.delete(`${BASE_URL}/api/questions/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      swal({
        title: "تم حذف السؤال بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
    
  } catch (error) {
      // console.error(error);
  }

};
const handleDeleteClick = async (id) => {
  const token = localStorage.getItem('token' );
  try {
      await deleteRow(id, token);
      const updateQuestion = question.filter(row => row.id !== id);
      setQuestion(updateQuestion);
  } catch (error) {
      // console.error(error);
  }
};





const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = question.filter((item) => {
    return (
      item.attributes.question.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.id.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
  });
  setFilteredData(searchResults);
};



    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable : true,
      
          },
      {
        name: "Image ",
         selector: (row) => {
          if(row.attributes.img === "") {
            return "";
          }
          else {
           return <img width={50} height={50} src={`${Image_URL}/storage/${row.attributes.img}`} alt="" />
          }
         },
        
        sortable : true,
      grow:2,
      },
      {
        name: "Question ",
        selector: (row) => row.attributes.question,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:5,
      },
  
      
      {
        name: "Level",
        selector: (row) => row.attributes.level,
        sortable : true,

      },
      {
        name: "Choice1",
        selector: (row) => row.attributes.c1,
        grow:3,
      },
   
      // {
      //   name: "Image1",
      //   selector: (row) => <img width={50} height={50} src={`${Image_URL}/storage/${row.attributes.c1_img}`} alt="" />,

      //   grow:3,
      // },
      {
        name: "Choice2",
        selector: (row) => row.attributes.c2,
        grow:3,
      },
      // {
      //   name: "Image2",
      //   selector: (row) => 
      //       <img width={50} height={50} src={`${Image_URL}/storage/${row.attributes.c2_img}`} alt="" />,
         
      //   grow:3,
      // },
      {
        name: "Choice3",
        selector: (row) => row.attributes.c3,
        grow:3,
      },
      // {
      //   name: "Image3",
      //   selector: (row) =>  <img width={50} height={50} src={`${Image_URL}/storage/${row.attributes.c3_img}`} alt="" />,

      //   grow:3,
      // },
      {
        name: "Choice4",
        selector: (row) => row.attributes.c4,
        grow:3,
      },
      // {
      //   name: "Image4",
      //   selector: (row) => <img width={50} height={50} src={`${Image_URL}/storage/${row.attributes.c4_img}`} alt="" />,

      //   grow:3,
      // },
      {
        name: "Answer",
        selector: (row) => row.attributes.answer,
        grow:3,
      },
      {
        name: "Chapter",
        selector: (row) => row.attributes.lesson,
        sortable : true,

      },
      // {
      //   name: "Country Flag",
      //   selector: (row) => <img width={10} height={10} src={row.flag} alt="" />,
      // },
      {
        name: "Control",
        cell: row => <Link to={`/admin/editquestion/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-warning pe-3 ps-3 pt-2 pb-2">Edit</button>,
        </Link>

        
      },
      {
        name: "Control",
        cell: row => <button style={{background:"#" , border: "none"}} className="btn btn-danger pe-lg-2 ps-lg-2 pe-2 ps-2 pt-2 pb-2" onClick={() => alert('هل انت متأكد من حذف هذا السؤال') | handleDeleteClick(row.id)}>Delete</button> ,
        grow:2,
    },
     
    ];
    
    return (
        <>
      <div className="d-flex">
    
       <div className="question-data">
      
      <div className="main-title"> بنك الأسئلة </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                             customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : question} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                  <Link to="/admin/addquestion">
                                       <button className="btn btn-primary mt-4 mb-4 fs-5 fw-bold pt-2 pb-2 pe-4 ps-4 me-2">اضافة سؤال</button>   
                  </Link>
               }
                subHeader
                subHeaderComponent={
                    <input type="text" className="form-control mt-4 mb-4" placeholder="Search of Question" onChange={handleSearch} />

                }
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default Questiondata;