
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate , useParams} from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL, Image_URL, tableCustomStyles } from "../App";
import SidebarAdminMain from "./SidebarAdminMain";




function AdminLevelWeek() {
  
  
  const navigate = useNavigate();
  const {id} = useParams()
  const [week , setWeek] = useState([]);
  

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});



const getWeek = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/show-level-weeks/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        setWeek(response.data.data)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
  getWeek();
}, [])


const [rows, setRows] = useState([]);

const deleteRow = async(id, token) => {
  try {
      const response = await axios.delete(`${BASE_URL}/api/weeks/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
  
      swal({
        title: "تم حذف الحصة  بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
      // navigate("/admin/quizdata")
  } catch (error) {
      // console.error(error);
  }
};

const handleDeleteClick = async (id) => {
  const token = localStorage.getItem("token");
  try {
  const confirmDelete = await swal({
  title: "هل أنت متأكد من حذف هذه الحصة؟",
  icon: "warning",
  buttons: ["لا", "نعم"],
  dangerMode: true,
  });
  if (confirmDelete) {
    await deleteRow(id, token);
    const updateWeek = week.filter((row) => row.id !== id);
    setWeek(updateWeek);
  }
  } catch (error) {
  // console.error(error);
  }
  };


   
const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = week.filter((item) => {
    return (
      item.attributes.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
  });
  setFilteredData(searchResults);
};


    
  
    const columns = [
 
      {
        name: "Id",
        selector: (row) => row.id,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      },
      {
        name: "Name ",
        selector: (row) => row.attributes.name,
        sortable : true,
      grow:6,
      },
  
      
      {
        name: "Descreption",
        selector: (row) => row.attributes.description,
        grow:7,
      },
      {
        name: "Price ",
        selector: (row) => row.attributes.price,
        
      },
  
      {
        name: "Image",
        selector: (row) => <img width={50} height={50} src={`${Image_URL}/storage/${row.attributes.img}`} alt="" />

      },
      {
        name: "Level ",
        selector: (row) => row.attributes.level,
        
      },
      {
        name: "Created_At ",
        selector: (row) => row.attributes.created_at, 
        grow:3,
      },
      {
        name: "Updated_At ",
        selector: (row) => row.attributes.updated_at,
        grow:3,
      },
      {
        name: "Edit",
        cell: row => <Link to={`/admin/editweek/${row.id}`}>
        <button className="btn btn-warning ps-lg-3 pe-lg-3">Edit</button>
        </Link>
      },
      {
        name: "Student",
        cell: row => <Link to={`/admin/studentsweek/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-primary pe-lg-2 ps-lg-2 pe-1 ps-1 pt-2 pb-2">Student</button>,
        </Link>,
          grow:4
        
      },
      
      {
        name: "Sort",
        cell: row => <button style={{background:"#" , border: "none"}} className="btn btn-dark pe-3 ps-3 pt-2 pb-2">Sort</button> ,
        // grow:2,
    },

      {
        name: "Delete",
        cell: row => <button style={{background:"#" , border: "none"}} className="btn btn-danger pe-3 ps-3 pt-2 pb-2" onClick={() =>  handleDeleteClick(row.id)}>Delete</button> ,
        grow:4,
    },
     
    ];
    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  بيانات الحصص  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                                           customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : week} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                  <Link to="/admin/addweek">
                                       <button className="btn btn-primary mt-4 mb-4 fs-5 fw-bold pt-2 pb-2 pe-4 ps-4 me-2">اضافة حصة</button>   
                  </Link>
               }
                subHeader
                subHeaderComponent={
                  <input type="text" className="form-control mt-4 mb-4" placeholder="Search of Week" onChange={handleSearch} />

                }
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default AdminLevelWeek;