
import axios from "axios";
import { useEffect, useId, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import '../../styles/videodata.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { CSVLink } from "react-csv";
import swal from "sweetalert";
import { BASE_URL, tableCustomStyles } from "../../App";







function StudentHomework() {
    

    const navigate = useNavigate();
  
  
  const [student , setStudent] = useState([]);
  
    const {id} = useParams()
    // console.log(id);

    const token = localStorage.getItem('token' );
    const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
  
});



const getHomeWorkStudent = async () => {
    try {
      const token = localStorage.getItem('token' );
      const response = await axiosInstance.get(`/api/homework-student/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', 
            "ngrok-skip-browser-warning": "any",
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        setStudent(response.data.data)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
    getHomeWorkStudent();
}, [])

let totalStudent = student.length



const [rows, setRows] = useState([]);

const ClearRow = async(userId, token , row) => {
  try {
      const response = await axios.get(`${BASE_URL}/api/clear-student-homework/${userId}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
      }
      ) ;
      swal({
        title: "تم اعادة الواجب للطالب  بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
      // navigate("/admin/quizdata")
  } catch (error) { 
      // console.log(error);
  }

};
const handleClearClick = async (id) => {
  const token = localStorage.getItem('token' );
  try {
      await ClearRow(id, token);
      const updateStudentQuiz = student.filter(row => row.id !== id);
      setStudent(updateStudentQuiz);
  } catch (error) {
      // console.error(error);
  }
};






const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = student.filter((item) => {
    return (
      item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.id.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
  });
  setFilteredData(searchResults);
};


    const columns = [
 
      {
        name: "ID ",
        selector: (row) => row.id,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",

      },
      {
        name: "Name ",
        selector: (row) => row.name,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },
      {
        name: "Phone ",
        selector: (row) => row.phone,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },
      {
        name: "Phone ",
        selector: (row) => row.pphone,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },
  
      
   
      {
        name: "Degree ",
        selector: (row) => row.degree,
        // grow:3,
      },
   
      {
        name: "Government ",
        selector: (row) => row.government,
        // grow:3,
      },
   
     
      // {
      //   name: "Country Flag",
      //   selector: (row) => <img width={10} height={10} src={row.flag} alt="" />,
      // },
      {
        name: "Clear",
        cell: row =>  <button style={{background:"#" , border: "none"}} className="btn btn-success pe-lg-2 ps-lg-2 pe-2 ps-2 pt-2 pb-2" onClick={() => alert('هل انت متأكد من اعادة الامتحان لهذا الطالب  ') | handleClearClick(row.id)}>Clear</button> ,
        
      },

     
    ];
    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  الطلاب الممتحين </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                             customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : student} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                  <CSVLink
                  data = {student}
                  filename = "الطلاب الممتحنين"
                  className = "btn btn-success mb-3 mt-4 me-2" > Export File Excel </CSVLink> 
             }
           
                subHeader
                subHeaderComponent={
                  <>
                  <div className="total-search">
                    <h3 className="total-student mb-4"> <span>{totalStudent}</span> : اجمالي عدد الطلاب  اللي حلو الواجب  </h3>
                  <input type="text" className="form-control mt-4 mb-4 w-100" placeholder="Search of Student" onChange={handleSearch} /> 
                  </div>
                   </>
              
              }
                
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default StudentHomework;

