import { useState } from "react";
import { Link , useParams  } from "react-router-dom";

import '../../styles/sidebarProfile.css'

function SidebarProfileWithAdmin() {
        const {id} = useParams()
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    
    const menuItem=[
      {
        path:`/admin/studentprofile/${id}`,
        name:"ملف الطالب",
        icon: <i class="fa-solid fa-house" ></i>
      },
      {
        path:`/admin/studentprofilecourses/${id}`,
        name:"  الكورسات ",
        icon: <i class="fa-solid fa-book"></i>
      },
      {
        path:`/admin/studentprofilequizess/${id}`,
        name:"نتائج الامتحانات",
        icon: <i class="fa-solid fa-circle-question"></i>
      },
      {
        path:`/admin/studentprofilehomeworks/${id}`,
        name:"نتائج الواجبات",
        icon: <i class="fa-solid fa-circle-question"></i>
      },
   
    ]
    return (
        <>
                <div className="sidebar-profile">
                <div className="navigation">
    <ul>
            {
                menuItem.map((item , index) =>(
                        <li key={index} className="list">

                            <b></b>
                            <b></b>
                            <Link to={item.path}>
                                <span className="icon">{item.icon}</span>
                                <span className="title">{item.name}</span>
                            </Link>
                        </li>
                ))
            }
    </ul>
  </div>

                </div>
 
        </>
    )
}

export default SidebarProfileWithAdmin;