import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../styles/addhomework.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import { serialize } from "object-to-formdata";
import { BASE_URL } from "../../App";






function Addhomework () {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [level, setLevel] = useState("");
  const [cdn, setCdn] = useState("");
  const [start , setStart] = useState("");
  const [end , setEnd] = useState("");
  const [week_id, setWeek_id] = useState("");
  const [minutes, setMinutes] = useState("");
  const [errors, setErrors] = useState("");
  const [homework , setHomework] = useState([]);

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      "Content-Type": "multipart/form-data" ,
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
});

  const handleAddHomeWork = async (event) => {
    event.preventDefault();
    try {
      const options = {
        indices: true,

        nullsAsUndefineds: true,

        booleansAsIntegers: true,

        allowEmptyArrays: true,

        noFilesWithArrayNotation: false,

        dotsForObjectNotation: false,
      };
      const data = {title , cdn , week_id , level , minutes ,  start,  end}
      const formData = serialize(data, options);
      console.log([...formData]);
      
        await axiosInstance.post("/api/homeworks" , formData);
        setTitle("");
        setLevel("");
        setCdn("");
        setMinutes("")
        setWeek_id("");
        setStart("");
        setEnd("");
        swal({
            title: "تم إضافة الواجب بنجاح!",
            icon: "success",
            button: "حسناّ!",
          });
          navigate("/admin/homeworkdata")
         
         

    } catch(e) {
        if(e.response.status === 500) {
            // console.log(e.response);   
        }
        setErrors(e.response.data.errors);

    }
}




const getHomeWork = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/api/weeks-selection' , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data.map(item => item.id))
      // console.log(response.data.data);
      setHomework(response.data.data)
      const data = response.data.data;
               return data;
  } catch (error) {
      // console.log(error);
  }
}

useEffect (() =>{
getHomeWork();
}, [])

    return (
        <>
                  <div className="d-flex">
    
       <div className="add-homework ">
      
      <div className="main-title"> اضافة واجب </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                       <div className="codes pt-5">
                      
                        <div className="create-box">
                        <h2 className="create mb-4">  اضافة واجب </h2>
                        <Form onSubmit={handleAddHomeWork}>
        

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group className="mb-3" controlId="formBasicText">
        <Form.Label className="ms-2">اسم الواجب</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الواجب" name="title" onChange={(e) =>setTitle(e.target.value)}/>
        {errors.title && <div className="error mt-3">{errors.title[0]}</div>}

      </Form.Group>
      
      {/* <Form.Group controlId="formFile" className="mb-3">
        <Form.Label> ملف pdf</Form.Label>
        <Form.Control type="file" name="cdn" onChange={(e) =>setCdn(e.target.files[0])}/>
      </Form.Group> */}
      
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label> وقت الواجب</Form.Label>
        <Form.Control type="number" placeholder="ادخل وقت الواجب بالدقائق" name="minutes" onChange={(e) =>setMinutes(e.target.value)}/>
        {errors.minutes && <div className="error mt-3">{errors.minutes[0]}</div>}
      </Form.Group>
     </div>
     <div className="chooices d-lg-flex align-items-center mt-4">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ البداية</Form.Label>
        <Form.Control type="datetime-local"   name="start" onChange={(e)=>{setStart(e.target.value)}}/>
        {errors.start && <div className="error mt-3">{errors.start[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ النهاية</Form.Label>
        <Form.Control type="datetime-local"  name="end" onChange={(e)=>{setEnd(e.target.value)}}/>
        {errors.end && <div className="error mt-3">{errors.end[0]}</div>}

      </Form.Group>
      </div>

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group controlId="formFile" className="mb-3 mt-4">
      <Form.Select aria-label="Default select example" name="level" onChange={(e) =>setLevel(e.target.value)}>
      <option> اختر السنه الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
    {errors.level && <div className="error mt-3">{errors.level[0]}</div>}

      </Form.Group>

      
      <Form.Group controlId="formFile" className="mb-3 mt-4">
      <Form.Select aria-label="Default select example" name="week_id" onChange={(e) => setWeek_id(e.target.value)}>
      <option> اختر  الحصة</option>
      {
     homework.map(item => (
      <option key={item.id} value={item.id}>{item.name}</option>
    ))
      }
   
    </Form.Select>
    {errors.week_id && <div className="error mt-3">{errors.week_id[0]}</div>}

      </Form.Group>

     </div>
   
  
     {/* <DataTable
                      
                      className="table"
                      // getProps={() => customProps}
             columns={columns}
              data={filterCountries} 
              pagination 
              fixedHeader
              selectableRows
              // fixedHeaderScrollHeight="400px"
              highlightOnHover
              // actions={
              //     <button className="btn btn-success mt-4 mb-4">Excel</button>
              //                      <CSVLink  data={ countries} filename="RegisterUserData"  className="btn btn-success mb-3 mt-4 me-2">Export File Excel</CSVLink>
              // }
              subHeader
              subHeaderComponent={
                  <input 
                  type="text"
                   placeholder="Search here"
                   className=" search-table form-control" 
                   value={search}
                   onChange = {(e) => setSearch(e.target.value)}
                  />
              }
              /> */}
      <Button variant="primary" type="submit" className="create-button">
        اضف
      </Button>
    </Form>
                        </div>
                  
                        </div> 
              </div>
                   </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
        
    )
}

export default Addhomework;


