
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import '../../styles/addvideo.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { BASE_URL } from "../../App";


function Addvideo() {
  const navigate = useNavigate();

  const [week_id, setWeek_id] = useState("");
  const [title, setTitle] = useState("");
  // const [video_path, setVideo_path] = useState("");
  const [iframe, setIframe] = useState("");
  const [noviews, setNoviews] = useState("");
  const [minutes_views, setMinutes_views] = useState("");
  const [type , setType] = useState("");
  const [video_dauration , setVideo_dauration] = useState("");
  const [level , setLevel] = useState("");
  const [errors , setErrors] = useState("");
  const [video , setVideo] = useState([]);
 

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
});


  const handleAddVideo = async (event) => {
    event.preventDefault();
    // await csrf();
    try {
        await axiosInstance.post("/api/videos" , {
          week_id , 
          title ,
          iframe, 
          noviews , 
          minutes_views , 
          type,
          video_dauration,
          // video_path,
          level,
             
        });
        setWeek_id("");
        setTitle("");
        setIframe("");
        setNoviews("");
        setMinutes_views("");
        setType("");
        setVideo_dauration("");
        // setVideo_path("");
        setLevel("");
        swal({
            title: "تم إضافة الفديو بنجاح!",
            icon: "success",
            button: "حسناّ!",
          });
          navigate(`/admin/videodata`)
         

    } catch(e) {
        if(e.response.status === 422) {
            setErrors(e.response.data.errors);
            // console.log(e);   
        }
    }
}
 

const getVideo = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/api/weeks-selection' , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data);
      setVideo(response.data.data)
      const data = response.data.data;
               return data;
  } catch (error) {
      // console.log(error);
  }
}

useEffect (() =>{
  getVideo();
}, [])














    return (
        <>
      <div className="d-flex">
    
       <div className="add-video">
      
      <div className="main-title"> اضافة فيديو  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                    <div className="video">
                      
                        <div className="create-box">
                        <h2 className="title mt-4 mb-4">اضافة فيديو</h2>
                        <Form onSubmit={handleAddVideo}>
         


     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 me-lg-5 videoInput" controlId="formBasicText">
        <Form.Label> اسم الفديو</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الفديو" name="title" onChange={(e)=>{setTitle(e.target.value)}}/>
        {errors.title && <div className="error mt-3">{errors.title[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText ">
        <Form.Label> Iframe للفديو</Form.Label>
        <Form.Control type="text" placeholder="ادخل اللينك" name="title" onChange={(e)=>{setIframe(e.target.value)}}/>
        {errors.iframe && <div className="error mt-3">{errors.iframe[0]}</div>}

      </Form.Group>
    
     </div>

     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 me-lg-5 videoInput" controlId="formBasicText  ">
        <Form.Label> عدد المشاهدات</Form.Label>
        <Form.Control type="number" placeholder="ادخل عدد المشاهدات" name="noviews" onChange={(e)=>{setNoviews(e.target.value)}}/>
        {errors.noviews && <div className="error mt-3">{errors.noviews[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText ">
        <Form.Label>  عدد دقائق المشاهدة</Form.Label>
        <Form.Control type="number" placeholder="ادخل الدقائق" name="minutes_views" onChange={(e)=>{setMinutes_views(e.target.value)}}/>
        {errors.minutes_views && <div className="error mt-3">{errors.minutes_views[0]}</div>}

      </Form.Group>
    
     </div>
     <Form.Group className="mb-3 " controlId="formBasicText ">
        <Form.Label>  مدة دقائق الفديو</Form.Label>
        <Form.Control type="number" placeholder="ادخل مدة الدقائق" name="video_dauration" onChange={(e)=>{setVideo_dauration(e.target.value)}}/>
        {errors.video_dauration && <div className="error mt-3">{errors.video_dauration[0]}</div>}

      </Form.Group>

     <div className="chooices d-lg-flex align-items-center">
     
    <Form.Group className="mb-3 me-lg-5 videoInput" controlId="formBasicText  ">
    <Form.Select aria-label="Default select example" className="mt-3" name="level" onChange={(e) =>setLevel(e.target.value)} >
      <option>اخنر السنة الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
    {errors.level && <div className="error mt-3">{errors.level[0]}</div>}

      </Form.Group>


    <Form.Group controlId="formFile" className="mb-3 mt-4 videoInput">

      <Form.Select aria-label="Default select example" name="week_id" onChange={(e) => setWeek_id(e.target.value)}>
      <option> اختر  الحصة</option>
      {
      video.map(item => (
      <option key={item.id} value={item.id}>{item.name}</option>
      ))
      }
</Form.Select>
{errors.week_id && <div className="error mt-3">{errors.week_id[0]}</div>}

</Form.Group>

     </div>

     

      <Button variant="primary" type="submit" className="create-button">
        اضف
      </Button>
    </Form>
    </div>
                    </div>
              </div>
               </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default Addvideo;