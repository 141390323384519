
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import '../../styles/addvideo.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { BASE_URL } from "../../App";

function EditVideo() {


    const token = localStorage.getItem('token');

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
});

// const {id} = useParams();
const getVideo = async () => {
    try {
      const token = localStorage.getItem('token' );
      const response = await axiosInstance.get(`/api/videos/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        // setQuestion(response.data.data)
        setEdit(response.data.data.attributes);
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
    getVideo();
}, [])

// const handleInputChange = (event) => {
//   const { name, value } = event.target;
//   setEdit({ ...edit, [name]: value });
// };


const { id } = useParams();
const [edit, setEdit] = useState({
    week_id:"" , 
    title:"" ,
    iframe:"", 
    noviews:"" , 
    minutes_views: "", 
    type: "",
    video_dauration: "",
    video_path: "",
    level: "",
});
const [errors, setErrors] = useState({});

const Navigate = useNavigate();

const handleEdit = async (event) => {
  event.preventDefault();
  try {
    await axiosInstance.patch(
      `/api/videos/${id}`,
      {
        ...edit,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "ngrok-skip-browser-warning": "any",
        },
      }
    );
    setEdit({
        week_id:"" , 
        title:"" ,
        iframe:"", 
        noviews:"" , 
        minutes_views: "", 
        type: "",
        video_dauration: "",
        video_path: "",
        level: "",
    });
    swal({
      title: "تم تعديل الفديو بنجاح!",
      icon: "success",
      button: "حسناّ!",
    });
    Navigate("/admin/videodata");

  } catch (e) {
    if (e.response.status === 500) {
   
      // console.log(e.response);
      setErrors(e.response.data.errors);
    }
    else if (e.response.status === 401) {
      console.log(e);
    }
  }
};

 
      console.log(edit);
    

      const [video , setVideo] = useState([]);
      const getEditQuiz = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axiosInstance.get('/api/weeks-selection' , {
              headers: {
                'Content-Type': 'application/json',
                "ngrok-skip-browser-warning": "any",
                Authorization: `Bearer ${token}`,
              },
            });
            // console.log(response.data.data.map(item => item.id))
            // console.log(response.data);
            setVideo(response.data.data)
            const data = response.data.data;
                     return data;
        } catch (error) {
            // console.log(error);
        }
      }
      
      useEffect (() =>{
        getEditQuiz();
      }, [])
      



    return (
        <>
      <div className="d-flex">
    
       <div className="add-video">
      
      <div className="main-title"> اضافة فيديو  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                    <div className="video">
                      
                        <div className="create-box">
                        <h2 className="title mt-4 mb-4">اضافة فيديو</h2>
                        <Form onSubmit={handleEdit}>
         


     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 me-lg-5 videoInput" controlId="formBasicText">
        <Form.Label> اسم الفديو</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الفديو" name="title" value={edit.title} onChange={(e) => setEdit((prev) => ({ ...prev, title: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText ">
        <Form.Label> Iframe للفديو</Form.Label>
        <Form.Control type="text" placeholder="ادخل اللينك" name="iframe" value={edit.iframe} onChange={(e) => setEdit((prev) => ({ ...prev, iframe: e.target.value }))}/>
      </Form.Group>
    
     </div>

     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 me-lg-5 videoInput" controlId="formBasicText  ">
        <Form.Label> عدد المشاهدات</Form.Label>
        <Form.Control type="number" placeholder="ادخل عدد المشاهدات" name="noviews" value={edit.noviews} onChange={(e) => setEdit((prev) => ({ ...prev, noviews: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText ">
        <Form.Label> عرض الدقائق</Form.Label>
        <Form.Control type="number" placeholder="ادخل الدقائق" name="minutes_views" value={edit.minutes_views} onChange={(e) => setEdit((prev) => ({ ...prev, minutes_views: e.target.value }))}/>
      </Form.Group>
    
     </div>
     <Form.Group className="mb-3 " controlId="formBasicText ">
        <Form.Label> عرض مدة الدقائق</Form.Label>
        <Form.Control type="number" placeholder="ادخل مدة الدقائق" name="video_dauration" value={edit.video_dauration} onChange={(e) => setEdit((prev) => ({ ...prev, video_dauration: e.target.value }))}/>
      </Form.Group>

     <div className="chooices d-lg-flex align-items-center">
     
    <Form.Group className="mb-3 me-lg-5 videoInput" controlId="formBasicText  ">
    <Form.Select aria-label="Default select example" className="mt-4" name="level" value={edit.level} onChange={(e) => setEdit((prev) => ({ ...prev, level: e.target.value }))} >
      <option>اخنر السنة الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
      </Form.Group>

    <Form.Group controlId="formFile" className="mb-3 mt-4 videoInput">

<Form.Select aria-label="Default select example" name="week_id" value={edit.week_id} onChange={(e) => setEdit((prev) => ({ ...prev, week_id: e.target.value }))}>
<option> اختر  الحصة</option>
{
video.map(item => (
<option key={item.id} value={item.id}>{item.name}</option>
))
}



</Form.Select>
</Form.Group>
     </div>

     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className=" me-lg-5 videoInput">
    <Form.Label >  نوع الفديو</Form.Label>
     <Form.Select aria-label="Default select example" name="type" value={edit.type} onChange={(e) => setEdit((prev) => ({ ...prev, type: e.target.value }))}>
      {/* <option value="1" >الإجابة الأولي</option>
      <option value="2" >الإجابة الثانية</option>
      <option value="3">الإجابة الثالثة</option>
      <option value="3">الإجابة الرابعة</option> */}
    </Form.Select>
    </Form.Group>

    <Form.Group controlId="formFile" className="videoInput">
        <Form.Label> رفع الفديو </Form.Label>
        <Form.Control type="file" name="video_path"  onChange={(e) => setEdit((prev) => ({ ...prev, video_path: e.target.value }))}/>
      </Form.Group>
    
     </div>
  


      
        

      <Button variant="primary" type="submit" className="create-button">
        تعديل
      </Button>
    </Form>
    </div>
                    </div>
              </div>
               </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default EditVideo;