          import axios from "axios";
          import { useCallback, useState } from "react";
          import { useEffect } from "react";
          import { Col, Row } from "react-bootstrap";
          import { Link, useNavigate, useParams } from "react-router-dom";
          import swal from "sweetalert";
          import { BASE_URL, Image_URL } from "../../App";
          import '../../styles/years.css'
          import ScrollTopButton from "../ScrollTop";

          function Courses({loggedIn ,setLoggedIn}) {
            const navigate = useNavigate()

              const {id} = useParams()

              const [cards, setCards] = useState(() => []);
              const [levelTitle, setLevelTitle] = useState(() => "");
              const [isLoading, setIsLoading] = useState(true);

              
                // الريكويست الخاص بعرض الكورسات
              const fetchData = useCallback(async () => {
                try {
                    const token = localStorage.getItem('token');
                    const config = {
                      headers: {
                        'Content-Type': 'application/json',
                        "ngrok-skip-browser-warning": "any",
                        'Authorization': `Bearer ${token}`,
                      }
                    };
                    const response = await axios.get(`${BASE_URL}/api/show-level-weeks/${id}`, config);
                    // console.log(response.data.data);
                    setLevelTitle(response?.data);
                    setCards(response?.data?.data);
                    setIsLoading(false); 

                } catch (error) {
                  setIsLoading(false); 

                    // console.error(error);
                }
            }, [id]);
          
            useEffect(() => {
              fetchData();
              window.scrollTo(0, 0);

          }, [fetchData, id]);

          if (isLoading) {
            return <div className="main-loading">
              <div className="loading-course"> 
            <h2>  .... جاري تحميل الكورسات</h2>
            </div>; 
            </div>
          }
          


                    function formatDate(dateString) {
                      try {
                        const date = new Date(dateString);
                        const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
                        const formatter = new Intl.DateTimeFormat('ar-EG', options);
                        const formattedDate = formatter.format(date);
                        return formattedDate;
                      } catch (e) {
                        // console.error('Error formatting date', e);
                        return '';
                      }
                    }

                      
                    const handleBuyCourseNotLogin = () => {
                      swal({
                        title: "سجل الأول علشان تشترك في الكورس",
                        icon: "warning",
                        buttons: " حسناً",
                      }).then((willLogin) => {
                        if (willLogin) {
                          navigate('/loginuser');
                        }
                      });
                    };

              return (
                  <>
                    {
                      cards.length > 0
                      ?
                      <div className="grades mt-5 ">

                    {
                      loggedIn ? 
                        <div></div>
                      :
                   
                          <div className="button-courses"> 
                            <button class="button-52" role="button">{levelTitle.message}</button>

                          </div>
                    }

                  <div className="grade  pb-5 mb-5 mt-4">
                    <div className="container">
                      <Row>

                  {
                    cards.map((props) => {
                      return(
                        <>
                 <Col lg={4} md={6} key={props.id} className="mb-5">
                    <div className="main">
                     <div className="card shadow2">
                        <div className="images">
                          <img src={`${Image_URL}/storage/${props.attributes.img}`} alt=""/>
                          {/* <img src="/images/f1b7a8ca-a93c-416a-9784-370e97e1e0c0.jpg" alt=""/> */}
                          {/* <img src="/images/Logo.jpeg" alt=""/> */}
                        </div>
                        <div className="details mt-4">
                        <h2>{props.attributes.name}</h2>
                        <div className="line"></div>
                        <p>{props.attributes.description}</p>
                        </div>
                        <div className="price-date">

                            <div className="dateandbox">
                            <div className="test">
                          {
                                props.attributes.price === "0" 
                                ?  
                               
                                        <div className="subed">
                                        !   هذا الكورس  مجاني                                                             
                                          </div>
                              :
                              <div className="price-big">
                              <span className="price">{props.attributes.price}.00</span>
                              <span className="ms-3">جنيهاَ</span>
                              </div>   
                              }
                          </div>
           
              <div className="dates d-flex ms-2">
                  <span className="date d-flex">
                  {formatDate(props.attributes.created_at)}
                    <img src="/images/email.png" alt=""/>
                    </span>
                  <span className="date d-flex mt-1">
                  {formatDate(props.attributes.updated_at)}
                    <img src="/images/add-friend.png" alt=""/>
                    </span>
                </div>  
                            </div>
                     <div className="btns">
            <Link to="/loginuser" onClick={handleBuyCourseNotLogin} className="sub">! اشتري الأن</Link>
              <Link to={`/course-content/${props.id}`} className="buy">الدخول للكورس </Link>
            </div>      
                 </div>
                        
          </div>
              </div>
            </Col>
                        </>
                      )
                    })
                  }
                    </Row>
                      </div>
                  </div>
                  </div>
                  :
                  <div className="no-courses">
                    
                      <h2> !لا تتوفر كورسات حتي الأن </h2>
                  </div>
            
                    }
                                < ScrollTopButton />

                
                  </>
              )
          }
          export default Courses;