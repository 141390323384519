
import axios from "axios";
import { useEffect, useState } from "react";
import {Accordion ,  Col, Container, ProgressBar, Row } from "react-bootstrap";
import Collapsible from "react-collapsible";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
import '../../styles/pay.css'
import  '../../styles/sidebaruser.css'
import ScrollTopButton from "../ScrollTop";

function Pay() {
    const {id} = useParams()

  const [quizzes , setQuizzes] = useState([]);
  const [videos , setVideos] = useState([]);
  const [homeworks , setHomeworks] = useState([]);
  const [materials , setMaterials] = useState([]);
  // const [video_Duration , setVideo_Duration] = useState([]);
  const [score , setScore] = useState("")
  // const [date , setDate] = useState(0)
  const [details , setDetails] = useState([]);
  const [course , setCourse] = useState([])
  const [degree , setDegree] = useState("")
  const [mindegree , setMinDegree] = useState("")
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        'Authorization': `Bearer ${token}`,
      }
    };       
  
    axios.get(`${BASE_URL}/api/week-content/${id}`, config)
      .then(response => {
        setQuizzes(response.data.quizzes);
        setVideos(response.data.videos);
        setHomeworks(response.data.homeworks);
        setMaterials(response.data.materials);
        setDetails(response.data.data);
        setDegree(response.data.quizzes.map((props) => props.degree));
        setScore(response.data.quizzes.map((props) => props.score));
        setMinDegree(response.data.quizzes.map((props) => props.mindegree));
        // console.log(response.data)  ;
      //   const quizzesData = response.data.quizzes;
      //   setDegree(quizzesData.map(props => props.degree)[0]);
      // setScore(quizzesData.map(props => props.score)[0]);
      // setMinDegree(quizzesData.map(props => props.mindegree)[0]);
      })
      .catch(error => {
        if(error.response.status === 500) {
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

        function convertMinutesToHoursOrMinutes(minutes) {
          if (minutes < 60) {
            return `${minutes} دقيقة`;
          } else {
            const hours = Math.floor(minutes / 60);
            return `${hours} ساعات`;
          }
        }

        function formatDate(dateString) {
          try {
            const date = new Date(dateString);
            const options = {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              timeZone: 'Africa/Cairo',
              numberingSystem: 'arab'
            };
            const formatter = new Intl.DateTimeFormat('ar-EG', options);
            const formattedDate = formatter.format(date);
            return formattedDate;
          } catch (e) {
            return '';
          }
        }
        
        

            const token = localStorage.getItem('token');
             const axiosInstance = axios.create({
              baseURL: `${BASE_URL}`,
              headers: {
                   'Content-Type': 'application/json',
                   "ngrok-skip-browser-warning": "any",
                     Authorization: `Bearer ${token}`,
                  },
               });

//                    useEffect(() => {
//       const handleContextmenu = e => {
//           e.preventDefault()
//       }
//       document.addEventListener('contextmenu', handleContextmenu)
//       return function cleanup() {
//           document.removeEventListener('contextmenu', handleContextmenu)
//       }
// }, [])


const navigate = useNavigate()
const [buy , setBuy] = useState([])
const handleBuyCourses = async (id) => {
  try {
      await axiosInstance.get(`/api/user/enrollWeek/${id}`)
      .then(response => {
        // console.log(response);
        setBuy(response.data.data);
        swal({
          title: "تم شراء الكورس   بنجاح!",
          icon: "success",
          button: "حسنا!",
          customClass: {
            overlay: 'swal-overlay-custom',
        },
        }
        );
        
        navigate(`/contentcourse/${id}`);
        setDetails(prevDetails => ({
          ...prevDetails,
          Owned: true
        }));
      })
      
  } catch(e) {
      setIsLoading(false); 
      if(e.response.status === 404) {
        swal({
          title: "!رصيد غير كافي برجاء شحن المحفظة ",
          icon: "warning",
          button: "حسنا!",
          customClass: {
            overlay: 'swal-overlay-custom',
        },
        });
      }
  }
}

   

        const [isOpen, setIsOpen] = useState(false);
        const [isOverlayOpen, setIsOverlayOpen] = useState(false);
        
        const toggle = () => {
          setIsOpen(!isOpen);
          setIsOverlayOpen(!isOverlayOpen);
        }
        
        const close = () => {
          setIsOpen(false);
          setIsOverlayOpen(false);
        }

        const handleClick = () => {
          if (isOpen) {
            close();
          }
        };
        


        // function play() {
        //   if (/Win|Mac|Linux/.test(navigator.platform)) {
        //     swal({
        //       title: "  لما تيجي توقف وتشغل الفديو هيكون من الشريط من تحت ",
        //       icon: "warning",
        //       buttons: "تمام",
        //     });
        //   }
        // }
        

        function convertMinutesToHoursAndMinutes(minutes) {
          const hours = Math.floor(minutes / 60);
          const remainingMinutes = minutes % 60;
          return `${hours} ساعة و ${remainingMinutes} دقيقة`;
        }

        function formatDatewithoutclock(dateString) {
          try {
            const date = new Date(dateString);
            const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
            const formatter = new Intl.DateTimeFormat('ar-EG', options);
            const formattedDate = formatter.format(date);
            return formattedDate;
          } catch (e) {
            // console.error('Error formatting date', e);
            return '';
          }
        }


   

        

    return (
      <>
      {
        isLoading ?
        <div className="main-loading">
              <div className="loading-course"> 
            <h2>  .... جاري تحميل بيانات الكورس</h2>
            </div>; 
            </div>
        :
        <>  
        <div className="overlay" style={{ display: isOverlayOpen ? "block" : "none" , pointerEvents: isOpen ? "auto" : "none"}}  onClick={handleClick}></div>
        <div className="pay-smooth"  style={{ width: isOpen ? "" : "0px" }}>
              <div className="close-title">
              <button className="close-btn" onClick={close}>X</button>
              <h2>محتويات <span>{details.name}</span> </h2>
              </div>
                  <Row>
                    <Col lg={12}>
                          <div className="content-main">

                            {/* الجزء الخاص بالامتحان */}
                            {
                              quizzes.map((quiz) => {
                                return (
                                  <div className="content-card" key={quiz.id}>
                                    <div className="title">
                                    <h2>{quiz.title}</h2>
                                    <i class="fa-solid fa-file-circle-question"></i>
                                    </div>
                                    <ProgressBar now={Math.round(quiz.score * 100 / quiz.degree)} label={`${Math.round(quiz.score * 100 / quiz.degree)}%`}  className="custom-progress-bar-quiz"   style={{ backgroundColor: '#ff000029' , height: '20px'}}/>
                                      <div className="content-lesson">
                                      <p>اسم الامتحان : <span className="quiz-span">{quiz.title}</span></p>
                                  <p>مدة الامتحان : <span className="quiz-span">  {quiz.minutes} </span>{quiz.minutes > 10 ? "دقيقة" : "دقائق"} </p>
                                  <p> تاريخ البداية : <span className="quiz-span"> {`${formatDate(quiz.start)}`} </span> </p>
                                  <p> تاريخ النهاية : <span className="quiz-span"> {`${formatDate(quiz.end)}`} </span> </p>
                                  <p> عدد الأسئلة : <span className="quiz-span"> {quiz.noQuestions} </span> {quiz.noQuestions.length > 10 ? "سؤال" : "أسئلة"} </p>
                                  <p>درجة الامتحان : <span className="quiz-span">{quiz.degree} </span>{quiz.degree > 10 ? "درجة" : "درجات"}</p>
                                  <p>الدرجة الصغري للإمتحان : <span className="quiz-span">  {quiz.mindegree} </span>{quiz.mindegree > 10 ? "درجة" : "درجات"}</p>
                                    {
                                      quiz.prize === null
                                      ?
                                      ""
                                      :
                                      <p className="d-flex align-items-center  prize-p">  : جائزة الأوائل  <span className="quiz-span">   {quiz.prize}  </span></p>

                                    }
                                  {
                                    
                                    details.Owned
                                    ?

                                    // quiz.score === "non"
                                    // ?
                                    // ""
                                    // :
                                    quiz.score === "" 
                                    ?
                                    ""
                                    :
                                    <>
                                    <p>  درجتك في الامتحان : <span className="quiz-span"> {quiz.score} </span> درجة</p>
                                    <p>  النسبة المئوية في الامتحان : <span className="quiz-span"> {` % ${Math.round(quiz.score * 100 / quiz.degree)} `} </span></p>
                                    </>
                                    :
                                    ""
                                  }
    
    
                                    {
                            details.Owned
                            ?
                            
                              quiz.score === ""
                              ?
                              <Link to={`/startquiz/${quiz.id}`} className="quiz-btn">الدخول للإمتحان</Link>
                              :
                              <Link to={`/modelanswerquiz/${quiz.id}`} className="quiz-btn" >دوس هنا علشان تشوف إجاباتك</Link>
                            :
                            details.price === 0  
                            ?
<Link className="quiz-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>                          
     :
<Link className="quiz-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>                         
     }
                                  
                                    
                                      <Link to={`/TopRatedQuiz/${quiz.id}`} className="quiz-btn mt-4">دوس هنا علشان تشوف أوائل الامتحان</Link>

                                  
                                            </div>
                                  </div>
                                )
                              })
                            }
                                {/* الجزء الخاص بالفيديو */}

                            {
                              videos.map((video) => {
                                return (
                                  <div className="content-card" key={video.id}>
                                  <div className="title">
                                  <h2>{video.title}</h2>
                                  <i class="fa-solid fa-video"></i>
    
                                  </div>
                                    <div className="content-lesson">
                                    <p>اسم الفيديو : <span className="video-span">{video.title}</span></p>
                                <p>مدة الفيديو : <span className="video-span">  {video.video_dauration} </span>دقيقة </p>
                                <p> الحد الأقصي للمشاهدات : <span className="video-span">  {video.noviews} </span></p>
                                    {
                                      video.count === "" || !details.Owned
                                      ?
                                      ""
                                      :
                                      <p>عدد مرات مشاهداتك : <span className="video-span">  {video.count} </span></p>
                                    }
    
    
                                    {
                            details.Owned
                            ?
                              
                            video.count === video.noviews 
                            ?
                            <Link  className="video-btn">نفذت مشاهداتك</Link>
                            :
                            <Link to={`/video/${video.id}`}  className="video-btn">الدخول للفيديو</Link>
                 

                            :
                            details.price === 0  
                            ?
<Link className="video-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>                       
        :
<Link className="video-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>         
           
           }
    
    
                    
                                    </div>
                                </div>
                                )
                              })
                            }
                                    {/* الجزء الخاص بالهومورك */}

                              {
                              homeworks.map((homework) => {
                                return (
                                  <div className="content-card" key={homework.id}>
                                    <div className="title">
                                    <h2>{homework.title}</h2>
                                    <i class="fa-solid fa-file-circle-question"></i>
    
                                    </div>
                                    <ProgressBar now={Math.round(homework.score * 100 / homework.degree)} label={`${Math.round(homework.score * 100 / homework.degree)}%`}  className="custom-progress-bar-quiz"   style={{ backgroundColor: '#ff000029' , height: '20px'}}/>

                                    
                                      <div className="content-lesson">
                                      <p>اسم الواجب : <span className="quiz-span">{homework.title}</span></p>
                                      <p>مدة الامتحان : <span className="quiz-span">  {homework.minutes} </span>{homework.minutes > 10 ? "دقيقة" : "دقائق"} </p>
                                  <p> تاريخ البداية : <span className="quiz-span"> {`${formatDate(homework.start)}`} </span> </p>
                                  <p> تاريخ النهاية : <span className="quiz-span"> {`${formatDate(homework.end)}`} </span> </p>
                                  <p> عدد الأسئلة : <span className="quiz-span"> {homework.noQuestions} </span> {homework.noQuestions.length > 10 ? "سؤال" : "أسئلة"} </p>
                                  <p>درجة الواجب : <span className="quiz-span">  {homework.degree} </span>درجة</p>
                                  <p>الدرجة الصغري للواجب : <span className="quiz-span">  {homework.mindegree} </span>درجة</p>
                                  {
                                    details.Owned
                                    ?
                                    homework.score === ""
                                    ?
                                    ""
                                    :
                                      <>
                                    <p>   درجتك في الواجب : <span className="quiz-span"> {`  ${homework.score} `} </span> درجة</p>
                                    <p>  النسبة المئوية في الواجب : <span className="quiz-span"> {` % ${Math.round(homework.score * 100 / homework.degree)} `} </span></p>
                                      </>
                                      :
                                      ""
                                  }
    
    
                                    {
                            details.Owned
                            ?
                            
                            homework.score === ""
                              ?
                              <Link to={`/starthomework/${homework.id}`} className="quiz-btn">الدخول للواجب</Link>
                              :
                              <Link to={`/modelanswerhomework/${homework.id}`} className="quiz-btn" >دوس هنا علشان تشوف إجاباتك</Link>
                            :
                            details.price === 0  
                            ?
<Link className="quiz-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>                            
   :
<Link className="quiz-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>                      
        }
                                      </div>
                                  </div>
                                )
                              })
                               }
                                        {/* الجزء الخاص بالملفات */}

                            {
                              materials.map((material) => {
                                return (
                                  <div className="content-card" key={material.id}>
                                  <div className="title">
                                  <h2>{material.title}</h2>
                                  <i class="fa-solid fa-file-pdf"></i>
    
                                  </div>
                                    <div className="content-lesson">
                                    <p>اسم الملف : <span className="video-span">{material.title}</span></p>
                                    
    
                                {
                              details.Owned ?
                              <Link to={`/startpdf/${material.id}`} className="video-btn">الدخول للملف</Link>
                              :
          
                              details.price === 0  
                              ?
<Link className="video-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>                               
  :
<Link className="video-btn" onClick={() => swal({
    title: "هل تريد شراء هذا الكورس؟",
    icon: "warning",
    buttons: ["إلغاء", "نعم"],
    customClass: {
      overlay: 'swal-overlay-custom',
  },
  }).then((willBuy) => {
    if (willBuy) {
      handleBuyCourses(id);
    }
  })}>! اشتري الأن</Link>                            
   }
    
    
                    
                                    </div>
                                </div>
                                )
                              })
                            }
                          </div>
                      </Col>
                  </Row>
        </div>
    
    
    
                
            <div className="pay pt-5 pb-5" >
            {/* <h2 className="main-title">شراء {}</h2> */}
           <Container>
                <Row className="row ">
                <Col lg={12} className="mb-5"> 
                  <div className="main">
                  <div className="info">
                    <div className="image">
                    <img src="/images/bg.3b55416e926d51a05f75.png" alt=""/>
                    </div>
                     <div className="info-course d-flex">
                     <div className="content">
                     <h2 className="title">{details.name}  </h2>
                 <p>تاريخ انشاء الكورس <span className="date"> {formatDatewithoutclock(details.created_at)}  </span></p>
                     <p> اخر تحديث للكورس <span className="date"> {formatDatewithoutclock(details.updated_at)}  </span></p>
                    </div>
                    <div className="about">
                     <h3> <span>{videos.length}</span>: عددالفديوهات</h3>
                     <h3> <span>{quizzes.length}</span>: عدد الامتحانات </h3>
                     <h3> <span>{homeworks.length}</span>: عدد الواجبات </h3>
                     <h3> <span>{materials.length}</span>: عدد الملفات </h3>
                    </div>
                     </div>
                
    </div>
    
                  </div>
                   </Col>
                  <Row className="p-0 m-0">
    
                   <Col lg={4}>
                  <div className="card-pay">
                    <div className="image-pay">
                    <img src={`${Image_URL}/storage/${details.img}`} alt="" class="image-title"/>
                    {/* <img src="/images/f1b7a8ca-a93c-416a-9784-370e97e1e0c0.jpg"  className="image-title" alt=""/> */}
                    {/* <img src="/images/Logo.jpeg" className="image-title" alt=""/> */}
                    </div>
                    <div className="price d-flex">
                      <span className="ms-2"> {details.price}.00 </span>
                      <span> جنيهاَ</span>
                    </div>
                    <div class="button-pay">
    
                        {
                          details.price === 0 
                          ?
                          <button class="button-57" role="button" style={{ pointerEvents: "none" , backgroundColor: "#f43f5e" , color: "#fff"}} >
                            <span class="text"> !  هذا الكورس المجاني </span>
                            <span > !  هذا الكورس المجاني </span>
                        </button>                       
                         :
                          details.Owned
                          ? 
                          <button class="button-57" role="button" style={{ pointerEvents: "none" , backgroundColor: "#f43f5e" , color: "#fff"}}><span class="text" > تم شراء هذا الكورس</span><span> تم شراء هذا الكورس </span></button>
                            :
                            <button class="button-57" role="button" onClick={() =>
                              swal({
                                  title: "هل تريد شراء هذا الكورس ؟",
                                  icon: "warning",
                                  buttons: ["إلغاء", "نعم"],
                              }).then((willBuy) => {
                                  if (willBuy) {
                                      handleBuyCourses(id);
                                  }
                              })
                          }>
                              <span class="text">   شراء الكورس  </span>
                              <span >   شراء الكورس  </span>
                          </button>                 
                             }
    
                        </div>
                        <div className="button-pay">
                          <button className="toggle-btn" onClick={toggle}>
                            اضغط هنا لرؤية محتويات الحصة
                          </button>
                        </div>
                    <div className="info-pay">
                      <div className="d-flex">
                        <span>المحتوي</span>
                       <div class="watch-count d-flex">
                        <span> + {convertMinutesToHoursOrMinutes(details.videoMinutes)}</span>
                       {/* <span className="me-2"> ساعات</span> */}
                       </div>
                      </div>
                      {/* <div className="d-flex mt-4">
                        <span>عدد الأسئلة</span>
                        <div class="watch-count d-flex">
                        <span> 5</span>
                       <span className="me-2"> اسئلة</span>
                       </div>
                      </div> */}
                    </div>
                  </div>
                </Col>
    
                <Col lg={8}>
                  <div className="image-main-title">
                  <img src={`${Image_URL}/storage/${details.img}`} alt="" class="image-title"/>
                  </div>
                </Col>
                  </Row>
                {/* <Row className="m-0 p-0" >
                  <Col lg={12}>
                  <Accordion defaultActiveKey="0" flush className="accord">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h2>محتويات  <span>{details.name}</span></h2>
            </Accordion.Header>    
            <Accordion.Body>
           
              <div className="main-body">
              {
                quizzes.map((quiz) =>{
                    return (
                      <div className="quizess" key={quiz.id}>
                      <Accordion.Header>
                      <div className="body-title d-flex align-items-center">
                    <h2>
                          {quiz.title}
                    </h2>
                    <i class="fa-solid fa-file-circle-question"></i>
                      </div>
                      </Accordion.Header>
                    <Accordion.Body>
                          <div className="body-accord d-flex">
    
                           {
                            details.Owned
                            ?
                            
                              quiz.score === ""
                              ?
                              <Link to={`/startquiz/${quiz.id}`} className="quiz-btn">الدخول للإمتحان</Link>
                              :
                              <Link to={`/modelanswerquiz/${quiz.id}`} className="quiz-btn" >{`${quiz.score } /  ${quiz.degree}`}</Link>
                            :
                            details.price === 0  
                            ?
                            <Link to={`/chargewallet`}  className="quiz-btn"> إشتراك في الكورس</Link>
                            :
                            <Link to={`/chargewallet`}  className="quiz-btn"> إشتراك في الكورس</Link>
                           }
    
                            <div className="content-1">
                              <p> اسم الامتحان:<span className="ms-2">{quiz.title}</span></p>
                              <p>مدة الامتحان: <span className="ms-2">{quiz.minutes}</span>دقيقة</p>
                              <p>درجة الامتحان: <span className="ms-2">{quiz.degree}</span>درجة</p>
                              <p>الدرجة الصغري للإمتحان: <span className="ms-2">{quiz.mindegree}</span> درجة</p>
                              {
                                quiz.score === ""
                                ?
                                ""
                                :
                                <p> درجتك في الامتحان: <span className="ms-2">{quiz.score}</span> درجة</p>
                              }
                     
                
                            </div>
                          </div>
                    </Accordion.Body>
                      </div>
                    )
                })
              }
            
                {
                  videos.map((video) => {
                    return (
                      <div className="videos" key={video.id}>
                      <Accordion.Header>
                      <div className="body-title d-flex align-items-center">
                    <h2>
                          {video.title}
                    </h2>
                    <i class="fa-solid fa-video"></i>
                      </div>
                      </Accordion.Header>
                    <Accordion.Body>
                          <div className="body-accord d-flex">
                          {
                            details.Owned
                            ?
                            video.count === video.noviews 
                            ?
                            <Link  className="video-btn">نفذت مشاهداتك</Link>
                            :
                        
                            
                            <Link to={`/video/${video.id}`} className="video-btn">الدخول للفيديو</Link>
    
                            :
                            details.price === 0  
                            ?
                            <Link to={`/chargewallet`}  className="video-btn"> إشتراك في الكورس</Link>
                            :
                            <Link to={`/chargewallet`}  className="video-btn"> إشتراك في الكورس</Link>
                           }
                            <div className="content-2">
                              <p> اسم الفيديو:<span>{video.title}</span></p>
                              <p>مدة الفيديو: <span>{video.video_dauration} </span>دقيقة</p>
                              <p>  الحد الأقصي للمشاهدات: <span>{video.noviews}</span></p>
                              {
                                video.count === ""
                                ?
                                ""
                                :
                                <p> عدد مرات مشاهداتك: <span>{video.count}</span></p>
                              }
                            </div>
                          </div>
                    </Accordion.Body>
                      </div>
                    )
                  })
                }
    
              {
                homeworks.map((homework) => {
                      return(
                        <div className="Homework" key={homework.id}>
              <Accordion.Header>
              <div className="body-title d-flex align-items-center">
            <h2>
                          {homework.title}
            </h2>
            <i class="fa-solid fa-file-circle-question"></i>
              </div>
                </Accordion.Header>
               <Accordion.Body>
                  <div className="body-accord d-flex">
                         
                           {
                            details.Owned
                            ?
                            homework.score === ""
                            ?
                            <Link to={`/starthomework/${homework.id}`} className="quiz-btn">الدخول للواجب</Link>
                            :
                            <Link to={`/modelanswerhomework/${homework.id}`} className="quiz-btn" >{`${homework.score } /  ${homework.degree}`}</Link>
                            :
                            details.price === 0  
                            ?
                            <Link to={`/chargewallet`}  className="quiz-btn"> إشتراك في الكورس</Link>
                            :
                            <Link to={`/chargewallet`}  className="quiz-btn"> إشتراك في الكورس</Link>
                           }
    
                    <div className="content-1">
                      <p> اسم الواجب:<span>{homework.title}</span></p>
                      <p> درجة الواجب: <span>{homework.degree}</span>  درجة </p>
                      <p>مدة الواجب: <span className="ms-2">{homework.minutes}</span>دقيقة</p>
                      {
                                homework.score === ""
                                ?
                                ""
                                :
                                <p> درجتك في الواجب: <span className="ms-2">{homework.score}</span> درجة</p>
                              }
                    </div>
                  </div>
            </Accordion.Body>
              </div>
                      )
                })
              }
    
    
                  {
                    materials.map((material) => {
                      return (
                        <div className="material" key={material.id}>
              <Accordion.Header>
              <div className="body-title d-flex align-items-center">
            <h2>
                            {material.title}
            </h2>
             <i class="fa-solid fa-file-pdf"></i>
              </div>
              </Accordion.Header>
            <Accordion.Body>
                  <div className="body-accord d-flex">
                    {
                        details.Owned ?
                        <Link to={`/startpdf/${material.id}`} className="video-btn">الدخول للملف</Link>
                        :
    
                        details.price === 0  
                        ?
                        <Link to={`/chargewallet`}  className="video-btn"> إشتراك في الكورس</Link>
                        :
                        <Link to={`/chargewallet`}  className="video-btn"> إشتراك في الكورس</Link>
    
                    }
                    <div className="content-2">
                      <p> اسم الملف:<span>{material.title}</span></p>
                    </div>
                  </div>
                </Accordion.Body>
                </div>
                      )
                    })
                  }
    
              </div>
            </Accordion.Body>
          </Accordion.Item>
         </Accordion>
    
    
    
    
                  </Col>
                </Row>       */}
                </Row>
            </Container>
            </div>
            < ScrollTopButton />
    
     
            </>
      }
      </>
      
    )
}
export default Pay;