import SidebarAdmin from "./SidebarAdmin";
import '../styles/homepage-admin.css'
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";




function HomepageAdmin() {
    return (
        <> 
        <div className="d-flex">
    
        <div className="homepage-admin ">
        <div className="main-title"> الصفوف الدراسية</div>
      
        <Container>
      
            <Row>
                <Col lg={4}>
                    <div className="card">
                        <div className="box">
                            <div className="content">
                                <h2>Konoz</h2>
                                <div className="mt-4">
                                <h3>الصف الأول الثانوي</h3>
                                <p className="mb-5 fw-bold text-black-50 fs-5">أ/  محمد سعد</p>
                                <Link to="/admin/year/1">الدخول  للصف</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
              
                <Col lg={4}>
                    <div className="card">
                        <div className="box">
                            <div className="content">
                                <h2>Konoz</h2>
                                <div className="mt-4">
                                <h3>الصف الثاني الثانوي</h3>
                                <p className="mb-5 fw-bold text-black-50 fs-5">أ/  محمد سعد</p>
                                <Link to="/admin/year/2">الدخول  للصف</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
              
                <Col lg={4}>
                    <div className="card">
                        <div className="box">
                            <div className="content">
                                <h2>Konoz</h2>
                                <div className="mt-4">
                                <h3>الصف الثالث الثانوي</h3>
                                <p className="mb-5 fw-bold text-black-50 fs-5">أ/  محمد سعد</p>
                                <Link to="/admin/year/3">الدخول  للصف</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
             
            </Row>
        </Container>

      
        </div>
        <div className="sidebar">
       <SidebarAdmin />
       </div>
        </div>
  


        </>
    )
}

export default HomepageAdmin;