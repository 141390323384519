
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import SidebarAdmin from "../SidebarAdmin";
import '../../styles/videodata.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { BASE_URL, Image_URL, tableCustomStyles } from "../../App";
import swal from "sweetalert";







function Adminfile() {
  
  
      // const {id} = useParams()
  
 
  const [file , setFile] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});

const getFiles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/api/materials' , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data.data.map(item => item.id))
        
   
        setFile(response.data.data)
        // setFilteredData(response.data.data);

        const data = response.data.data;
                 return data;
    } catch (error) {
      if(error.response.status === 500) {
        // console.log(error);

      }
    }
}

useEffect (() =>{
  getFiles();
}, [])


const deleteRow = async(id, token) => {
  try {
      const response = await axios.delete(`${BASE_URL}/api/materials/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      swal({
        title: "تم حذف الملف بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
  } catch (error) {
      // console.error(error);
  }
};


const [rows, setRows] = useState([]);

const handleDeleteClick = async (id) => {
  const token = localStorage.getItem('token');
  try {
      await deleteRow(id, token);
      const updateFile = file.filter(row => row.id !== id);
      setFile(updateFile);
  } catch (error) {
      // console.error(error);
  }
};



const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = file.filter((item) => {
    return (
      item.attributes.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
  });
  setFilteredData(searchResults);
};


  
    const columns = [
      {
        name: "ID ",
        id: "id",
        selector: (row) => row.id,
        sortable : true,
        style: {
          // padding: "20px 15px"
        }
       
      },
      {
        name: "Week_ID ",
        selector: (row) => row.attributes.week_id,
        sortable : true,
   
      grow:2,
      },
      {
        name: "Name ",
        selector: (row) => row.attributes.title,
        sortable : true,

      grow:2,
      },
      {
        name: "Link ",
        selector: (row) => row.attributes.cdn,
        sortable : true,
   
      grow:2,
      },
      {
        name: "Created_at ",
        selector: (row) => row.attributes.created_at,
        sortable : true,

      grow:2,
      },
  
      {
        name: "Updated_at ",
        selector: (row) => row.attributes.updated_at,
        sortable : true,
 
      grow:2,
      },
  
      {
        name: "Edit",
        cell: row => <Link to={`/admin/editfile/${row.id}`}>
        <button style={{background:"#28a745" , border: "none"}} className="btn btn-success pe-3 ps-3 pt-2 pb-2" >Edit</button>,
        </Link>

        
      },
      
      {
        name: "Delete",
        cell: row => <button style={{background:"#" , border: "none"}} className="btn btn-danger pe-lg-3 ps-lg-3 ps-2 pe-2 pt-2 pb-2" onClick={() => alert('هل انت متأكد من حذف هذا الملف') | handleDeleteClick(row.id)}>Delete</button> ,
        grow:2,
    },
     
    ];
    
    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  بيانات الملفات  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
               customStyles={tableCustomStyles}
               columns={columns}
               data={filteredData.length > 0 ? filteredData : file} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                  <Link to="/admin/addfile">
                                       <button className="btn btn-primary mt-4 mb-4 fs-5 fw-bold pt-2 pb-2 pe-4 ps-4 me-2">اضافة ملف</button>   
                  </Link>
               }
                subHeader
                subHeaderComponent={
                  <input type="text" className="form-control mt-4 mb-4" placeholder="Search of file" onChange={handleSearch} />

                }
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default Adminfile;

