import '../styles/sidebarAdmin.css'

import { useRef , useState , useEffect } from 'react';  
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../App';

        
function SidebarAdminMain () {

    const {id} = useParams();
    // console.log(id);

    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);

    const [name, setName] = useState([]);
    const [ role , setRole] = useState("")
  

      
   
  
      useEffect(() => {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
            'Authorization': `Bearer ${token}`,
          }
        };
        axios.get(`${BASE_URL}/api/Auth`, config)
          .then(response => {
            setRole(response.data.role)
            setName(response.data);
          })
          .catch(error => {
            // console.error(error);
          });
      }, [role]);

      const roled = localStorage.getItem("sha1");

      

    
    const menuItems = [
        {
            path:"/admin",
            name:"الصفوف",
            icon: <i class="fa-solid fa-users"></i>
        },
        // {
        //     path:`/admin/year/${id}`,
        //     name:"احصائيات الصف",
        //     icon: <i class="fa-solid fa-users"></i>
        // },
        
        // {
        //     path:`/admin/allstudentdata`,
        //     name:"  بيانات الطلاب",
        //     icon:  <i class="fa-solid fa-users"></i>
        // },
        {
            path: roled > 1 ? "/admin/createcodes" : "/admin",
            name:" انشاء الأكواد",
            icon: <i class="fa-solid fa-ticket"></i>,
        },
        {
            path:`/admin/questiondata`,
            name:" بنك الأسئلة",
            icon: <i class="fa-solid fa-circle-question"></i>
        },
        {
            path:"/admin/videodata",
            name:" الفديوهات",
            icon: <i class="fa-solid fa-video"></i>
        },
        {
            path:`/admin/quizdata`,
            name:" الكويزات",
            icon: <i class="fa-solid fa-file-circle-question"></i>
        },
        {
            path:`/admin/homeworkdata`,
            name:" الواجبات",
            icon: <i class="fa-solid fa-book-open"></i>
        },
        {
            path:"/admin/filedata",
            name:"الكتب الخارجية",
            icon: <i class="fa-solid fa-file-pdf"></i>
        },
        {
            path:"/admin/weekdata",
            name:" الحصص",
            icon: <i class="fa-solid fa-calendar-week"></i>
        },
    
    ];
    
    

    return(
        <>
                <div className="sidebar-admin">

                <div class="navigation" style={{width : isOpen ? "300px" : "70px"}}>
     <ul>
     {
            menuItems.map((item , index) => (
                <li key={index} className="list" >
                    <b></b>
                    <b></b>
                    <Link to={item.path}>
                    <span className="icon">{item.icon}</span>
                    <span className="title">{item.name}</span>
                    </Link>

                </li>
            ))
      }
      
    </ul> 
  </div>
  <div class="toggle" onClick={toggle} style={{background : isOpen ? "#FFBF00" : "#e03a56"}}>
      <ion-icon name="menu-outline" class="open" style={{display : isOpen ? "none" :  "block"}}></ion-icon>
      <ion-icon name="close-outline" class="close" style={{display : isOpen ? "block" :  "none"}}></ion-icon>
    </div>


                </div>


        
        </>
    )
}

export default SidebarAdminMain;

