import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL } from "../App";


export const Loginuser = ({setLoggedIn , loggedIn, clearToken }) => {
       const navigate = useNavigate();

     const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState("");

    const handleLogin = async (event) => {
      event.preventDefault();
      try {
          await axios.post(`${BASE_URL}/api/login` , {email , password})
              .then(response => {
                  const token = response.data.data.token;
                  const role = response.data.data.user.role;
                  localStorage.setItem("token", token);
                  localStorage.setItem("sha1", role);
                  swal({
                    title: "تم تسجيل الدخول بنجاح!",
                    icon: "success",
                    button: "حسنا!",
                  }).then(() => {
                    setLoggedIn(true);
                    if (role > 0) {
                      navigate("/admin");
                    } else {
                      navigate("/");
                    }
                    window.location.reload();
                  });
              })
          setEmail("");
          setPassword("");
      }catch (e) {


             if(e.response.status === 422 ) {
                setErrors(e.response.data.errors)
             
              }
            else if (e.response.status === 401) {
            swal({
                title: "! خطأ في تسجيل الدخول ",
                text: "يرجي التحقق من رقم الهاتف او الباسورد",
                icon: "warning",
                button: "! اعد المحاولة",
              });
        }
        else if (e.response.status === 402) {
            swal({
                title: " هذا الحساب محظور يرجى التواصل معنا",
                text: "يرجي الرجوع إلي المساعدين",
                icon: "warning",
                button: "! فهمت ",
              });
        }
    }
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    
  
    
return (
    <>
    <div className="register-user pb-5 pt-5">
    <div className="register-button mb-5">
<button class="login-btn" role="button">تسجيل الدخول </button>
    </div>

      <Container>

            <Row>
                <Col lg={8} className="ms-auto me-auto">
                <div className="box">
                <Form onSubmit={handleLogin}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label> رقم الهاتف</Form.Label>
        <Form.Control type="number" placeholder="ادخل رقم الهاتف" name="email"  onChange={(e) =>setEmail(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.email && <div className="error">{errors.email[0]}</div>}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>كلمة المرور</Form.Label>
        <Form.Control type="password" placeholder="ادخل كلمة المرور" name="password" required onChange={(e) =>setPassword(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.password && <div className="error">{errors.password[0]}</div>}
        </Form.Text>
      </Form.Group>

 
       <Button variant="outline-primary" className="mt-3 mb-3" type="submit">تسجيل الدخول</Button>{' '}
      <Link to="/registeruser">!انشئ حسابك الأن</Link>
    </Form>
                </div>
                </Col>
            </Row>
        </Container>
    </div>
    </>
)
}

export default Loginuser;