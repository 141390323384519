import { useEffect } from 'react';
import '../styles/Numberphone.css'
    
function NumberPhone() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <div className="number-phone">
                <h2 className="d-block">01222624684</h2>
                <h2>01005829080</h2>

            </div>
        </>
    )
}

export default NumberPhone;