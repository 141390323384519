
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import '../../styles/addquestion.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { serialize } from "object-to-formdata";
import { BASE_URL } from "../../App";






function Addquestion() {
  
  
const [img , setImg] = useState(null)
const [question , setQuestion] = useState("")
const [level , setLevel] = useState("")
const [c1 , setC1] = useState("")
const [c2 , setC2] = useState("")
const [c3 , setC3] = useState("")
const [c4 , setC4] = useState("")
const [c1_img , setC1_img] = useState(null)
const [c2_img , setC2_img] = useState(null)
const [c3_img , setC3_img] = useState(null)
const [c4_img , setC4_img] = useState(null)
const [answer , setAnswer] = useState("")
const [lesson , setLesson] = useState("")
const [errors , setErrors] = useState("")
const [edit , setEdit] = useState("")
const navigate = useNavigate();

const token = localStorage.getItem('token');
const axiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "multipart/form-data" ,
      "ngrok-skip-browser-warning": "any",
      Authorization: `Bearer ${token}`,
  },
});


const handleAddQuestion = async (event) => {
  event.preventDefault();
  // await csrf();
  try {
    const options = {
      indices: true,

      nullsAsUndefineds: true,

      booleansAsIntegers: true,

      allowEmptyArrays: true,

      noFilesWithArrayNotation: false,

      dotsForObjectNotation: false,
    };

    const data = {  
          img , 
      question ,
      level, 
      c1 , 
      c2 , 
      c3,
      c4,
      c1_img,
      c2_img,
      c3_img,
      c4_img,
      answer,
      lesson, 
    }
    const formData = serialize(data, options);
    // console.log([...formData]);
      await axiosInstance.post("/api/questions" , formData);
      setImg(null);
      setQuestion("");
      setLevel("");
      setC1("");
      setC2("");
      setC3("");
      setC4("");
      setC1_img(null);
      setC2_img(null);
      setC3_img(null);
      setC4_img(null);
      setAnswer("");
      setLesson("");
      swal({
          title: "تم إضافة السؤال بنجاح!",
          icon: "success",
          button: "حسناّ!",
        });
        navigate("/admin/questiondata")
       

  } catch(e) {
      if(e.response.status === 422) {
          setErrors(e.response.data.errors);
          // console.log(e);   
      }
  }
}

  
  
    
    return (
        <>
     <div className="d-flex">
    
    <div className="add-question">
   
   <div className="main-title"> أضافة الأسئلة</div>
 
   <Container>
 
       <Row>
      
       <Col lg={12}>
                  <div className="quiz-content">
                 <div className="question">
                   
                     <div className="create-box">
                     <h2 className="title mt-4 mb-4">أضافة السؤال</h2>
                     <Form onSubmit={handleAddQuestion}>
                     <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
     <Form.Label>* السؤال</Form.Label>
     <Form.Control as="textarea" rows={2}  name="question"   onChange={(e) =>setQuestion(e.target.value)}/>
     {errors.question && <div className="error mt-3">{errors.question[0]}</div>}

   </Form.Group>

   <Form.Group controlId="formFile" className="mb-3">
     <Form.Label>صورة السؤال</Form.Label>
     <Form.Control type="file" name="img"  onChange={(e) =>setImg(e.target.files[0])}/>
     {errors.img && <div className="error mt-3">{errors.img[0]}</div>}

   </Form.Group>

  <div className="chooices d-lg-flex align-items-center mt-5">
  <Form.Group className="mb-3" controlId="formBasicText  ">
     <Form.Label>الأختيار الأول</Form.Label>
     <Form.Control type="text" placeholder="ادخل الأجابة"   name="c1"   onChange={(e) =>setC1(e.target.value)}/>
     {errors.c1 && <div className="error mt-3">{errors.c1[0]}</div>}

   </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicText">
     <Form.Label>الأختيار الثاني</Form.Label>
     <Form.Control type="text" placeholder="ادخل الأجابة"  name="c2"  onChange={(e) =>setC2(e.target.value)}/>
     {errors.c2 && <div className="error mt-3">{errors.c2[0]}</div>}

   </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicText ">
     <Form.Label>الأختيار الثالث</Form.Label>
     <Form.Control type="text" placeholder="ادخل الأجابة" name="c3"   onChange={(e) =>setC3(e.target.value)}/>
     {errors.c3 && <div className="error mt-3">{errors.c3[0]}</div>}

   </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicText ">
     <Form.Label>الأختيار الرابع</Form.Label>
     <Form.Control type="text" placeholder="ادخل الأجابة" name="c4"   onChange={(e) =>setC4(e.target.value)}/>
     {errors.c4 && <div className="error mt-3">{errors.c4[0]}</div>}

   </Form.Group>
  </div>
  <div className="chooices  d-lg-flex align-items-center mt-5">

  <Form.Group controlId="formFile" className="image mb-3" >
     <Form.Label>صورة الإجابة الأولي</Form.Label>
     <Form.Control type="file" name="c1_img"   onChange={(e) =>setC1_img(e.target.files[0])}/>
     {errors.c1_img && <div className="error mt-3">{errors.c1_img[0]}</div>}

   </Form.Group>
  <Form.Group controlId="formFile" className=" image mb-3" >
     <Form.Label>صورة الإحابة الثانية</Form.Label>
     <Form.Control type="file" name="c2_img"   onChange={(e) =>setC2_img(e.target.files[0])}/>
     {errors.c2_img && <div className="error mt-3">{errors.c2_img[0]}</div>}

   </Form.Group>
  <Form.Group controlId="formFile" className=" image mb-3" >
     <Form.Label>صورة الإجابة الثالثة</Form.Label>
     <Form.Control type="file" name="c3_img"   onChange={(e) =>setC3_img(e.target.files[0])}/>
     {errors.c3_img && <div className="error mt-3">{errors.c3_img[0]}</div>}

   </Form.Group>
  <Form.Group controlId="formFile" className=" image mb-3">
     <Form.Label>صورة الإجابة الرابعة</Form.Label>
     <Form.Control type="file" name="c4_img"  onChange={(e) =>setC4_img(e.target.files[0])}/>
     {errors.c4_img && <div className="error mt-3">{errors.c4_img[0]}</div>}

   </Form.Group>

  </div>
 
 <Form.Group className="mt-4">
 <Form.Label > اختيار إجابة السؤال</Form.Label>
  <Form.Select aria-label="Default select example" className="mt-2" name="answer" onChange={(e) =>setAnswer(e.target.value)}>
   <option >اختر الإجابة</option>
   <option value="1" >الإجابة الأولي</option>
   <option value="2" >الإجابة الثانية</option>
   <option value="3">الإجابة الثالثة</option>
   <option value="4">الإجابة الرابعة</option>
 </Form.Select>
 {errors.answer && <div className="error mt-3">{errors.answer[0]}</div>}

 </Form.Group>


 {/* <div className="chooices d-lg-flex align-items-center mt-5"> */}
 <Form.Group className="mt-4">
 <Form.Label >ادخل الحصة</Form.Label>
  <Form.Control type="text" className="mt-2" name="lesson" placeholder="ادخل اسم الحصة" onChange={(e) =>setLesson(e.target.value)}></Form.Control> 


   {/* <option >اختر الشابتر</option>
   <option value="1">الإجابة الأولي</option> */}

 {errors.lesson && <div className="error mt-3">{errors.lesson[0]}</div>}

 </Form.Group>


 {/* </div> */}
 <Form.Group controlId="formFile" className="mb-3 mt-4">
      <Form.Select aria-label="Default select example" name="level" onChange={(e) =>setLevel(e.target.value)}>
      <option> اختر السنه الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
    {errors.level && <div className="error mt-3">{errors.level[0]}</div>}

      </Form.Group>


   
     

   <Button variant="primary" type="submit" className="create-button">
     اضف
   </Button>
 </Form>
 </div>
                 </div>
           </div>
            </Col>
       </Row>
   </Container>

 
   </div>
   <div className="sidebar">
    <SidebarAdminMain />
    </div>
    </div>
        </>
    )
}

export default Addquestion;