import React, { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr';
import { useParams } from 'react-router';
import axios from 'axios';
import { BASE_URL } from '../../App';
import '../../styles/video.css'; 
import { Col, Container, Row } from 'react-bootstrap';
import swal from 'sweetalert';
// import ScreenTest from 'screentest';

const VideoPlayer = () => {


  const {id} = useParams()
   const [video ,setVideo] = useState("");
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        'Authorization': `Bearer ${token}`,
      }
    };
    axios.get(`${BASE_URL}/api/user/enrollVideo/${id}` , config)
      .then(response => {
        setVideo(response.data.data);
      })
      .catch(error => {
      });
  }, [id])

  const videoId = video?.iframe;
  const videoSource = videoId ? `https://www.youtube.com/embed/${videoId}` : '';
  // const videoSource = `https://www.youtube.com/embed/JkuPZBiSGjw`;
  // console.log(videoSource);




  const [auth , setAuth] = useState("")

  const getToken = async () => {
         try {
           const token = localStorage.getItem('token');
             const response = await axios.get(`${BASE_URL}/api/Auth` , {
               headers: {
                 'Content-Type': 'application/json',
                 "ngrok-skip-browser-warning": "any",
                 Authorization: `Bearer ${token}`,
               },
             }
             );
             setAuth(response.data)

         } 
         catch (error) {
         }
       }
     useEffect(() => {
      getToken()
     }, []);
    //  console.log(auth);



    


    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'F12' ||  event.altKey) {
          event.preventDefault();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);



  useEffect(() => { 
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
}, [])


useEffect(() => {
  window.scrollTo(0, 0);
}, []);




  const playerRef = useRef(null);

  useEffect(() => {
    if (video) {
      const player = new Plyr("#player", {
       

        controls: [
          "play-large",
          'rewind',
          "play",
          'fast-forward',
          "progress",
          "current-time",
          "mute",
          "volume",
          "captions",
          "settings",
          "pip",
          "airplay",
          "fullscreen",
        ],
        seekTime: 10,

   
        settings: ['captions', 'quality', 'speed', 'loop'],       
        clickToPlay: true,
        youtube: {
           noCookie: true,
           iv_load_policy: 3,
           showinfo: 0,
           modestBranding: 1,
           rel: 0,
           quality: "highres",
        },
        quality: {
          default: 720,
          options: ['1080p60', '1080p', '720p60', '720p', '480p', '360p', '240p']
        },
        loop: {
           active: false,
        },
        centered: true,

      });
      

      // Disable right-click menu on the video player
      player.on('contextmenu', function (e) {
        e.preventDefault();
      });

      // Disable sharing button on the video player
      const sharingButton = player.elements.share;
      if (sharingButton) {
        sharingButton.remove();
      }
      
      playerRef.current = player;
      
    }

  }, [video]);

  // const handleSeekForward = () => {
  //   const currentTime = playerRef.current.currentTime;
  //   playerRef.current.currentTime = currentTime + 10;
  // };

  // const handleSeekBackward = () => {
  //   const currentTime = playerRef.current.currentTime;
  //   playerRef.current.currentTime = currentTime - 10;
  // };











  return (
    <>
    <div className="video" >
    <div className="button-courses"> 
<button class="button-50" role="button">{video.title}</button>

                          </div>
      <Container>
        <Row>
          <Col lg={9} className="ms-auto me-auto">
            {/* <h2>{auth.fname + auth.lname}</h2> */}
          <div className="plyr__video-embed" id="player" >
        <iframe
          src={videoSource}
          allowFullScreen
          allowTransparency
          allow="autoplay"
          playsinline
        ></iframe>
      </div>
      {/* <div className="seek">
        <button onClick={handleSeekBackward}>-10s</button>
        <button onClick={handleSeekForward}>+10s</button>
      </div> */}
      
      {/* <h2>{auth.id}</h2> */}

          </Col>
        </Row>
      </Container>
    
    </div>
    </>
    
  );
};

export default VideoPlayer;

