

import { Link } from "@material-ui/core";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams  } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/quizstudent.css'
import '../../styles/pdf.css'
import {BASE_URL, Image_URL} from "../../App"



const StartPdf = () => {
    const navigate = useNavigate()
    const {id} = useParams()

    const [file ,setFile] = useState("")
    useEffect(() => {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
          'Authorization': `Bearer ${token}`,
        }
      };
      axios.get(`${BASE_URL}/api/materials/${id}` , config)
        .then(response => {
          setFile(response.data.data[0]?.attributes)
        })
        .catch(error => {
          // console.error(error);
        });
    }, [id])
    
                const start = () => {
                  window.open(`${Image_URL}/storage/${file.cdn}`)
                   }
    
                   function formatDate(dateString) {
                    try {
                      const date = new Date(dateString);
                      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab'};
                      const formatter = new Intl.DateTimeFormat('ar-EG', options);
                      const formattedDate = formatter.format(date);
                      return formattedDate;
                    } catch (e) {
                      // console.error('Error formatting date', e);
                      return '';
                    }
                  }

                  useEffect(() => {
                    window.scrollTo(0, 0);
                  }, []);


  return (
    <div class="quiz-main">
      <Container>
        <Row>
          <Col lg={12}>
            {/* <h2 className="main-title mt-5">{file.title}</h2> */}
            <div className="quiz-app ">
                <div className="quiz-data">
                   <h1 className="titlefile mb-5">{file.title}</h1>
                   <p class="date-file">{formatDate(file.created_at)}</p>
                 <button className="start mt-5" onClick={start}>فتح الملف الأن</button>
                </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default StartPdf;
