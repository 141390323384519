import { Link } from "@material-ui/core";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams  } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/quizstudent.css'



const StartHomework = () => {
  
    const navigate = useNavigate()
  const {id} = useParams()

  const start = () => {
  //   swal({
  //     title: "قبل متبدأ الواجب اعمل ريفرش للصفحة",
  //     icon: "warning",
  //     button: "حسنا!",
  //   }).then((willBuy) => {
  //     if (willBuy) {
      
  //     }
  // })
  navigate(`/homework/${id}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div class="quiz-main pt-4">
                      <div className="register-button mb-4 pt-4">
<button class="register-btn" role="button"> ركز شوية </button>
    </div> 
      <Container>
        <Row>
          <Col lg={12}>
            <div className="quiz-app ">
                <div className="quiz-data">
                <button className="start" onClick={start}>أبدأ الواجب الأن</button>      
                </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default StartHomework;
