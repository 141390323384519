
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import '../styles/createcode.css'
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv";
import '../styles/chargewallet.css'
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL } from "../App";



function ChargeWallet () {
    const navigate = useNavigate();

    const [filteredData, setFilteredData] = useState([]);
    const [codeTable , setCodeTable] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0);
    const [code, setCode] = useState("");
    const [errors , setErrors] = useState("")
    const [isLoading , setIsLoading] = useState(true);


    const token = localStorage.getItem('token');
    const axiosInstance = axios.create({
      baseURL: `${BASE_URL}`,
      headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
          Authorization: `Bearer ${token}`,
      },
  });


        // الريكويست الخاص بعرض الأكواد المشحونة
          const getcodes = async () => {
              try {
                const response = await axiosInstance.get('/api/my-codes');
                  setWalletBalance(response.data.message);
                  setCodeTable(response.data.data)
                  setIsLoading(false)
                  const data = response.data.data;
                          return data;
              } catch (error) {
                setIsLoading(false)

              }
          }
          useEffect (() =>{
            getcodes();
          }, [])


          // الريكويست الخاص ب شحن الكود وسعة المحفظة

  const handleCharge = async (event) => {
    event.preventDefault();
    try {
     const response =  await axiosInstance.post("/api/codes/charge" , {
          code
             
        });
        setCode("")
        swal({
            title: "تم الشحن بنجاح!",
            icon: "success",
            button: "حسناّ!",
          });
          const chargedCode = response.data.data;
          setWalletBalance(prevBalance => prevBalance + chargedCode.value);
          setCodeTable(prevTable => [...prevTable, chargedCode]);

    } catch(e) {
        if(e.response.status === 401) {
          swal({
            title: "تم شحن هذا الكود من قبل!",
            icon: "warning",
            button: "حسناّ!",
          });
            setErrors(e.response.data.errors);
        }
       else if(e.response.status === 404) {
          swal({
            title: "  هذا الكود  غير صحيح!",
            icon: "warning",
            button: "حسناّ!",
          });
            setErrors(e.response.data.errors);
        }
      
    }
}


useEffect(() => {
  window.scrollTo(0, 0);
}, []);




function formatDate(dateString) {
  try {
    const date = new Date(dateString);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'UTC',
      numberingSystem: 'arab'
    };
    const formatter = new Intl.DateTimeFormat('ar-EG', options);
    const formattedDate = formatter.format(date);
    return formattedDate;
  } catch (e) {
    return '';
  }
}
            
      const columns = [
           {
              name: "ID-codes ",
              selector: (row) => row.id,
              sortable : true,
            },
          {
              name: "Code",
              selector: (row) => row.code,
              sortable : true,
            },
     
          {
              name: "Price",
              selector: (row) => row.value,
              sortable : true,
            }, 
          {
              name: "Charged At",
              selector: (row) =>  row.updated_at,
          
              sortable : true,
            }, 
      ];


  
    return (
        <>
           {
          isLoading
          ?
           <div className="main-loading">
           <div className="loading-course"> 
         <h2>  .... جاري تحميل  بيانات رصيدك</h2>
         </div>
         </div>
         :
         <div className="charge-wallet ">
         <div className="main-title">شحن الأكواد</div>
         <Container>
             <Row>
             <Col lg={12}>
                        <div className="quiz-content">
                          <div className="codes">
                          <Form className="align-items-center" onSubmit={handleCharge}>
         <Form.Group className="mb-3" controlId="formBasicEmail">
           <Form.Label> كود الشحن </Form.Label>
           <Form.Control type="text" placeholder="ادخل  كود الشحن" name="code" onChange={(e)=>{setCode(e.target.value)}}/>
         </Form.Group>
         <Button variant="outline-primary" type="submit" className="ms-lg-4 ms-0">اشحن</Button>{' '}
       </Form>
       <div className="wallet">
         <span>رصيدك بالمحفظة  </span>
                                               
                     <span> {walletBalance} جنيها</span>
               </div>
            
               <DataTable
                  columns={columns}
                  data={filteredData.length > 0 ? filteredData : codeTable} 
                   pagination 
                   fixedHeader
                   // selectableRows
                   fixedHeaderScrollHeight="400px"
                   highlightOnHover
                  
                   subHeader
                   // subHeaderComponent={
                   //   <input type="text" className="form-control mt-4 mb-4" placeholder="Search of Codes" onChange={handleSearch} />
   
                   // }
                   />
                   
                           </div> 
                 </div>
                       </Col>
               </Row>
           </Container>
   
       
         </div>
           }
    
      
        </>
        
    )
}

export default ChargeWallet;