
import axios from "axios";
import { useEffect, useId, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import '../../styles/videodata.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { CSVLink } from "react-csv";
import swal from "sweetalert";
import { BASE_URL, tableCustomStyles } from "../../App";


function StudentQuiz() {
    
    const navigate = useNavigate();
    const {id} = useParams()
    // console.log(id);
  const [student , setStudent] = useState([]);
  
    const token = localStorage.getItem('token' );
    const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
  
});



const getQuizStudent = async () => {
    try {
      const token = localStorage.getItem('token' );
      const response = await axiosInstance.get(`/api/quiz-student/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
        });
        // console.log(response.data.data.map(item => item.id))
     
        setStudent(response.data.data)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
    getQuizStudent();
}, [])



const getRefreshStudent = async () => {
  try {
    const token = localStorage.getItem('token' );
    const response = await axiosInstance.get(`/api/refresh-quiz/${id}` , {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
        },
      });
        // console.log(response);
      const data = response.data.data;
      return data;
  } catch (error) {
      console.log(error);
  }
}

const handleRefreshClick = () => {
  swal({
      title: " تحذير! هذا الزر يستخدم عند وجود مشاكل في درجات الطلبة",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  })
  .then((willRefresh) => {
      if (willRefresh) {
          getRefreshStudent();
      }
  });
}

useEffect (() =>{
  getRefreshStudent();
}, [])





let totalStudent = student.length



const [rows, setRows] = useState([]);

const ClearRow = async (id) => {
  const token = localStorage.getItem('token');
  const { student_id, quiz_id } = student.find(row => row.id === id);
  try {
    const response = await axios.get(`${BASE_URL}/api/clear-student-quiz/${student_id}/${quiz_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
      },
    });
    swal({
      title: "تم اعادة الامتحان للطالب  بنجاح!",
      icon: "success",
      button: "حسناّ!",
    });
    const updateStudentQuiz = student.filter(row => row.id !== id);
    setStudent(updateStudentQuiz);
  } catch (error) {
    // console.error(error);
  }
};





const handleClearClick = async (id) => {
  swal({
    title: "هل انت متأكد من اعادة الامتحان لهذا الطالب؟",
    icon: "warning",
    buttons: {
      cancel: "لا",
      confirm: "نعم",
    },
  }).then((willDelete) => {
    if (willDelete) {
      ClearRow(id);
    }
  });
};



const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = student.filter((item) => {
    return (
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
  });
  setFilteredData(searchResults);
};


    const columns = [
 
      {
        name: "ID ",
        selector: (row) => row.id,
        sortable : true,
      },
      {
        name: "Student_Id ",
        selector: (row) => row.student_id,
        sortable : true,
      },
      {
        name: "Name ",
        selector: (row) => row.name,
        sortable : true,
      grow:2,
      },
      {
        name: "Phone ",
        selector: (row) => row.phone,
        sortable : true,
      grow:2,
      },
      {
        name: "Phone ",
        selector: (row) => row.pphone,
        sortable : true,
      grow:2,
      },
  
      
   
      {
        name: "Degree ",
        selector: (row) => row.degree,
        sortable : true,

      },
   
      {
        name: "Government ",
        selector: (row) => row.government,
      },
      {
        name: "ModelAnswer",  
        cell: row => <Link to={`/admin/modelanswerquiz/${row.id}`}>
         <button style={{background:"#" , border: "none"}} className="btn btn-secondary pe-lg-2 ps-lg-2 pe-2 ps-2 pt-2 pb-2" >ModelAnswer</button> ,
        </Link>,
        grow:3,
        
      },
      {
        name: "Clear",
        cell: row => <button style={{ background: "#", border: "none" }} className="btn btn-success pe-lg-2 ps-lg-2 pe-2 ps-2 pt-2 pb-2" onClick={() => handleClearClick(row.id)}>Clear</button>,
        
      },

     
    ];
    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  الطلاب الممتحين </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                             customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : student} 
                pagination 
                fixedHeader
                highlightOnHover
                actions={
                    <CSVLink
                    data = {student}
                    filename = "الطلاب الممتحنين"
                    className = "btn btn-success mb-3 mt-4 me-2" > Export File Excel </CSVLink> 
               }
               subHeader
                subHeaderComponent={
                  <>
                  <div className="total-search">
                  <button  className="refresh" onClick={handleRefreshClick}>تحديث الطلبة</button>

                  <h3 className="total-student mb-4"> <span>{totalStudent}</span> : اجمالي عدد الطلاب  اللي حلو الكويز  </h3>
                      <input type="text" className="form-control mt-4 mb-4 w-100" placeholder="Search of Student" onChange={handleSearch} /> 
                      </div>     
                      

                  </>
                  
                                 }
                />
              </div>
                   </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default StudentQuiz;

