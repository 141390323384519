
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/addvideo.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { BASE_URL, Image_URL } from "../../App";



function EditWeek() {
        
  const { id } = useParams();
    const navigate = useNavigate();
  
    const [week , setWeek] = useState([]);
  const token = localStorage.getItem('token' );

    const axiosInstance = axios.create({
      baseURL: `${BASE_URL}`,
  });

  const getWeek = async () => {
    try {
      const token = localStorage.getItem('token' );
      const response = await axiosInstance.get(`/api/user/weeks/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data.attributes);
        setEdit(response.data.data.attributes)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error.response);
    }
}

useEffect (() =>{
  getWeek();
}, [])


const [edit, setEdit] = useState({
    name: "",
    description: "",
    price: "",
    img: "",
    level: "",
    created_at: "",
    updated_at: "",
});
const [errors, setErrors] = useState({});

  const handleEdit = async (event) => {
    event.preventDefault();
    // const token = sessionStorage.getItem('token' );
    try {
      const formData = new FormData();
      formData.append("name", edit.name);
      formData.append("description", edit.description);
      formData.append("price", edit.price);
      formData.append("level", edit.level);
      formData.append("img", edit.img);


      await axiosInstance.post(
        `/api/update-week/${id}`,
          formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            "ngrok-skip-browser-warning": "any",
          },
        }
      );
      setEdit({
        name: "",
    description: "",
    price: "",
    img: "",
    level: "",
    created_at: "",
    updated_at: "",
      });
      swal({
        title: "تم تعديل الحصة بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
      navigate("/admin/weekdata");
     
    } catch (e) {
      if (e.response.status === 200) {
        
        // console.log(e);
        setErrors(e.response.data.errors);
      }
      else if (e.response.status === 422) {
        console.log(e.response);
      }
    }
  };
  
 
  


  console.log(edit);

  
      
const [imagePreview, setImagePreview] = useState(null);

const handleImageChange = (event) => {
  const file = event.target.files[0];
  setEdit((prev) => ({ ...prev, img: file }));
  setImagePreview(URL.createObjectURL(file));
}

    
    return (
        <>
      <div className="d-flex">
    
       <div className="add-video">
      
      <div className="main-title">  تعديل الحصة رقم {id}  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                    <div className="video">

                        <div className="create-box">
                        <h2 className="title mt-4 mb-4">تعديل الحصة </h2>
                        <Form onSubmit={handleEdit}>
         

         <img width={300} height={300} className="edit_image" src={imagePreview || `${Image_URL}/storage/${edit.img}`} alt=""/>
     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 me-lg-3 videoInput" controlId="formBasicText">
        <Form.Label> اسم الحصة</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الحصة" name="name" value={edit.name}  onChange={(e) => setEdit((prev) => ({ ...prev, name: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText ">
        <Form.Label> بوستر الحصة </Form.Label>
        <Form.Control type="file" name="img"  onChange={handleImageChange}/>
      </Form.Group>
    
     </div>

     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 videoInput me-lg-3" controlId="formBasicText ">
        <Form.Label>  وصف الحصة </Form.Label>
        <Form.Control type="text" placeholder="ادخل وصف الحصة" name="description" value={edit.description}  onChange={(e) => setEdit((prev) => ({ ...prev, description: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3 videoInput" controlId="formBasicText">
        <Form.Label> سعر الحصة</Form.Label>
        <Form.Control type="number" placeholder="ادخل سعر الحصة" name="price" value={edit.price}  onChange={(e) => setEdit((prev) => ({ ...prev, price: e.target.value }))}/>
      </Form.Group>
   
     </div>
    

        
  

     <div className="chooices d-lg-flex align-items-center mt-lg-4">
   

    <Form.Select aria-label="Default select example" className="mt-3" name="level" value={edit.level}  onChange={(e) => setEdit((prev) => ({ ...prev, level: e.target.value }))}>
      <option>اخنر السنة الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
     </div>

    
 




      
        

      <Button variant="primary" type="submit" className="create-button">
        تعديل
      </Button>
    </Form>
    </div>
                    </div>
              </div>
               </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default EditWeek;