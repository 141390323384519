import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SidebarAdmin from "../SidebarAdmin";
import '../../styles/table.css'
import { Link } from "react-router-dom";
import { BASE_URL, tableCustomStyles } from "../../App";




function Admins () {

  
  
  
   
    const [admin , setAdmin] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const axiosInstance = axios.create({
      baseURL: `${BASE_URL}`,
      headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
      },
  });
  


  const getAdmin = async () => {
      try {
        const token = localStorage.getItem('token');
          const response = await axiosInstance.get('/api/admins' , {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
     
          setAdmin(response.data.data)
          // const data = response.data.data;
          //          return data;
      } catch (error) {
          // console.log(error);
      }
  }
  useEffect (() =>{
    getAdmin();
  }, [])


  const deleteRow = async(id, token) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/delete-admin/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        // console.error(error);
    }
};


  // const [rows, setRows] = useState([]);

  const handleDeleteClick = async (id) => {
    const token = localStorage.getItem('token');
    try {
        await deleteRow(id, token);
        const updatedAdmin = admin.filter(row => row.id !== id);
        setAdmin(updatedAdmin);
    } catch (error) {
        // console.error(error);
    }
};
  
  

  
    const columns = [
        {
            name: "ID ",
            selector: 'id',
            sortable : true,
      
          },
      {
        name: "Name",
        selector: 'attributes.name',
        sortable : true,
      },
  
 
      {
        name: "role",
        selector: 'attributes.role',
      },
      
      {
        name: "Delete",
        cell: row => <button style = {
            { background: "#", border: "none" }
        }
        className = "btn btn-danger"
        onClick = {
            () => alert('هل انت متأكد من حذف هذا المساعد') | handleDeleteClick(row.id)
        } > Delete </button>,
    },
    {
      name: "Edit",
      cell: row => <Link to={`/admin/editadmin/${row.id}`}>
      <button className="btn btn-warning ps-lg-3 pe-lg-3">Edit</button>
      </Link>
    }
    
      
    ];
    return (
        <>
               <div className="d-flex">
      
       <div className="allstudent-admin ">
      
      <div className="main-title">  المساعدين </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
            
              <DataTable
                             customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : admin} 
                pagination 
                fixedHeader
     
                highlightOnHover
                actions={
                  <Link to="/admin/addadmin">
                                       <button className="btn btn-primary mt-4 mb-4 fs-5 fw-bold pt-2 pb-2 pe-4 ps-4 me-2">اضافة مساعد</button>   
                  </Link>
               }      
               subHeader
       
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdmin />
       </div>
       </div>
        </>
    )
}

export default Admins;