
import axios from "axios";
import { useEffect, useState } from "react";
import {Accordion ,  Col, Container, Row } from "react-bootstrap";
import Collapsible from "react-collapsible";
import { Link, Navigate, useParams } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
import '../../styles/pay.css'
import  '../../styles/sidebaruser.css'
import ScrollTopButton from "../ScrollTop";

function PayNotLogin() {
    const {id} = useParams()

  const [quizzes , setQuizzes] = useState([]);
  const [videos , setVideos] = useState([]);
  const [homeworks , setHomework] = useState([]);
  const [materials , setMaterials] = useState([]);
  const [video_Duration , setVideo_Duration] = useState([]);
  const [score , setScore] = useState(0)
  const [date , setDate] = useState(0)
  const [details , setDetails] = useState([]);
  const [course , setCourse] = useState([])
  // const navigate = useNavigate()

      const [isLoading, setIsLoading] = useState(false);

useEffect(() => {
      setIsLoading(true);
      window.scrollTo(0, 0);

          const token = localStorage.getItem('token');
          const config = {
            headers: {
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "any",
              'Authorization': `Bearer ${token}`,
            }
          };       
          axios.get(`${BASE_URL}/api/week-unAuth-content/${id}` , config)
            .then(response => {
              setQuizzes(response.data.quizzes);
              setVideos(response.data.videos);
              setHomework(response.data.homeworks);
              setMaterials(response.data.materials);
              setDetails(response.data.data);
              setScore(response.data.quizzes[0].quizResult[0].score);
              setDate(response.data.quizzes[0].quizResult[0].created_at);
            })
           .catch(error => {
           
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, []);


        function convertMinutesToHoursOrMinutes(minutes) {
          if (minutes < 60) {
            return `${minutes} دقيقة`;
          } else {
            const hours = Math.floor(minutes / 60);
            return `${hours} ساعات`;
          }
        }

        function formatDate(dateString) {
          try {
            const date = new Date(dateString);
            const options = {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              timeZone: 'Africa/Cairo',
              numberingSystem: 'arab'
            };
            const formatter = new Intl.DateTimeFormat('ar-EG', options);
            const formattedDate = formatter.format(date);
            return formattedDate;
          } catch (e) {
            return '';
          }
        }
            const token = localStorage.getItem('token');
             const axiosInstance = axios.create({
              baseURL: `${BASE_URL}`,
              headers: {
                   'Content-Type': 'application/json',
                   "ngrok-skip-browser-warning": "any",
                     Authorization: `Bearer ${token}`,
                  },
               });
        
        const [isOpen, setIsOpen] = useState(false);
        const [isOverlayOpen, setIsOverlayOpen] = useState(false);
        
        const toggle = () => {
          setIsOpen(!isOpen);
          setIsOverlayOpen(!isOverlayOpen);
        }
        
        const close = () => {
          setIsOpen(false);
          setIsOverlayOpen(false);
        }

        const handleClick = () => {
          if (isOpen) {
            close();
          }
        };

        const handleBuyCourseNotLogin = () => {
          swal({
            title: "سجل الأول علشان تشترك في الكورس",
            icon: "warning",
            buttons: " حسناً",
          })
             Navigate('/loginuser');
        };

    return (

      <>
      {
        isLoading 
        ?
        <div className="main-loading">
              <div className="loading-course"> 
            <h2>  .... جاري تحميل بيانات الكورس</h2>
            </div>; 
            </div>
            :
 <>  
            <div className="overlay" style={{ display: isOverlayOpen ? "block" : "none" , pointerEvents: isOpen ? "auto" : "none"}}  onClick={handleClick}></div>
    <div className="pay-smooth" style={{ width: isOpen ? "" : "0px" }}>
          <div className="close-title">
          <button className="close-btn" onClick={close}>X</button>
          <h2>محتويات <span>{details.name}</span> </h2>
          </div>
              <Row>
                <Col lg={12}>
                      <div className="content-main">
                        {/* الجزء الخاص  ببايانات الامتحان */}
                        {
                          quizzes.map((quiz) => {
                            return (
                              <div className="content-card" key={quiz.id}>
                                <div className="title">
                                <h2>{quiz.title}</h2>
                                <i class="fa-solid fa-file-circle-question"></i>

                                </div>
                                  <div className="content-lesson">
                                  <p>اسم الامتحان : <span className="quiz-span">{quiz.title}</span></p>
                              <p>مدة الامتحان : <span className="quiz-span">  {quiz.minutes} </span>دقيقة </p>
                              <p> تاريخ البداية : <span className="quiz-span"> {`${formatDate(quiz.start)}`} </span> </p>
                                  <p> تاريخ النهاية : <span className="quiz-span"> {`${formatDate(quiz.end)}`} </span> </p>
                                  {/* <p> عدد الأسئلة : <span className="quiz-span"> {quiz.noQuestions} </span> {quiz.noQuestions.length > 10 ? "سؤال" : "أسئلة"} </p> */}
                              <p>درجة الامتحان : <span className="quiz-span">  {quiz.degree} </span>درجة</p>
                              <p>الدرجة الصغري للإمتحان : <span className="quiz-span">  {quiz.mindegree} </span>درجة</p>
                              {
                                quiz.prize === null
                                ?
                                ""
                                :
                                <p className="d-flex align-items-center  prize-p">  : جائزة الأوائل  <span className="quiz-span"> {quiz.prize} </span></p>

                              }
                                <Link to="/loginuser" onClick={handleBuyCourseNotLogin}  className="quiz-btn"> سجل الأن</Link>
                                  </div>
                              </div>
                            )
                          })
                        }
                        {/* الجزء الخاص  ببايانات الفيديو */}

                        {
                          videos.map((video) => {
                            return (
                              <div className="content-card" key={video.id}>
                              <div className="title">
                              <h2>{video.title}</h2>
                              <i class="fa-solid fa-video"></i>

                              </div>
                                <div className="content-lesson">
                                <p>اسم الفيديو : <span className="video-span">{video.title}</span></p>
                            <p>مدة الفيديو : <span className="video-span">  {video.video_dauration} </span>دقيقة </p>
                            <p> الحد الأقصي للمشاهدات : <span className="video-span">  {video.noviews} </span></p>
                                

                            <Link to={`/loginuser`}  onClick={handleBuyCourseNotLogin} className="video-btn"> سجل الأن</Link>



                
                                </div>
                            </div>
                            )
                          })
                        }
                        {/* الجزء الخاص  ببايانات الواجب */}

                          {
                          homeworks.map((homework) => {
                            return (
                              <div className="content-card" key={homework.id}>
                                <div className="title">
                                <h2>{homework.title}</h2>
                                <i class="fa-solid fa-file-circle-question"></i>

                                </div>
                                  <div className="content-lesson">
                                  <p>اسم الواجب : <span className="quiz-span">{homework.title}</span></p>
                              <p>مدة الواجب : <span className="quiz-span">  {homework.minutes} </span>دقيقة </p>
                              <p> تاريخ البداية : <span className="quiz-span"> {`${formatDate(homework.start)}`} </span> </p>
                                  <p> تاريخ النهاية : <span className="quiz-span"> {`${formatDate(homework.end)}`} </span> </p>
                              <p>درجة الواجب : <span className="quiz-span">  {homework.degree} </span>درجة</p>
                              <p>الدرجة الصغري للواجب : <span className="quiz-span">  {homework.mindegree} </span>درجة</p>
                             

                              <Link to={`/loginuser`} onClick={handleBuyCourseNotLogin} className="quiz-btn"> سجل الأن</Link>    

                             
                                  </div>
                              </div>
                            )
                          })
                        }
                        {/* الجزء الخاص  ببايانات الملف */}

                        {
                          materials.map((material) => {
                            return (
                              <div className="content-card" key={material.id}>
                              <div className="title">
                              <h2>{material.title}</h2>
                              <i class="fa-solid fa-file-pdf"></i>

                              </div>
                                <div className="content-lesson">
                                <p>اسم الملف : <span className="video-span">{material.title}</span></p>
                                


                                <Link to={`/loginuser`} onClick={handleBuyCourseNotLogin}  className="video-btn"> سجل الأن</Link>    


                
                                </div>
                            </div>
                            )
                          })
                        }
                      </div>
                  </Col>
              </Row>
    </div>
        <div className="pay pt-5 pb-5" >
        {/* <h2 className="main-title">شراء {}</h2> */}
       <Container>
            <Row className="row ">
            <Col lg={12} className="mb-5"> 
              <div className="main">
              <div className="info">
                <div className="image">
                <img src="/images/bg.3b55416e926d51a05f75.png" alt=""/>
                </div>
                 <div className="info-course d-flex">
                 <div className="content">
                 <h2 className="title">{details.name}  </h2>
             <p>تاريخ انشاء الكورس <span className="date"> {formatDate(details.created_at)}  </span></p>
                 <p> اخر تحديث للكورس <span className="date"> {formatDate(details.updated_at)}  </span></p>
                </div>
                <div className="about">
                 <h3> <span>{videos.length}</span>: عددالفديوهات </h3>
                 <h3> <span>{quizzes.length}</span>: عدد الامتحانات </h3>
                 <h3> <span>{homeworks.length}</span>: عدد الواجبات </h3>
                 <h3> <span>{materials.length}</span>: عدد الملفات </h3>
                </div>
                 </div>
            
</div>

              </div>
               </Col>
              <Row className="p-0 m-0">

               <Col lg={4}>
              <div className="card-pay">
                <div className="image-pay">
                <img src={`${Image_URL}/storage/${details.img}`} alt="" class="image-title"/>

                </div>
                <div className="price d-flex">
                  <span className="ms-2"> {details.price}.00 </span>
                  <span> جنيهاَ</span>
                </div>
                <div class="button-pay">

                <Link  to="/loginuser"   onClick={handleBuyCourseNotLogin}>
                <button class="button-57" role="button" style={{  backgroundColor: "" , color: "#000"}} ><span class="text" > ! سجل الأن  </span><span>  ! سجل الأن </span></button>
                </Link>

                    </div>
                    <div className="button-pay">
                      <button className="toggle-btn" onClick={toggle}>
                        اضغط هنا لرؤية محتويات الحصة
                      </button>
                    </div>
                <div className="info-pay">
                  <div className="d-flex">
                    <span>المحتوي</span>
                   <div class="watch-count d-flex">
                    <span>{convertMinutesToHoursOrMinutes(details.videoMinutes)}</span>
                   {/* <span className="me-2"> ساعات</span> */}
                   </div>
                  </div>
                  {/* <div className="d-flex mt-4">
                    <span>عدد الأسئلة</span>
                    <div class="watch-count d-flex">
                    <span> 5</span>
                   <span className="me-2"> اسئلة</span>
                   </div>
                  </div> */}
                </div>
              </div>
            </Col>

            <Col lg={8}>
              <div className="image-main-title">
              <img src={`${Image_URL}/storage/${details.img}`} alt="" class="image-title"/>
              </div>
            </Col>
              </Row>

            </Row>
        </Container>
        </div>
        < ScrollTopButton />

 
        </>
      }
      </>
       
    )
}
export default PayNotLogin;