import { Col, Container, Row, Table } from "react-bootstrap";
import "../../styles/profilequiz.css";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { height, width } from "@mui/system";
import axios from "axios";
import styled from "@emotion/styled";
import { colors } from "@mui/material";
import { Link } from "react-router-dom";
import SidebarProfile from "../SidebarProfile";
import { BASE_URL, tableCustomStyles } from "../../App";
import ScrollTopButton from "../ScrollTop";
// import '../styles/table.css'

function Profilequiz() {

  const[isOpen ,setIsOpen] = useState(false);
  const toggle = () => setIsOpen (!isOpen);

  const [quiz , setQuiz] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading , setIsLoading] = useState(true)

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});

const getQuizData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/api/my-quizzes' , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        setQuiz(response.data.data)
        setFilteredData(response.data.data);
        setIsLoading(false)
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
        setIsLoading(false)

    }
}

useEffect (() =>{
  getQuizData();
}, [])


  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable : true,

    },
    {
      name: "Week_ID",
      selector: (row) => row.week_id,
      sortable : true,
    
    },
    {
      name: "Name",
      selector: (row) => row.title,
      grow: 5,
    
    },
    {
      name: "Minutes",
      selector: (row) => row.minutes,
    //   style: {
    //     background: "#e6e6e6",
    //   }
    },
    {
      name: "Degree",
      selector: (row) => row.degree,
    //   style: {
    //     background: "#e6e6e6",
    //   }
    },
    {
      name: "Mindegree",
      selector: (row) => row.mindegree,
    //   style: {
    //     background: "#e6e6e6",
    //   }
    },
    {
      name: "Score",
      selector: (row) => row.pivot.score,
   
    },
    {
      name: "Start",
      selector: (row) => row.start,
      grow:3
    },
    {
      name: "End",
      selector: (row) => row.end,
      grow:3
    },
    {
      name: "Level",
      selector: (row) => row.level,
      
    },
    {
      name: "ModelAnswer",
      selector: (row) => <Link to={`/modelanswerquiz/${row.id}`}>
      <button style={{background:"#" , border: "none"}} className="btn btn-secondary pe-2 ps-2 pt-2 pb-2">ModelAnswer</button>,
      </Link>,
      grow: 4,
      
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     {
          isLoading
          ?
           <div className="main-loading">
           <div className="loading-course"> 
         <h2>  .... جاري تحميل نتائج الأمتحانات</h2>
         </div>; 
         </div>
         :
         <div className="profile-quiz">
          <div className="profile-ground">
                             <img src="/images/bg.3b55416e926d51a05f75.png" alt=""/>
                     </div>
         <Container>
             <Row>
                 <Col lg={12}>
                     
                     <div className="profile-user pt-5">
                     <div className="title-profile mt-5 mb-5">
                  <button class="button-prof" role="button"> نتائج الإمتحانات </button>
                       </div>                              
                             <Container>
                             <Row >
                                 <Col lg={12} className="mt-2">
                               <div className="main-info">

                                     <Container>
                                         <Row className="" >
                                       <Col lg={8}>
                                       <div className="quiz-content">
                   <DataTable
                                                customStyles={tableCustomStyles}

                   columns={columns}
                     data={filteredData} 
                     pagination 
                     fixedHeader
                     // selectableRows
                     // fixedHeaderScrollHeight="400px"
                     highlightOnHover
                     // actions={
                     //     <button className="btn btn-info">Export</button>
                     // }
                     subHeader
                     
                     />
           </div>
                                       </Col>
                                       <Col lg={4}>
                               <SidebarProfile />
                                 </Col>

                                         </Row>
                                     </Container>

                               </div>

                                 </Col>
                             </Row>
                             </Container>
                         </div>
                 
                 </Col>
             </Row>

         </Container>
     </div>
     }

         
        < ScrollTopButton />

    </>
  );
}

export default Profilequiz;


