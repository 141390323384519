

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../styles/createcode.css'
import SidebarAdminMain from "../SidebarAdminMain";
import '../../styles/addadmin.css'
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL } from "../../App";
// import { BASE_URL } from ".";



function Addadmin () {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [errors, setErrors] = useState("");
  const [role , setRole] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
});


  const handleAddAdmin = async (event) => {
    event.preventDefault();
    try {
        await axiosInstance.post("/api/user" , {
                fname , 
                lname ,
                email, 
               password , 
               password_confirmation , 
               role,
             
        });
        setFname("");
        setLname("");
        setEmail("");
        setPassword("");
        setPassword_confirmation("");
        setRole("");
        swal({
            title: "تم إضافة الأدمن بنجاح!",
            icon: "success",
            button: "حسناّ!",
          });
          navigate("/admin/admins")
         

    } catch(e) {
        if(e.response.status === 422) {
            setErrors(e.response.data.errors);
            // console.log(e);   
        }
    }
}

    return (
        <>
                  <div className="d-flex">
    
       <div className="add-admin ">     
      <div className="main-title">اضافة مساعد</div>
      <Container>
          <Row>  
          <Col lg={12}>
                     <div className="quiz-content">
                       <div className="codes">
                       <Form className="align-items-center" onSubmit={handleAddAdmin}>
           
              <div className="input-group">
              <Form.Group className="big-input mb-3 me-lg-5" controlId="formBasicEmail">
        <Form.Label> الاسم الأخير </Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الأخير" name="lname" onChange={(e) =>setLname(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.lname && (
                                <div className="error">{errors.lname[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
              <Form.Group className="big-input mb-3 me-lg-5" controlId="formBasicEmail">
        <Form.Label> الاسم الأول </Form.Label>
        <Form.Control type="text" placeholder="ادخل الأسم الأول"  name="fname" onChange={(e) =>setFname(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.fname && (
                                <div  className="error">{errors.fname[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
              </div>
              <div className="input-group">
              <Form.Group className="big-input mb-3" controlId="formBasicEmail">
                    <Form.Label>برجاء اختيار  دور المساعد</Form.Label>
                    <Form.Select aria-label="Default select example" name="role" onChange={(e) =>setRole(e.target.value)}>
                    <option >اختر دور المساعد</option>
                          <option  value="1"> مساعد</option>
                           <option value="2">مساعد أول</option>
    </Form.Select>
    <Form.Text className="text-muted">
        {errors.role && (
                                <div  className="error">{errors.role[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
              <Form.Group className="big-input mb-3 me-lg-5" controlId="formBasicEmail">
        <Form.Label> ادخل رقم الفون</Form.Label>
        <Form.Control type="number" placeholder="ادخل  رقم الفون" name="email" onChange={(e) =>setEmail(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.email && (
                                <div  className="error">{errors.email[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
              </div>
              <div className="input-group">
              <Form.Group className="big-input mb-3 me-lg-5" controlId="formBasicEmail">
        <Form.Label> اكد كلمة السر  </Form.Label>
        <Form.Control type="password" placeholder=" تأكيد كلمة السر" name="password_confirmation" onChange={(e) =>setPassword_confirmation(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.password_confirmation && (
                                <div  className="error">{errors.password_confirmation[0]}</div>
                            )}
        </Form.Text>
      </Form.Group>
        <Form.Group className="big-input mb-3 me-lg-5" controlId="formBasicEmail">
        <Form.Label> ادخل كلة سر للمساعد</Form.Label>
        <Form.Control type="password" placeholder="ادخل  كلمة السر" name="password" onChange={(e) =>setPassword(e.target.value)}/>
        <Form.Text className="text-muted">
        {errors.password && (
                                <div  className="error">{errors.password[0]}</div>
                            )}
        </Form.Text>     
      </Form.Group>
    
              </div>
      <Button variant="primary" type="submit" className=" ms-0">
        اضـف
      </Button>
    </Form>
                        </div> 
              </div>
                   </Col>
          </Row>
      </Container>

      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
        
    )
}

export default Addadmin;