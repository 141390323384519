

import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { BASE_URL } from '../App';
import Box from '@mui/material/Box';





function AppNavbar ({loggedIn ,setLoggedIn , clearToken}) {

  const [scrollTop , setScrollTop] = useState(0);

  
  const onScroll = () =>{
    const windowScroll = document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (windowScroll / height) * 100;
    setScrollTop(scrolled);
  }

    useEffect(() => {
      window.addEventListener("scroll" , onScroll)
      return () => window.removeEventListener("scroll" , onScroll)
    }, [])



        
    
  const [name, setName] = useState([]);
  const [ role , setRole] = useState("")

    // الريكويست الخاص ب تحديد اذا كان أدمن ام مستخدم عادي
    useEffect(() => {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
          'Authorization': `Bearer ${token}`,
        }
      };
      axios.get(`${BASE_URL}/api/Auth`, config)
        .then(response => {
          setRole(response.data.role)
          setName(response.data);
        })
        .catch(error => {

        });
    }, [loggedIn]);

    const roled = localStorage.getItem("sha1");



    const [active, setActive] = useState(false);
    const [navHeight, setNavHeight] = useState('0px');

    function handleToggle() {
      setActive(!active);
    }

    

    return (   
      <>
    {/* <Navbar className='navbar pt-3 pb-3' sticky="top" bg="light" expand="lg">
      <Container>
        {
          role > 0 
          ?
          <Link to="/" className='nav-link'>
          <h1>Kono<span>z</span></h1>
        </Link>
        :
        <Link to="/" className='nav-link'>
        <h1>Kono<span>z</span></h1>
      </Link>
        }
      
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto navbar-link">

            {
             loggedIn ?
              <>
                  {
                    roled > 0 
                    ?
                    ""
                    :
                    <Link to="/chargewallet">
                    <img src="/images/wallet.png" alt="" className="wallet"/>
                    </Link>
                  }
                              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src="/images/user.png" alt="" className="user_img"/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="me-5">
                <Dropdown.Item > {name.fname +' '+ name.lname} اهلاَ </Dropdown.Item>
                  {
                    roled > 0
                    ?
                    <Dropdown.Item >   
                    <Link to='/admin' className="toprofile">
                      صفحة الأدمن
                     </Link> 
                   </Dropdown.Item>
                    :
                    <Dropdown.Item >   
                  <Link to='/profile' className="toprofile">
                    حسابي
                   </Link> 
                 </Dropdown.Item>
                  }
                <Dropdown.Item onClick={clearToken}>تسجيل الخروج <img src="/images/exit.png" alt="" className="exit_prof"/></Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                        </> 
                        
              :
              
              <>
              <Link to="/loginuser" className='navs-link me-4'>
                <i className='fa-duotone fa-arrow-right-to-bracket'></i>
              <span className='text-center'>تسجيل الدخول</span>
              <div className='liquid'></div>
             </Link>
             <Link to="/registeruser" className='navs-link'>
             <span className='text-center'>!انشئ حسابك</span>
              <div className='liquid'></div>
             </Link> 
              </>
            }
           
          </Nav>
        </Navbar.Collapse>
      </Container>
        <div className='scroller'>
      <div className='scrollerStyle' style={{width : `${scrollTop}%`}}></div>
    </div>
    </Navbar> */}
  
 
  <nav className="navbaR" >
    
  <div className="container">
    <Link to="/" className='nav-link'>
      <h1>Kono<span>z</span></h1>
    </Link>
    <button className={`toggle-button ${active ? 'active' : ''}`} onClick={handleToggle}>
      <span className="bar"></span>
      <span className="bar"></span>
      <span className="bar"></span>
    </button>
    <ul className={`navbar-links ${active ? 'active' : ''} ${active ? 'transition-active' : ''}`}>
      {/* <li><a href="#">Home</a></li>
      <li><a href="#">About</a></li>
      <li><a href="#">Contact</a></li> */}
      {loggedIn ?
        <>
          {
            roled > 0
              ?
              ""
              :
              <Link to="/chargewallet">
                <img src="/images/wallet.png" alt="" className="wallet" />
              </Link>
          }
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src="/images/user.png" alt="" className="user_img ms-lg-3" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="me-5">
              <Dropdown.Item > {name.fname + ' ' + name.lname} اهلاَ </Dropdown.Item>
              {
                roled > 0
                  ?
                  <Dropdown.Item >
                    <Link to='/admin' className="toprofile">
                      صفحة الأدمن
                    </Link>
                  </Dropdown.Item>
                  :
                      <>
                      <Dropdown.Item >
                    <Link to='/profile' className="toprofile">
                      حسابي
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item >
                    <Link to='/editinfo' className="toprofile">
                      تعديل حسابي
                    </Link>
                  </Dropdown.Item>
                      </>
              }
              <Dropdown.Item onClick={clearToken}>تسجيل الخروج <img src="/images/exit.png" alt="" className="exit_prof" /></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </> :
        <>
           <Link to="/loginuser" className='navs-link mt-lg-0 mt-4'>
                <i className='fa-duotone fa-arrow-right-to-bracket'></i>
              <span className='text-center'>تسجيل الدخول</span>
              <div className='liquid'></div>
             </Link>
             <Link to="/registeruser" className='navs-link ms-lg-3'>
             <span className='text-center'>!انشئ حسابك</span>
              <div className='liquid'></div>
             </Link> 
        </>
      }
    </ul>
  </div>

  <div className='scroller'>
    <div className='scrollerStyle' style={{ width: `${scrollTop}%` }}></div>
  </div>

  <div className='scroller-linear'>
  <Box sx={{ width: '100%' }}>
  <LinearProgress 
    sx={{
      bgcolor: '#dbdbdb',
      height: '1.5px',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#f43f5e',
        boxShadow: 'inset 0 0 10px rgb(0 0 0 / 15%)'
      }
    }} 
  />  
</Box>
    </div>
</nav>
      
      </>
    
    )
}



export default AppNavbar;