
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../styles/addquiz.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../App";






function EditHomework () {
  

  const token = localStorage.getItem('token');



  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
});

// const {id} = useParams();
const getHomeWork = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/homeworks/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
            "ngrok-skip-browser-warning": "any",
          },
        });
        // console.log(response.data.data.map(item => item.attributes))
        // console.log(response.data.data.attributes);
        setEdit(response.data.data.attributes)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
  getHomeWork();
}, [])



// const handleInputChange = (event) => {
//   const { name, value } = event.target;
//   setEdit({ ...edit, [name]: value });
// };


const { id } = useParams();
const [edit, setEdit] = useState({
    week_id: "",
    title: "",
    cdn: "",
    minutes: "",
    level: "",
    start: "",
    end: "",
});
const [errors, setErrors] = useState({});

const navigate = useNavigate();

const handleEdit = async (event) => {
  event.preventDefault();
  try {
    await axiosInstance.patch(
      `/api/homeworks/${id}`,
      {
        ...edit,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "ngrok-skip-browser-warning": "any",
        },
      }
    );
    setEdit({
        week_id: "",
        title: "",
        cdn: "",
        minutes: "",
        level: "",
        start: "",
        end: "",
    });
    swal({
      title: "تم تعديل الواجب بنجاح!",
      icon: "success",
      button: "حسناّ!",
    });
    navigate("/admin/homeworkdata");

  } catch (e) {
    if (e.response.status === 500) {
    
   
      console.log(e);
      setErrors(e.response.data.errors);
    }
  }
};

 
      // console.log(edit);  
    

const [homework , setHomework] = useState([]);
const getEditQuiz = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/api/weeks-selection' , {
        headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data.map(item => item.id))
      // console.log(response.data.data);
      setHomework(response.data.data)
      const data = response.data.data;
               return data;
  } catch (error) {
      console.log(error);
  }
}

useEffect (() =>{
  getEditQuiz();
}, [])









    return (
        <>
                  <div className="d-flex">
    
       <div className="add-quiz ">
      
      <div className="main-title"> تعديل الواجب رقم {id}  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                       <div className="codes pt-5">
                      
                        <div className="create-box">
                        <h2 className="create mb-4">  تعديل الواجب </h2>
                        <Form onSubmit={handleEdit}>
        

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText  ">
        <Form.Label className="ms-2">اسم الواجب</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الواجب" name="title" value={edit.title}  onChange={(e) => setEdit((prev) => ({ ...prev, title: e.target.value }))}/>
      </Form.Group>
      <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> درجة الواجب</Form.Label>
        <Form.Control type="number" placeholder="ادخل درجة الواجب" name="minutes" value={edit.minutes}  onChange={(e) => setEdit((prev) => ({ ...prev, minutes: e.target.value }))}/>
      </Form.Group>
      </div>
      <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ البداية</Form.Label>
        <Form.Control type="datetime-local"   name="start" value={edit.start}  onChange={(e) => setEdit((prev) => ({ ...prev, start: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ النهاية</Form.Label>
        <Form.Control type="datetime-local"  name="end" value={edit.end}  onChange={(e) => setEdit((prev) => ({ ...prev, end: e.target.value }))}/>
      </Form.Group>
      </div>
     {/* <div className="chooices d-lg-flex align-items-center">
   
      <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2">  الدرجة الصغري</Form.Label>
        <Form.Control type="number" placeholder="ادخل الدرجة الصغري" name="mindegree" onChange={(e)=>{setDegree(e.target.value)}}/>
      </Form.Group>
      </div> */}

   
     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
     <Form.Select aria-label="Default select example" name="week_id" value={edit.week_id} onChange={(e) => setEdit((prev) => ({ ...prev, week_id: e.target.value }))}> 
      <option>اختر رقم الحصة  </option>
      {
        homework.map(item => (
          <option key={item.id} value={item.id} name="week_id">{item.name}</option>
        ))
      }
    </Form.Select>
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3 group ">
      <Form.Select aria-label="Default select example" name="level" value={edit.level}  onChange={(e) => setEdit((prev) => ({ ...prev, levell: e.target.value }))}>
      <option> اختر السنه الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
      </Form.Group>
    
      </div>
   
   


   
  
    
              
      <Button variant="primary" type="submit" className="create-button">
        تعديل
      </Button>
    </Form>
                        </div>
                  
                        </div> 
              </div>
                   </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
        
    )
}

export default EditHomework;