import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
import "../../styles/quizstudent.css";

const Homework = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [homework, setHomework] = useState([]);
  const [time, setTime] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [question_id, setQuestion_id] = useState([]);
  const [choosen, setChoosen] = useState([]);
  const [solvedQuestions, setSolvedQuestions] = useState("");
  const [unsolvedQuestions, setUnsolvedQuestions] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${BASE_URL}/api/homework-enroll/${id}`, config)
      .then((response) => {
        setTime(response.data);
        setHomework(response.data.data);

        let selectedAnswersFromApi = {};

        response.data.data.forEach((currentObject, index) => {
          selectedAnswersFromApi[index] = currentObject?.choosen;
        });

        let solved = 0;
        Object?.keys(selectedAnswersFromApi)?.forEach((key) => {
          if (
            selectedAnswersFromApi[key] &&
            selectedAnswersFromApi[key] !== "0"
          ) {
            solved++;
          }
        });

        const unsolved = response.data.data.length - solved;
        setSelectedAnswers(selectedAnswersFromApi);
        setChoosen(selectedAnswersFromApi?.[1]);
        setSolvedQuestions(solved);
        setUnsolvedQuestions(unsolved);
        setIsLoading(false);
      })

      .catch((e) => {
        // console.error(e);
        if (e.response.status === 401) {
          swal({
            title: "! هذا الأسبوع غير موجود ",
            icon: "warning",
            button: "حسنا!",
          });
        } else if (e.response.status === 402) {
          swal({
            title: "! من فضلك قم بشراء هذه الحصة  ",
            icon: "warning",
            button: "حسنا!",
          });
        } else if (e.response.status === 403) {
          swal({
            title: "! عفواّ لقد انتهي الوقت ",
            text: " الأن ستذهب إلي نموذج الإجابة الخاص بك",
            icon: "warning",
            button: "حسنا!",
          });
          navigate(`/modelanswerhomework/${id}`);
        } else if (e.response.status === 405) {
          swal({
            title: "!لقد قمت بدخول هذا الامتحان من قبل ",
            icon: "warning",
            button: "حسنا!",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleNextQuestion = async (e) => {
    e.preventDefault();
    setCurrentQuestion(currentQuestion + 1);
    setChoosen(selectedAnswers[currentQuestion + 1] || "");
  };

  const handlePrevQuestion = (e) => {
    e.preventDefault();
    setCurrentQuestion(currentQuestion - 1);
    setChoosen(selectedAnswers[currentQuestion - 1] || "");
  };

  const handleFinish = (e) => {
    e.preventDefault();
    navigate(`/modelanswerhomework/${id}`);
  };

  const [answeredQuestions, setAnsweredQuestions] = useState([]);

  // const handleAnswerSelected = (e) => {
  //   const selectedAnswer = e.target.value;
  //   const selectedAnswersCopy = { ...selectedAnswers };
  //   selectedAnswersCopy[currentQuestion] = selectedAnswer;
  //   setSelectedAnswers(selectedAnswersCopy);
  //   setChoosen(selectedAnswer);
  //   setAnsweredQuestions([...answeredQuestions, currentQuestion]);
  // };

  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "any",
      Authorization: `Bearer ${token}`,
    },
  });

  const handleAnswerSelected = async (e) => {
    const selectedAnswer = e.target.value;
    const selectedAnswersCopy = { ...selectedAnswers };
    selectedAnswersCopy[currentQuestion] = selectedAnswer;
    setSelectedAnswers(selectedAnswersCopy);
    setChoosen(selectedAnswer);
    setAnsweredQuestions({ ...answeredQuestions, [currentQuestion]: true });

    try {
      const response = await axiosInstance.post(
        `/api/submit-quiz-question/${id}/${homework[currentQuestion].id}`,
        {
          choosen: selectedAnswer,
        }
      );
      // console.log(response.data);

      const solved = Object.values(selectedAnswersCopy).filter(
        (answer) => answer && answer !== "0"
      ).length;
      const unsolved = homework.length - solved;
      setSolvedQuestions(solved);
      setUnsolvedQuestions(unsolved);
    } catch (e) {
      // console.log(e.response);
    }
  };

  function CountdownTimer({ remainingTime }) {
    const [timeLeft, setTimeLeft] = useState(remainingTime);

    useEffect(() => {
      const remainingTime = time.message;
      const timer = setTimeout(() => {
        if (remainingTime <= 0) {
          navigate(`/modelanswerhomework/${id}`);
        } else {
          setTime({ message: remainingTime - 1 });
        }
      }, 1000);
      return () => clearTimeout(timer);
    }, [time, currentQuestion]);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    return (
      <div>
        {hours} :{minutes < 10 ? "0" : ""} {minutes} : {seconds < 10 ? "0" : ""}{" "}
        {seconds}
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <div className="main-loading">
          <div className="loading-course">
            <h2> .... جاري تحميل بيانات الواجب</h2>
          </div>
          ;
        </div>
      ) : (
        <div class="quiz-main pt-5">
          <div className="register-button mb-4 pt-4">
            <button class="register-btn" role="button">
              {" "}
              ركز شوية{" "}
            </button>
          </div>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="quiz-app ">
                  <div className="quiz-data">
                    <div className="timer">
                      <span>
                        {" "}
                        : باقي من الزمن <img
                          src="/images/clock.png"
                          alt=""
                        />{" "}
                      </span>
                      <span>
                        <CountdownTimer remainingTime={time.message} />
                      </span>
                    </div>
                    <div className="question-tabs">
                      {homework.map((q, index) => (
                        <div
                          key={q.id}
                          className={`question-tab ${
                            currentQuestion === index ? "active" : ""
                          } ${answeredQuestions[index] ? "answered" : ""}`}
                          onClick={(e) => {
                            setCurrentQuestion(index);
                            // handleSubmitQuestion(e, q.id);
                          }}
                        >
                          {index + 1}
                        </div>
                      ))}
                    </div>
                    <div className="quiz-info">
                      <div className="content">
                        <p> : اجمالي عدد الأسئلة </p>
                        <span className="length">{homework.length}</span>
                      </div>
                      <div className="content">
                        <p>: عدد الأسئلة المحلولة </p>
                        <span className="solved">
                          {" "}
                          {solvedQuestions ? solvedQuestions : "0"}
                        </span>
                      </div>
                      <div className="content">
                        <p> : عدد الأسئلة الغير محلولة </p>
                        <span className="unsolved">
                          {unsolvedQuestions ? unsolvedQuestions : "0"}
                        </span>
                      </div>
                    </div>

                    <div className="quiz-control">
                      <button
                        className="prev"
                        onClick={handlePrevQuestion}
                        disabled={currentQuestion === 0}
                      >
                        السابق
                      </button>
                      <button
                        className="next"
                        onClick={handleNextQuestion}
                        disabled={currentQuestion === homework.length - 1}
                      >
                        التالي
                      </button>
                    </div>
                    <div className="quiz-finish">
                      {currentQuestion === homework.length - 1 && (
                        <button
                          type="submit"
                          className="finish"
                          onClick={
                            (e) => handleFinish(e)

                            // homework[currentQuestion].id
                          }
                        >
                          انهي الإمتحان
                        </button>
                      )}
                    </div>
                  </div>

                  {homework.length > 0 && (
                    <form className="form-quiz">
                      <div></div>

                      <div className="exam">
                        <p class="arrange">
                          {" "}
                          رقم السؤال : <span>{currentQuestion + 1}</span>
                        </p>

                        <h3 className="question">
                          {homework[currentQuestion].question}
                        </h3>
                        <img
                          src={`${Image_URL}/storage/${homework[currentQuestion].img}`}
                          className="img-question"
                          alt=""
                        />
                        <div className="answers d-flex">
                          {homework[currentQuestion].c1 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="1"
                                checked={
                                  selectedAnswers[currentQuestion] === "1"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {homework[currentQuestion].c1}
                              <img
                                src={`${Image_URL}/storage/${homework[currentQuestion].c1_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}

                          {homework[currentQuestion].c2 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="2"
                                checked={
                                  selectedAnswers[currentQuestion] === "2"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {homework[currentQuestion].c2}
                              <img
                                src={`${Image_URL}/storage/${homework[currentQuestion].c2_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}

                          {homework[currentQuestion].c3 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="3"
                                checked={
                                  selectedAnswers[currentQuestion] === "3"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {homework[currentQuestion].c3}
                              <img
                                src={`${Image_URL}/storage/${homework[currentQuestion].c3_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}

                          {homework[currentQuestion].c4 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="4"
                                checked={
                                  selectedAnswers[currentQuestion] === "4"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {homework[currentQuestion].c4}
                              <img
                                src={`${Image_URL}/storage/${homework[currentQuestion].c4_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}
                        </div>
                      </div>
                      <br />
                    </form>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default Homework;
