import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import '../../styles/createcode.css'
import { CSVLink } from "react-csv";
import SidebarAdminMain from "../SidebarAdminMain";
import { BASE_URL, tableCustomStyles } from "../../App";
import swal from "sweetalert";


function Createcode() {
  const [codes, setCodes] = useState([]);
  const [search, setSearch] = useState("");
  const [filtercodes, setFiltercodes] = useState([]);
  const [numberOfCodes, setNumberOfCodes] = useState("");
  const [code, setCode] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState("");
  const [CodeResponce, setCodeResponce] = useState([]);



  const token = localStorage.getItem('token');
  
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      'Content-Type': 'application/json',
      "ngrok-skip-browser-warning": "any",
      Authorization: `Bearer ${token}`,
    },
  });

  // الريكويست الخاص بإنشاء الأكواد
  
  const handleCreateCode = async (event) => {
    event.preventDefault();
  
    try {
      const response = await axiosInstance.post("/api/codes", {
        numberOfCodes,
        value,
      });
      setCodeResponce(response.data.data);
      setNumberOfCodes("");
      setValue("");
    } catch (error) {
      console.error(error);
    }
  };
  

  // او الكود Id الريكويست الخاص ب البحث عن الأكواد بالأسم او 

  const [SearchCodeResponce, setSearchCodeResponce] = useState([]);
  const handleSearchCode = async(event) => {
      event.preventDefault();
      try {
          await axiosInstance.post("/api/codes/search", { code, id })
              .then(response => {
                  setSearchCodeResponce(response.data.data);
                  console.log(response.data.data);
              });
          setCode("");
          setId("");

      } catch (e) {
        if(e.response.status === 422) {
            swal({
                title: "! هذا الأسم لم يستخدم اكواد من قبل",
                text: "Id يرجي التحقق من رقم الإسم او ",
                icon: "warning",
                button: "! اعد المحاولة",
              });
        }
      }
  }


  useEffect(() => {
      const result = codes.filter(code => {
          return code.name.toLowerCase().match(search.toLowerCase());
      });
      setFiltercodes(result);
  }, [search])


  // الريكويست الخاص بمسح الكود

  const deleteRow = async(id, token) => {
      try {
          const response = await axios.delete(`${BASE_URL}/api/codes/${id}`, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });
      } catch (error) {
      }
  };

  // الريكويست الخاص بإعادة استخدام الكود

  const clearRow = async(id) => {
      try {
        const token = localStorage.getItem('token');
          const response = await axiosInstance.get(`/api/codes/clear/${id}`, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });
          setCodes(response.data.data)
          setFiltercodes(response.data.data)
          const data = response.data.data;
          return data;
      } catch (error) {
        //   console.log(error);
      }
  }

  const [rows, setRows] = useState([]);

  // الفانشكن المسئولة عن مسح الكود

  const handleDeleteClick = async (id) => {
    const token = localStorage.getItem('token');
    try {
        await deleteRow(id, token);
        const updateCode = CodeResponce.filter(row => row.id !== id);
        const updateCodes = SearchCodeResponce.filter(row => row.id !== id);
        setCodeResponce(updateCode);
        setSearchCodeResponce(updateCodes);
    } catch (error) {
        // console.error(error);
    }
};
  

  // الفانشكن المسئولة عن اعادة استخدام الكود


const handleClearClick = async (id) => {
    const token = localStorage.getItem('token');
    try {
        await clearRow(id, token);
        const updateCode = CodeResponce.filter(row => row.id !== id);
        const updateCodes = SearchCodeResponce.filter(row => row.id !== id);
        setCodeResponce(updateCode);
        setSearchCodeResponce(updateCodes);
    } catch (error) {
        // console.error(error);
    }
};

  const columns = [{
          name: "ID",
          selector: 'id',
          sortable: true,
      },
      {
          name: "Price ",
          selector: 'value',
          sortable: true,
      },
      {
          name: "Code ",
          selector: 'code',
          grow: 3
      },
      {
          name: "created_at",
          selector: 'created_at',
          grow: 2,
          sortable: true,

      },
      {
          name: "updated_at",
          selector: 'updated_at',
          grow: 2
      },


      {
          name: "Status",
          cell: (row) => (row.usedBy === null ? < button style = {{ background: "#", border: "none" } }className = "btn btn-secondary" > not used </button> : <button style={{background:"#" , border: "none"}} className="btn btn-primary">{row.usedBy}</button > ),
          grow: 3,
      },
      {
          name: "Clear",

          cell: (row) => < button style = { { background: "#", border: "none" }}
          className = "btn btn-success"
          disabled = { row.usedBy === "" }
          onClick = {
              () => alert('هل انت متأكد من تفريغ هذا الكود') | handleClearClick(row.id)
          }> Clear </button>,
          grow: 2,
      },
      {
          name: "Delete",
          cell: row => <button style = {
              { background: "#", border: "none" }
          }
          className = "btn btn-danger"
          onClick = {
              () => alert('هل انت متأكد من حذف هذا الكود') | handleDeleteClick(row.id)
          } > Delete </button>,
          grow: 2,
      },

      // {
    
  ];
  return ( 
  <>
      < div className = "d-flex" >

      <div className = "create-code">

      <div className = "main-title" > انشاء الأكواد </div>

      < Container >

      <Row >

      <Col lg = { 12 } >
 < div className = "quiz-content" >
      <div className = "codes pt-5" >

      <div className = "create-box" >
      <  h2 className = "create mb-4" > انشاء الأكواد </h2> 
      < Form onSubmit = { handleCreateCode } >
      <Form.Group className = "mb-3"controlId = "formBasicNumber" >
      < Form.Label className = "ms-2" > عدد الأكواد </Form.Label> 
      <Form.Control type = "number" placeholder = " ادخل عدد الأكواد"  name = "numberOfCodes" required onChange = {  (e) => setNumberOfCodes(e.target.value)}/>
      </ Form.Group>

      < Form.Group className = "mb-3  ms-lg-4 me-lg-4 me-md-4 ms-md-4 ms-0 me-0" controlId = "formBasicNumber" >
        <Form.Label className = "ms-2" > سعر الأكواد </Form.Label> 
        < Form.Control type = "number"placeholder = " ادخل سعر الكود"
        name = "value"
      required onChange = {
          (e) => setValue(e.target.value)
      }
      /> </Form.Group > 
      <Button variant = "primary"
      type = "submit"
      className = "create-button" >
      انشئ </Button> 
      </Form >

      </div> 


      <div className = "table-code" >


      <DataTable className = "table"
                     customStyles={tableCustomStyles}

      columns = { columns }
      data = { CodeResponce }
      pagination fixedHeader highlightOnHover 
      actions = { 
      <CSVLink
          data = {CodeResponce}
          filename = "GeneratedCodes"
          className = "btn btn-success mb-3 mt-4 me-2" > Export File Excel </CSVLink>
      }
      />

      </div>

      <div className = "create-box mt-5" >
      <h2 className = "create mb-4" > البحث عن الأكواد </h2> 
      <Form onSubmit = { handleSearchCode } >

      <Form.Group className = "mb-3"
      controlId = "formBasicNumber" >
      <Form.Label className = "ms-2" > الكود </Form.Label>
      <Form.Control type = "text"
      placeholder = " ادخل  الكود"
      name = "code"
      onChange = {
          (e) => setCode(e.target.value)
      }
      /> 
      </Form.Group >

      < Form.Group className = "mb-3 ms-lg-4 me-lg-4 me-md-4 ms-md-4 ms-0 me-0"
      controlId = "formBasicNumber" >
      < Form.Label className = "ms-2" >  أو الأسم id الطالب </Form.Label> 
      <Form.Control type = "text"
      placeholder = " id الطالب"
      name = "id"
      onChange = {
          (e) => setId(e.target.value)
      }
      /> 
      </Form.Group > 
      <Button variant = "primary"
      type = "submit"
      className = "search-button" >
      ابحث </Button> 
      </Form >

      </div>    

      <div className = "table-code" >


      <DataTable className = "table"
                           customStyles={tableCustomStyles}

      columns = { columns }
      data = { SearchCodeResponce }
      pagination fixedHeader highlightOnHover 
  

      />

      </div>

      </div>  
      </div > 
      </Col>
      </Row > 
      </Container>


      </div> 
      <div className = "sidebar" >
      <SidebarAdminMain />
      </div> 
      </div> 
      </>

  )
}


export default Createcode;