import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../styles/addhomework.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { Navigate, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { serialize } from "object-to-formdata";
import { BASE_URL } from "../../App";



function Addfile () {
  
  const [title, setTitle] = useState("");
  const [cdn, setCdn] = useState(null);
  const [week_id, setWeek_id] = useState("");
  const [errors, setErrors] = useState("");
  const [file , setFile] = useState([]);


  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      "Content-Type": "multipart/form-data" ,
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
});


  const handleAddFile = async (event) => {
    event.preventDefault();
    try {
      const options = {
        indices: true,

        nullsAsUndefineds: true,

        booleansAsIntegers: true,

        allowEmptyArrays: true,

        noFilesWithArrayNotation: false,

        dotsForObjectNotation: false,
      };
        const data = {title , cdn , week_id}
        const formData = serialize(data , options);
        console.log([...formData]);

        await axiosInstance.post("/api/materials" , formData);
        setTitle("");
        setCdn(null);
        setWeek_id("");

        swal({
            title: "تم إضافة الملف بنجاح!",
            icon: "success",
            button: "حسناّ!",
          });
          navigate("/admin/filedata");
         
    } catch(e) {
        if(e.response.status === 503) {
            setErrors(e.response.data.errors);
                  // console.log(e);
        }
    }
}


const getFile = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/api/weeks-selection' , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data);
      setFile(response.data.data)
      const data = response.data.data;
               return data;
  } catch (error) {
      // console.log(error);
  }
}

useEffect (() =>{
  getFile();
}, [])

    return (
        <>
                  <div className="d-flex">
    
       <div className="add-homework ">
      
      <div className="main-title"> اضافة ملف </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                       <div className="codes pt-5">
                      
                        <div className="create-box">
                        <h2 className="create mb-4">  اضافة ملف </h2>
                        <Form onSubmit={handleAddFile}> 
        

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group className="mb-3" controlId="formBasicText">
        <Form.Label className="ms-2">اسم الملف</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الملف" name="title" onChange={(e)=>setTitle(e.target.value)}/>
        {errors.title && <div className="error mt-3">{errors.title[0]}</div>}

        
      </Form.Group>
      
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label> الملف</Form.Label>
        <Form.Control type="file" name="cdn" onChange={(e)=>setCdn(e.target.files[0])}/>
        {errors.cdn && <div className="error mt-3">{errors.cdn[0]}</div>}

      </Form.Group>
     </div>
     <Form.Select aria-label="Default select example" name="week_id" onChange={(e) => setWeek_id(e.target.value)}> 
      <option>اختر اسم الحصة </option>
      {
        file.map(item => (
          <option key={item.id} value={item.id} name="week_id" onChange={(e) => setWeek_id(e.target.value)}>{item.name}</option>
        ))
      }
    </Form.Select>
    {errors.week_id && <div className="error mt-3">{errors.week_id[0]}</div>}

   
  
   
        

      <Button variant="primary" type="submit" className="create-button">
        اضف
      </Button>
    </Form>
                        </div>
                  
                        </div> 
              </div>
                   </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
        
    )
}

export default Addfile;


