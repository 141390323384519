
import './App.css';
import './styles/table.css'
import { Navigate, useLocation, useNavigate } from 'react-router';
import AppNavbar from './user/AppNavbar';
// import LandingPage from './components/LandingPage';
// import Feature from './components/Feature';
// import Courses from './components/Courses';
import Footer from './user/Footer';
// import Form from './components/Form';  x 
import { Route, Router, Routes} from 'react-router-dom';
import Homepage from './user/Homepage';
import Years from './user/CoursesStudent/Courses';
// import ContentCourse from './user/ContentCourse';
import Quiz from './user/QuizStudent/Quiz';
import Profile from './user/ProfileStudent/Profile';
import Profilecourses from './user/ProfileStudent/Profilecourses';
import VideoPlayer from './user/VideoStudent/VideoPlayer';
import Profilequiz from './user/ProfileStudent/Profilequiz';
import HomepageAdmin from './admin/Homepageadmin';
import SidebarAdmin from './admin/SidebarAdmin';
import { createContext, useLayoutEffect, useState } from 'react';
import AllStudentData from './admin/StudentDataAdmin/Allstudentdata';
import Admins from './admin/AdminName/AdminName';
import Createcode from './admin/CodeAdmin/Createcode';
import AdminYear from './admin/AdminYear';
import Questiondata from './admin/QuestionAdmin/QuestionData';
import Addquestion from './admin/QuestionAdmin/AddQuestion';
import Adminvideo from './admin/VideoAdmin/AdminVideo';
import Addvideo from './admin/VideoAdmin/AddVideo';
import Adminquiz from './admin/QuizAdmin/QuizData';
import Addquiz from './admin/QuizAdmin/AddQuiz';
import Adminhomework from './admin/HomeworkAdmin/AdminHomework';
import Addhomework from './admin/HomeworkAdmin/AddHomework';
import AdminFile from './admin/FileAdmin/AdminFile';
import Addfile from './admin/FileAdmin/AddFile';
import Adminfile from './admin/FileAdmin/AdminFile';
import Adminweek from './admin/WeekAdmin/AdminWeek';
import Addweek from './admin/WeekAdmin/AddWeek';
import Registeruser from './user/RegisterUser';
import Loginuser from './user/LoginUser';
import Addadmin from './admin/AdminName/AddAdmin';
import EditAdmin from './admin/AdminName/EditAdmin';
import Editstudent from './admin/StudentDataAdmin/EditStudent';
import EditQuestion from './admin/QuestionAdmin/EditQuestion';
import EditFile from './admin/FileAdmin/EditFile';
import StudentQuiz from './admin/QuizAdmin/StudentsQuiz';
import ShowLevelStudent from './admin/StudentDataAdmin/ShowLevelStudent';
import EditQuiz from './admin/QuizAdmin/EditQuiz';
import ShowLevelQuestion from './admin/ShowLevelQuestion';
import Pay from './user/CoursesStudent/ContentCourse';
import ShowLevelHomework from './admin/ShowLevelHomework';
import QuestionSelector from './admin/QuestionAdmin/QuestionSelector';
import ShowLevelQuiz from './admin/ShowLevelQuiz';
import EditWeek from './admin/WeekAdmin/EditWeek';
import EditVideo from './admin/VideoAdmin/EditVideo';
import StudentWeek from './admin/WeekAdmin/StudentWeek';
import { useEffect } from 'react';
import ChargeWallet from './user/ChargeWallet';
import Courses from './user/CoursesStudent/Courses';
import MyCourses from './user/CoursesStudent/MyCourses';
import ModelAnswer from './user/QuizStudent/ModelAnswerQuiz';
import EditHomework from './admin/HomeworkAdmin/EditHomework';
import StudentHomework from './admin/HomeworkAdmin/StudentHomework';
import Homework from './user/HomeworkStudent/Homework';
import ModelAnswerQuiz from './user/QuizStudent/ModelAnswerQuiz';
import ModelAnswerHomework from './user/HomeworkStudent/ModelAnswerHomework';
import axios from 'axios';
import swal from 'sweetalert';
import StartQuiz from './user/QuizStudent/StartQuiz';
import StartHomework from './user/HomeworkStudent/StartHomework';
import ProfileHomework from './user/ProfileStudent/ProfileHomework';
import StartPdf from './user/PdfStudent/Pdf';
import PayNotLogin from './user/CoursesStudent/ContentCourseNotLogin';
import StudentVideo from './admin/VideoAdmin/StudentVideo';
import QuestionSelectorHomeWork from './admin/HomeworkAdmin/QuestionSelectorHomework';
import StudentStatistic from './admin/ProfileStudentWithAdmin/StudentStatistic';
import StudentCourseWithAdmin from './admin/ProfileStudentWithAdmin/StudentCourse';
import StudentQuizProfile from './admin/ProfileStudentWithAdmin/StudentQuizess';
import StudentProfileHomeworks from './admin/ProfileStudentWithAdmin/StudentHomework';
import ModelAnswerQuizWithAdmin from './admin/QuizAdmin/ModelAnswerQuizWithAdmin';
import NumberPhone from './user/NumberPhone';
import ScrollTopButton from './user/ScrollTop';
import EditStudentBySelf from './user/EditStudentBySelf';
import AdminLevelVideo from './admin/ShowLevelVideo';
import AdminLevelWeek from './admin/ShowLevelWeek';
import TopRatedQuiz from './user/QuizStudent/TopRatedQuiz';
import Videoss from './user/Videossss';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IconButton } from '@material-ui/core';





// export const ThemeContext = createContext(null);
export const BASE_URL = "https://api.mr-mohammedsaad.com";
export const Image_URL = "https://api.mr-mohammedsaad.com";

export const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      // paddingLeft: '0 8px',
      // justifyContent: 'center',
      // backgroundColor: '#FFA500',
      borderBottom: "3px solid #FFA500"
    },
  },
  cells: {
    style: {
      // fontSize: '17px',
      padding: '20px 15px',
    },
  },
}
function App() {

  useLayoutEffect (() =>{
    const loader = document.getElementById("loader");
    loader.classList.add("loaded");

    setTimeout(() =>{
      const loader = document.getElementById('loader');
      if (document.body.contains(loader)) {
        document.body.removeChild(loader);
      }}, 300)
  } , []);




  const [loggedIn, setLoggedIn] = useState(0);

  
  const [role , setRole] = useState(2)
  
    const [auth , setAuth] = useState("")
     const getToken = async () => {
          const token = localStorage.getItem('token');
          if(!token) {
            setLoggedIn(0)
            clearToken()
          }
          else {
            setLoggedIn(1)
            try {
              const token = localStorage.getItem('token');
                const response = await axios.get('https://api.mr-mohammedsaad.com/api/Auth' , {
                  headers: {
                    'Content-Type': 'application/json',
                    "ngrok-skip-browser-warning": "any",
                    Authorization: `Bearer ${token}`,
                  },
                });
                setRole(response.data.role)
                setAuth(response.data)
                setLoggedIn(1)
            } 
            catch (error) {
              if(error.response.status === 401) {
                swal({
                  title: "! انتهت مدة تسجيل الدخول",
                  text: "يرجي تسجيل الدخول مرة أخري",
                  icon: "warning",
                  button: "حسنا!",
                });
              setLoggedIn(0)
              clearToken()
              setRole('')
              }
            }
          }
        }
        useEffect(() => {
          const token = localStorage.getItem('token');
          if (token) {
            getToken();
          } else {
            getToken();
          }
        }, []);
        



      const clearToken = () => {
                // window.location.reload()

        localStorage.removeItem('token');
        localStorage.removeItem('sha1');
        setLoggedIn(0);

      };


      const authed = localStorage.getItem("token");
      const roled = localStorage.getItem("sha1");
     
    
      function NotFound() {
        return (
          <div className="not-found">
            <h1><span>404</span> - Page Not Found</h1>
          </div>
        );
      }

      const location = useLocation();

      // Check if the current route is /video/:id
      const isVideoPage = location.pathname.startsWith('/video/')

      // const [darkMode, setDarkMode] = useState(false);

      // const toggleDarkMode = () => {
      //   setDarkMode(!darkMode);
      // };
      
      // const theme = createMuiTheme({
      //   palette: {
      //     type: darkMode ? 'dark' : 'light',
      //     primary: {
      //       main: '#1e88e5',
      //     },
      //     secondary: {
      //       main: '#4caf50',
      //     },
      //   },
      // });
      
      

      
  return (
   
<div className="App">
{/* <IconButton onClick={toggleDarkMode} color="inherit">
  {darkMode ? 'dark' : 'light'}
</IconButton> */}

{/* <ThemeProvider theme={theme}> */}
  {/* <CssBaseline />*/}
  {/* darkMode={darkMode}
    toggleDarkMode={toggleDarkMode} */}
      {!isVideoPage && <AppNavbar loggedIn={loggedIn} setLoggedIn={setLoggedIn} clearToken={clearToken}/>}

      {/* <AppNavbar loggedIn={loggedIn} setLoggedIn={setLoggedIn} clearToken={clearToken} /> */}
        


                 <Routes basename="/">
          <Route path='/registeruser' element={loggedIn ? <Navigate to="/"/> : <Registeruser />} />
          <Route path='/loginuser' element={loggedIn ? <Navigate to="/" /> : <Loginuser loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path='/' element={<Homepage loggedIn={loggedIn} setLoggedIn={setLoggedIn} auth={auth}/>} />
          <Route path='/courses/:id' element={loggedIn ? <Navigate to="/" /> : <Courses />} />
          <Route path='/course-content/:id' element={loggedIn ? <Navigate to="/" /> : <PayNotLogin />} />
         
          <Route path='/mycourses' element={!authed ? <Navigate to="/loginuser" /> : <MyCourses/> }/>
          <Route path='/course' element={!authed ? <Navigate to="/loginuser" /> : <Courses />} />
          <Route path='/contentcourse/:id' element={!authed ? <Navigate to="/loginuser" /> : <Pay />} />
          <Route path='/chargewallet' element={!authed ? <Navigate to="/loginuser" /> : <ChargeWallet />} />
          <Route path='/quiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <Quiz />} />
          <Route path='/startquiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <StartQuiz />} />
          <Route path='/modelanswerquiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <ModelAnswerQuiz />} />
          <Route path='/TopRatedQuiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <TopRatedQuiz />} />
          <Route path='/homework/:id' element={!authed ? <Navigate to="/loginuser" /> : <Homework />} />
          <Route path='/starthomework/:id' element={!authed ? <Navigate to="/loginuser" /> : <StartHomework />} />
          <Route path='/modelanswerhomework/:id' element={!authed ? <Navigate to="/loginuser" /> : <ModelAnswerHomework />} />
          <Route path='/video/:id' element={!authed ? <Navigate to="/loginuser" /> : <VideoPlayer />} />
          <Route path='/startpdf/:id' element={!authed ? <Navigate to="/loginuser" /> : <StartPdf />} />
          <Route path='/profile' element={!authed ? <Navigate to="/loginuser" /> : <Profile />} />
          <Route path='/profile/courses' element={!authed ? <Navigate to="/loginuser" /> : <Profilecourses />} />
          <Route path='/profile/quiz' element={!authed ? <Navigate to="/loginuser" /> : <Profilequiz />} />
          <Route path='/profile/homeworks' element={!authed ? <Navigate to="/loginuser" /> : <ProfileHomework />} />
          <Route path='/numberphone' element={ <NumberPhone />} />
          <Route path='/editinfo' element={ <EditStudentBySelf />} />
          
    
          <Route path='/admin' element={roled > 0 && authed && role > 0 ? <HomepageAdmin /> : <Navigate to="/" />} />
          <Route path='/admin/allstudentdata' element={roled > 0 && authed && role > 0 ? <AllStudentData /> : <Navigate to="/" />}/>
          <Route path='/admin/levelstudent/:id' element={roled > 0 && authed && role > 0 ? <ShowLevelStudent />: <Navigate to="/" />}/>
          <Route path='/admin/editstudent/:id' element={roled > 0 && authed && role > 0 ? <Editstudent /> : <Navigate to="/" /> }/>
          <Route path='/admin/admins' element={roled > 0 && authed && role > 0 ? <Admins /> :  <Navigate to="/" />}/>
          <Route path='/admin/addadmin' element={roled > 0 && authed && role > 0 ? <Addadmin /> : <Navigate to="/" />}/>
          <Route path='/admin/editadmin/:id' element={ roled > 0 && authed && role > 0 ? <EditAdmin /> : <Navigate to="/" />}/>
          <Route path='/admin/createcodes' element={ roled > 1 && authed && role > 1 ? <Createcode /> : <Navigate to="/" />}/>
          <Route path='/admin/year/:id' element={roled > 0 && authed && role > 0 ? <AdminYear /> :<Navigate to="/" /> }/>
          <Route path='/admin/questiondata' element={roled > 0 && authed && role > 0 ? <Questiondata/> : <Navigate to="/" /> }/>
          <Route path='/admin/levelquestion/:id' element={ roled > 0 && authed && role > 0 ? <ShowLevelQuestion/> : <Navigate to="/" /> }/>
          <Route path='/admin/editquestion/:id' element={ roled > 0 && authed && role > 0 ? <EditQuestion/> : <Navigate to="/" /> }/>
          <Route path='/admin/addquestion' element={ roled > 0 && authed && role > 0 ? <Addquestion/> : <Navigate to="/" />}/>
          <Route path='/admin/questionselector/:id' element={roled > 0 && authed && role > 0 ? <QuestionSelector /> : <Navigate to="/" />}/>
          <Route path='/admin/questionselectorhomework/:id' element={roled > 0 && authed && role > 0 ? <QuestionSelectorHomeWork /> : <Navigate to="/" />}/>
          <Route path='/admin/videodata' element={roled > 0 && authed && role > 0 ? <Adminvideo/> : <Navigate to="/" />}/>
          <Route path='/admin/addvideo' element={ roled > 0 && authed && role > 0 ?  <Addvideo/> : <Navigate to="/" />}/>
          <Route path='/admin/editvideo/:id' element={roled > 0 && authed && role > 0 ? <EditVideo/> : <Navigate to="/" />}/>
          <Route path='/admin/levelvideo/:id' element={roled > 0 && authed && role > 0 ? <AdminLevelVideo/> : <Navigate to="/" />}/>
          <Route path='/admin/studentvideo/:id' element={roled > 0 && authed && role > 0 ? <StudentVideo/> : <Navigate to="/" />}/>
          <Route path='/admin/quizdata' element={roled > 0 && authed && role > 0 ? <Adminquiz/> : <Navigate to="/" />}/>
          <Route path='/admin/levelquiz/:id' element={roled > 0 && authed && role > 0 ? <ShowLevelQuiz/> : <Navigate to="/" />}/>
          <Route path='/admin/addquiz' element={roled > 0 && authed && role > 0 ? <Addquiz/> : <Navigate to="/" />}/>
          <Route path='/admin/editquiz/:id' element={roled > 0 && authed && role > 0 ? <EditQuiz/> : <Navigate to="/" /> }/>
          <Route path='/admin/quizstudents/:id' element={roled > 0 && authed && role > 0 ?  <StudentQuiz/> :  <Navigate to="/" />}/>
          <Route path='/admin/edithomework/:id' element={roled > 0 && authed && role > 0 ? <EditHomework/> :  <Navigate to="/" />}/>
          <Route path='/admin/studenthomework/:id' element={roled > 0 && authed && role > 0 ? <StudentHomework/> :  <Navigate to="/" />}/>
          <Route path='/admin/homeworkdata' element={roled > 0 && authed && role > 0 ? <Adminhomework/> : <Navigate to="/" />}/>
          <Route path='/admin/levelhomework/:id' element={ roled > 0 && authed && role > 0 ?  <ShowLevelHomework /> : <Navigate to="/" />}/>
          <Route path='/admin/addhomework' element={roled > 0 && authed && role > 0 ? <Addhomework /> : <Navigate to="/" />}/>
          <Route path='/admin/filedata' element={roled > 0 && authed && role > 0 ? <Adminfile /> : <Navigate to="/" />}/>
          <Route path='/admin/addfile' element={roled > 0 && authed && role > 0 ? <Addfile /> : <Navigate to="/" />}/>
          <Route path='/admin/editfile/:id' element={roled > 0 && authed && role > 0 ? <EditFile /> : <Navigate to="/" />}/>
          <Route path='/admin/weekdata' element={roled > 0 && authed && role > 0 ? <Adminweek /> : <Navigate to="/" /> }/>
          <Route path='/admin/addweek' element={roled > 0 && authed && role > 0 ? <Addweek /> :  <Navigate to="/" />}/>
          <Route path='/admin/editweek/:id' element={roled > 0 && authed && role > 0 ? <EditWeek /> : <Navigate to="/" />}/>
          <Route path='/admin/levelweek/:id' element={roled > 0 && authed && role > 0 ? <AdminLevelWeek /> : <Navigate to="/" />}/>
          <Route path='/admin/studentsweek/:id' element={roled > 0 && authed && role > 0 ? <StudentWeek /> : <Navigate to="/" />}/>
          <Route path='/admin/studentprofile/:id' element={roled > 0 && authed && role > 0 ? <StudentStatistic /> : <Navigate to="/" />}/>
          <Route path='/admin/studentprofilecourses/:id' element={roled > 0 && authed && role > 0 ? <StudentCourseWithAdmin /> : <Navigate to="/" />}/>
          <Route path='/admin/studentprofilequizess/:id' element={roled > 0 && authed && role > 0 ? <StudentQuizProfile /> : <Navigate to="/" />}/>
          <Route path='/admin/studentprofilehomeworks/:id' element={roled > 0 && authed && role > 0 ? <StudentProfileHomeworks /> : <Navigate to="/" />}/>
          <Route path='/admin/modelanswerquiz/:id' element={roled > 0 && authed && role > 0 ? <ModelAnswerQuizWithAdmin /> : <Navigate to="/" />}/>
          <Route path='*' element={<NotFound />} />
      </Routes>
      


      {<Footer/>}
      {/* </ThemeProvider> */}


  
     
      </div>
      
  );
}

export default App;
