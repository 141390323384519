import { useNavigate, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import swal from 'sweetalert'
import { useEffect } from 'react'

const StartQuiz = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const start = () => {
  //   swal({
  //     title: "قبل متبدأ امتحانك اعمل ريفرش للصفحة",
  //     icon: "warning",
  //     button: "حسنا!",
  //   }).then((willstart) => {
  //     if (willstart) {
       
  //     }
  // })
  navigate(`/quiz/${id}`)
  }

  return (
    <div className="quiz-main pt-4">
                <div className="register-button mb-4 pt-4">
<button class="register-btn" role="button"> ركز شوية </button>
    </div> 
      <Container>
        <Row>
          <Col lg={12}>
           <div className="quiz-app">
              <div className="quiz-data">
                <button className="start" onClick={start}>
                  أبدأ الأمتحان الآن
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StartQuiz
