import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL, Image_URL } from "../../App";
import "../../styles/quizstudent.css";

const Quiz = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quiz, setQuiz] = useState([]);
  const [time, setTime] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [question_id, setQuestion_id] = useState([]);
  const [choosen, setChoosen] = useState([]);
  const [solvedQuestions, setSolvedQuestions] = useState("");
  const [unsolvedQuestions, setUnsolvedQuestions] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${BASE_URL}/api/quiz-enroll/${id}`, config)
      .then((response) => {
        setTime(response.data);
        setQuiz(response.data.data);
        
        let selectedAnswersFromApi = {};
        response.data.data.forEach((currentObject, index) => {
          selectedAnswersFromApi[index] = currentObject?.choosen;
        });

        let solved = 0;
        Object?.keys(selectedAnswersFromApi)?.forEach((key) => {
          if (
            selectedAnswersFromApi[key] &&
            selectedAnswersFromApi[key] !== "0"
          ) {
            solved++;
          }
        });

        const unsolved = response.data.data.length - solved;
        setSelectedAnswers(selectedAnswersFromApi);
        setChoosen(selectedAnswersFromApi?.[1]);
        setSolvedQuestions(solved);
        setUnsolvedQuestions(unsolved);
        setIsLoading(false);
      })

      .catch((e) => {
        // console.log(e);

        if (e.response.status === 401) {
          swal({
            title: "! هذا الأسبوع غير موجود ",
            icon: "warning",
            button: "حسنا!",
            dangerMode: true,

          });
        } else if (e.response.status === 402) {
          swal({
            title: "! من فضلك قم بشراء هذه الحصة  ",
            icon: "warning",
            button: "حسنا!",
            dangerMode: true,

          });
        } else if (e.response.status === 403) {
          swal({
            title: "! عفواّ لقد انتهي الوقت ",
            // text: " الأن ستذهب إلي نموذج الإجابة الخاص بك",
            icon: "warning",
            button: "حسنا!",
            dangerMode: true,

          }).then(() => {
            window.history.go(-2);
          });
          // navigate(`/modelanswerquiz/${id}`);
        } else if (e.response.status === 405) {
          swal({
            title: "!لقد قمت بدخول هذا الامتحان من قبل ",
            icon: "warning",
            button: "حسنا!",
            dangerMode: true,

          })
        } else if (e.response.status === 449) {
          swal({
            title: "! لسه معاد الامتحان مجاش ",
            icon: "warning",
            button: "حسنا!",
            dangerMode: true,

          }).then(() => {
            window.history.go(-2);
          });
        }
         else if (e.response.status === 448) {
          swal({
            title: "!  معاد الامتحان خلص ",
            icon: "warning",
            button: "حسنا!",
            dangerMode: true,
          }).then(() => {
            window.history.go(-2);
          });
        }
         else if (e.response.status === 468) {
          swal({
            title: "!  لقد انهيت هذا الأمتحان من قبل ",
            icon: "warning",
            button: "حسنا!",
            dangerMode: true,
          }).then(() => {
            window.history.go(-2);
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [submitting, setSubmitting] = useState(false);
  // const [submitError, setSubmitError] = useState(false);


  const handleFinish = (e) => {
    e.preventDefault();
    swal({
      title: "هل أنت متأكد من تسليم إجاباتك؟",
      icon: "warning",
      buttons: ["هراجع تاني" , "متأكد"],
      dangerMode: true,
    }).then((willSubmit) => {
      if (willSubmit) {
        setSubmitting(true);
        
      }
    });
  };
  

  useEffect(() => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
      },
    };
    if (submitting) {
      axios
        .get(`${BASE_URL}/api/finish-quiz/${id}`, config)
        .then((response) => {
          // console.log(response.data);
          setSubmitting(false);
          swal("تم تسليم إجاباتك بنجاح!", {
            title: " ❤️ بالتوفيق",
            icon: "success",
          });
          window.history.go(-2);

        })
        .catch((error) => {
          // console.log(error);
          setSubmitting(false);
          // setSubmitError(true);
        });
    }
  }, [submitting]);

  

  const handleNextQuestion = async (e) => {
    e.preventDefault();
    setCurrentQuestion(currentQuestion + 1);
    setChoosen(selectedAnswers[currentQuestion + 1] || "");
  };

  const handlePrevQuestion = (e) => {
    e.preventDefault();
    setCurrentQuestion(currentQuestion - 1);
    setChoosen(selectedAnswers[currentQuestion - 1] || "");
  };


  const [answeredQuestions, setAnsweredQuestions] = useState([]);


  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": "any",
      Authorization: `Bearer ${token}`,
    },
  });

  const handleAnswerSelected = async (e) => {
    const selectedAnswer = e.target.value;
    const selectedAnswersCopy = { ...selectedAnswers };
    selectedAnswersCopy[currentQuestion] = selectedAnswer;
    setSelectedAnswers(selectedAnswersCopy);
    setChoosen(selectedAnswer);
    setAnsweredQuestions({ ...answeredQuestions, [currentQuestion]: true });
  
    try {
      const response = await axiosInstance.post(
        `/api/submit-quiz-question/${id}/${quiz[currentQuestion].id}`,
        {
          choosen: selectedAnswer,
        }
      );
      // console.log(response.data); 
  
      const solved = Object.values(selectedAnswersCopy).filter(
        (answer) => answer && answer !== "0"
      ).length;
      const unsolved = quiz.length - solved;
      setSolvedQuestions(solved);
      setUnsolvedQuestions(unsolved);
    } catch (e) {
      // console.log(e.response);
    }
  };

//   const [answeredQuestions, setAnsweredQuestions] = useState([]);

//   const handleAnswerSelected = (e) => {
//     const selectedAnswer = e.target.value;
//     const selectedAnswersCopy = { ...selectedAnswers };
//     selectedAnswersCopy[currentQuestion] = selectedAnswer;
//     setSelectedAnswers(selectedAnswersCopy);
//     setChoosen(selectedAnswer);
//     setAnsweredQuestions({ ...answeredQuestions, [currentQuestion]: true });
//   };



//  const handleSubmitQuestion = async (e, question_id, callbackFunction) => {
//     e.preventDefault();

//     try {
//       const response = await axiosInstance.post(
//         `/api/submit-quiz-question/${id}/${question_id}`,
//         {
//           choosen,
//         }
//       );
      
      
//       setChoosen("");
//       if (callbackFunction) {
//         callbackFunction(e);
//       }
//       let solved = 0;
//       for (const key in selectedAnswers) {
//         if (selectedAnswers[key] && selectedAnswers[key] !== "0") {
//           solved++;
//         }
//       }
//       console.log(choosen);
//       console.log(question_id);
//       const unsolved = quiz.length - solved;
//       setSolvedQuestions(solved);
//       setUnsolvedQuestions(unsolved);
//     } catch (e) {
//       // console.log(e.response);
//     }
//   };
  

  function CountdownTimer({ remainingTime }) {
    const [timeLeft, setTimeLeft] = useState(remainingTime);

    useEffect(() => {
      const remainingTime = time.message;
      const timer = setTimeout(() => {
        if (remainingTime <= 0) {
          navigate(`/modelanswerquiz/${id}`);
        } else {
          setTime({ message: remainingTime - 1 });
        }
      }, 1000);
      return () => clearTimeout(timer);
    }, [time, currentQuestion]);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    return (
      <div>
        {hours} :{minutes < 10 ? "0" : ""} {minutes} : {seconds < 10 ? "0" : ""}{" "}
        {seconds}
      </div>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="main-loading">
          <div className="loading-course">
            <h2> .... جاري تحميل بيانات الامتحان</h2>
          </div>
          ;
        </div>
      ) : (
        <div class="quiz-main pt-5">
          <div className="register-button mb-4 pt-4">
            <button class="register-btn" role="button">
              {" "}
              ركز شوية{" "}
            </button>
          </div>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="quiz-app ">
                  <div className="quiz-data">
                    <div className="timer">
                      <span>
                        {" "}
                        : باقي من الزمن <img
                          src="/images/clock.png"
                          alt=""
                        />{" "}
                      </span>
                      <span>
                        <CountdownTimer remainingTime={time.message} />
                      </span>
                    </div>
                    <div className="question-tabs">
                      {quiz.map((q, index) => (
                        <div
                          key={q.id}
                          className={`question-tab ${
                            currentQuestion === index ? "active" : ""
                          } ${answeredQuestions[index] ? "answered" : ""}`}
                          onClick={(e) =>{
                            setCurrentQuestion(index);
                            // handleSubmitQuestion(e, q.id);
                          }}
                        >
                          {index + 1}
                        </div>
                      ))}
                    </div>
                    <div className="quiz-info">
                      <div className="content">
                        <p> : اجمالي عدد الأسئلة </p>
                        <span className="length">{quiz.length}</span>
                      </div>
                      <div className="content">
                        <p>: عدد الأسئلة المحلولة </p>
                        <span className="solved">
                          {" "}
                          {solvedQuestions ? solvedQuestions : "0"}
                        </span>
                      </div>
                      <div className="content">
                        <p> : عدد الأسئلة الغير محلولة </p>
                        <span className="unsolved">
                          {unsolvedQuestions ? unsolvedQuestions : "0"}
                        </span>
                      </div>
                    </div>

                    <div className="quiz-control">
                      <button
                        className="prev"
                        onClick={
                            handlePrevQuestion
                          
                        }
                        disabled={currentQuestion === 0}
                      >
                        السابق
                      </button>


                      {/* <button
                        className="prev"
                        onClick={(e) =>
                          handleSubmitQuestion(
                            e,
                            quiz[currentQuestion].id,
                            handlePrevQuestion
                          )
                        }
                        disabled={currentQuestion === 0}
                      >
                        السابق
                      </button> */}


                      <button
                        className="next"
                        onClick={
                            handleNextQuestion
                          
                        }
                        disabled={currentQuestion === quiz.length - 1}
                      >
                        التالي
                      </button>
                    </div>
                    <div className="quiz-finish">
                      {currentQuestion === quiz.length - 1 && (
                        <button
                          type="submit"
                          className="finish"
                          onClick={(e) =>
                            handleFinish(e) 
                            // handleSubmitQuestion(e, quiz[currentQuestion].id)
                          }
                        >
                          انهي الإمتحان
                        </button>
                      )}
                    </div>
                  </div>

                  {quiz.length > 0 && (
                    <form className="form-quiz">
                      <div></div>
                      <div className="exam">
                        <p class="arrange">
                          {" "}
                          رقم السؤال : <span>{currentQuestion + 1}</span>
                        </p>

                        <h3 className="question">
                          <span>
                            {" "}
                            <img
                              src="/images/problem.png"
                              className="icon-question"
                              alt=""
                            />
                          </span>
                          {quiz[currentQuestion].question}
                        </h3>
                        <img
                          src={`${Image_URL}/storage/${quiz[currentQuestion].img}`}
                          className="img-question"
                          alt=""
                        />
                        <div className="answers d-flex">
                          {quiz[currentQuestion].c1 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="1"
                                checked={
                                  selectedAnswers[currentQuestion] === "1"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {quiz[currentQuestion].c1}
                              <img
                                src={`${Image_URL}/storage/${quiz[currentQuestion].c1_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}

                          {quiz[currentQuestion].c2 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="2"
                                checked={
                                  selectedAnswers[currentQuestion] === "2"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {quiz[currentQuestion].c2}
                              <img
                                src={`${Image_URL}/storage/${quiz[currentQuestion].c2_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}

                          {quiz[currentQuestion].c3 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="3"
                                checked={
                                  selectedAnswers[currentQuestion] === "3"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {quiz[currentQuestion].c3}
                              <img
                                src={`${Image_URL}/storage/${quiz[currentQuestion].c3_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}

                          {quiz[currentQuestion].c4 === null ? (
                            ""
                          ) : (
                            <label>
                              <input
                                type="checkbox"
                                name="choosen"
                                value="4"
                                checked={
                                  selectedAnswers[currentQuestion] === "4"
                                }
                                onChange={(e) =>
                                  handleAnswerSelected(e) |
                                  setChoosen(e.target.value)
                                }
                              />
                              {quiz[currentQuestion].c4}
                              <img
                                src={`${Image_URL}/storage/${quiz[currentQuestion].c4_img}`}
                                className="img-question"
                                alt=""
                              />
                            </label>
                          )}
                        </div>
                      </div>
                      <br />
                    </form>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
export default Quiz;
