import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../styles/addquiz.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../App";






function Addquiz () {
  
  const [week_id, setWeek_id] = useState("");
  const [title, setTitle] = useState("");
  const [cdn, setCdn] = useState("");
  const [minutes, setMinutes] = useState("");
  const [degree, setDegree] = useState("");
  const [mindegree, setMindegree] = useState("");
  const [noquestions , setNoQuestions] = useState("");
  const [prize , setPrize] = useState("");
  const [prizeDegree , setPrizeDegree] = useState("");
  const [start , setStart] = useState("");
  const [end , setEnd] = useState("");
  const [level , setLevel] = useState("");
  const [answerTime , setAnswerTime] = useState("");
  const [errors , setErrors] = useState("");
  const navigate = useNavigate();
  const [file , setFile] = useState([]);

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
});


  const handleAddQuiz = async (event) => {
    event.preventDefault();
    // await csrf();
    try {
        await axiosInstance.post("/api/quizzes" , {
          week_id , 
          title ,
          cdn, 
          minutes , 
          degree , 
          mindegree,
          prize,
          prizeDegree,
          noquestions,
          answerTime,
          start,
          end,
          level,
             
        });
        setWeek_id("");
        setTitle("");
        setCdn("");
        setMinutes("");
        setDegree("");
        setMindegree("");
        setPrize("");
        setPrizeDegree("");
        setNoQuestions("");
        setAnswerTime("");
        setStart("");
        setEnd("");
        setLevel("");

        swal({
            title: "تم إضافة الكويز بنجاح!",
            icon: "success",
            button: "حسناّ!",
          });
          navigate("/admin/quizdata")
          // console.log();
    } catch(e) {
        if(e.response.status === 500) {
            // setErrors(e.response.data.errors);
            // console.log(e);   
        }
        setErrors(e.response.data.errors);

    }
}
 
const getFile = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/api/weeks-selection' , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data);
      setFile(response.data.data)
      const data = response.data.data;
               return data;
  } catch (error) {
      // console.log(error);
  }
}

useEffect (() =>{
  getFile();
}, [])



    return (
        <>
                  <div className="d-flex">
    
       <div className="add-quiz ">
      
      <div className="main-title"> اضافة الكويز </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                       <div className="codes pt-5">
                      
                        <div className="create-box">
                        <h2 className="create mb-4">  اضافة الكويز </h2>
                        <Form onSubmit={handleAddQuiz}>
        

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText  ">
        <Form.Label className="ms-2">اسم الكويز</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الكويز" name="title" onChange={(e)=>{setTitle(e.target.value)}}/>
        {errors.title && <div className="error mt-3">{errors.title[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> وقت الكويز</Form.Label>
        <Form.Control type="number" placeholder="   ادخل وقت الكويز بالدقائق  " name="minutes" onChange={(e)=>{setMinutes(e.target.value)}}/>
        {errors.minutes && <div className="error mt-3">{errors.minutes[0]}</div>}

      </Form.Group>
      </div>

     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> الدرجة الصغري</Form.Label>
        <Form.Control type="number" placeholder=" ادخل   الدرجة الصغري للكويز  " name="mindegree" onChange={(e)=>{setMindegree(e.target.value)}}/>
        {errors.minutes && <div className="error mt-3">{errors.minutes[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 group " controlId="formBasicText  ">
        <Form.Label className="ms-2"> الدرجة العظمي</Form.Label>
        <Form.Control type="number" placeholder=" ادخل الدرجة العظمي للكويز " name="degree" onChange={(e)=>{setDegree(e.target.value)}}/>
        {errors.degree && <div className="error mt-3">{errors.degree[0]}</div>}
      </Form.Group>
      </div>
     {/* <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> درجة الكويز</Form.Label>
        <Form.Control type="number" placeholder="ادخل درجة الكويز" name="degree" onChange={(e)=>{setDegree(e.target.value)}}/>
      </Form.Group>
      <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2">  الدرجة الصغري</Form.Label>
        <Form.Control type="number" placeholder="ادخل الدرجة الصغري" name="mindegree" onChange={(e)=>{setDegree(e.target.value)}}/>
      </Form.Group>
      </div> */}
     <div className="chooices d-lg-flex align-items-center">

           <Form.Group className="mb-3 me-lg-4 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> عدد الأسئلة</Form.Label>
        <Form.Control type="number" placeholder="ادخل عدد الأسئلة"  name="noquestions" onChange={(e)=>{setNoQuestions(e.target.value)}}/>
        {errors.noquestions && <div className="error mt-3">{errors.noquestions[0]}</div>}
      </Form.Group>
      <Form.Group controlId="formFile" className="group mt-3">
      <Form.Select aria-label="Default select example" name="answerTime" onChange={(e) =>setAnswerTime(e.target.value)}>
      <option> اختر معاد ظهور  الإجابات</option>
      <option value="0">بعد   انهاء الطالب للامتحان فورا</option>
      <option value="1">  بعد وقت الكويز كامل</option>
    </Form.Select>
          {errors.answerTime && <div className="error mt-3">{errors.answerTime[0]}</div>}

      </Form.Group>
      </div>

      <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> جائزة الأوائل</Form.Label>
        <Form.Control type="text" placeholder=" ادخل جائزة الأوائل  " name="prize" onChange={(e)=>{setPrize(e.target.value)}}/>
        {errors.prize && <div className="error mt-3">{errors.prize[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 group " controlId="formBasicText  ">
        <Form.Label className="ms-2"> الحد الأدني لدرجة الأوائل</Form.Label>
        <Form.Control type="text" placeholder=" ادخل الحد الأدني لدرجة الأوائل " name="prizeDegree" onChange={(e)=>{setPrizeDegree(e.target.value)}}/>
        {errors.prizeDegree && <div className="error mt-3">{errors.prizeDegree[0]}</div>}
      </Form.Group>
      </div>
      
     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ البداية</Form.Label>
        <Form.Control type="datetime-local"   name="start" onChange={(e)=>{setStart(e.target.value)}}/>
        {errors.start && <div className="error mt-3">{errors.start[0]}</div>}
      </Form.Group>
     <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ النهاية</Form.Label>
        <Form.Control type="datetime-local"  name="end" onChange={(e)=>{setEnd(e.target.value)}}/>
        {errors.end && <div className="error mt-3">{errors.end[0]}</div>}
      </Form.Group>
      </div>
   
     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
     <Form.Select aria-label="Default select example" name="week_id" onChange={(e) => setWeek_id(e.target.value)}> 
      <option>اختر رقم الحصة  </option>
      {
        file.map(item => (
          <option key={item.id} value={item.id} name="week_id" onChange={(e) => setWeek_id(e.target.value)}>{item.name}</option>
        ))
      }
    </Form.Select>
    {errors.week_id && <div className="error mt-3">{errors.week_id[0]}</div>}

      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3 group ">
      <Form.Select aria-label="Default select example" name="level" onChange={(e) =>setLevel(e.target.value)}>
      <option> اختر السنه الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
          {errors.level && <div className="error mt-3">{errors.level[0]}</div>}

      </Form.Group>
      </div>
   
   


   
  
    
              
      <Button variant="primary" type="submit" className="create-button">
        اضف
      </Button>
    </Form>
                        </div>
                  
                        </div> 
              </div>
                   </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
        
    )
}

export default Addquiz;


