import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link, useParams } from "react-router-dom";
import '../../styles/profile.css' 
import axios from "axios";
import { BASE_URL } from "../../App";
import SidebarProfileWithAdmin from "./SidebarProfileWithAdmin";


function StudentStatistic (props) {
    const {id} = useParams()
      const [authDetails, setAuthDetails] = useState([]);
        const [myStats, setMyStats] = useState([]);

     

      useEffect(() => {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
            'Authorization': `Bearer ${token}`,
          }
        };
        axios.get(`${BASE_URL}/api/user-statistics/${id}`, config)
          .then(response => {
            setAuthDetails(response.data[0])
            // console.log(response.data);
            setMyStats(response.data);
          })
          .catch(error => {
            // console.error(error);
          });
      }, []);


  
      let percentageQuiz = 0;
      if (myStats && myStats.numOfAllQuizzes !== 0) {
          percentageQuiz = Math.round((myStats.numOfMyQuizzes / myStats.numOfAllQuizzes) * 100);
      }
      
      let percentageVideo = 0;
      if (myStats && myStats.numberOfVideos !== 0) {
          percentageVideo = Math.round((myStats.viewedVideos / myStats.numberOfVideos) * 100);
      }
      
      let percentageDegreess = 0;
      if (myStats && myStats.standardDegree !== 0) {
        percentageDegreess = Math.round((myStats.myDegrees / myStats.standardDegree) * 100);
      }

    return (
        <>
        <div className="profile">
        <div className="profile-ground">
                                <img src="/images/bg.3b55416e926d51a05f75.png" alt=""/> 
                        </div>
            <Container>
                <Row>
                    <Col lg={12}>
                        
                        <div className="profile-user pt-5">
                        <div className="title-profile mt-5 mb-5">
                  <button class="button-prof" role="button">  ملف الطالب الشخصي </button>
                       </div>                               
                         <Container>
                                <Row>
                                    <Col lg={8}>
                                  <div className="main-info">
                                  <div className="info">
                                            <h2 className="mt-4">{authDetails.fname +' '+authDetails.lname}</h2>
                                            <span className="line"></span>
                                            <span className="number d-block">  {authDetails.email} <i className="fa-solid fa-phone ms-2"></i></span>
                                            <span className="email d-block mt-2">{authDetails.phone} <i className="fa-solid fa-envelope ms-2"></i> </span>
                                            <span className="email d-block mt-2">Id : {authDetails.id} </span>

                                        </div>
                                        <div className="info-course">
                                            <span className="line"></span>
                                       <div className="about-course">
                                       <h2 className="analysis-name mb-5">احصائيات <span>كورساتك</span></h2>
                                        <div className="Analysis d-flex align items center">
                                            <Row>

                                            <Col lg={4}>
                                                <div className="box-3">
                                            <CircularProgressbar value={percentageDegreess} text={`${percentageDegreess}%`} />
                                            </div>
                                            <div className="analysis-text"> متوسط درجاتك في الكويزات </div>
                                            <div className="wallet d-flex">
                                                  <div > {myStats.standardDegree}  </div> 
                                                        <div className="name"> من </div>                 
                                                  <div className="num-total"> {myStats.myDegrees}  </div> 
                                                        </div>
                                                </Col>
                                         
                                                <Col lg={4}>
                                                <div className="box-2">
                                            <CircularProgressbar value={percentageQuiz} text={`${percentageQuiz}%`} />
                                            </div>
                                            <div className="analysis-text">عدد الكويزات اللي حليتها</div>
                                            <div className="wallet d-flex mt-4">
                                                  <div> {myStats.numOfAllQuizzes}  </div> 
                                                        <div className="name"> من </div>                 
                                                  <div  className="num-quiz"> {myStats.numOfMyQuizzes}  </div> 
                                                        </div>
                                                </Col>
                                                
                                                <Col lg={4}>
                                                <div className="box-1">
                                            <CircularProgressbar value={percentageVideo} text={`${percentageVideo}%`} />
                                            </div>
                                            <div className="analysis-text">عدد الفديوهات اللي شوفتها</div>
                                            <div className="wallet d-flex mt-1">
                                                  <div> {myStats.numberOfVideos}  </div> 
                                                        <div className="name"> من </div>                 
                                                  <div className="num-video" > {myStats.viewedVideos}  </div> 
                                                        </div>
                                                </Col>
                
                                            </Row>
                                            
                                            {/* <div className="box-2">
                                            <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                            </div>
                                            <div className="box-3">
                                            <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                            </div> */}
                                        </div>
                                    
                                       {/* <span className="line"></span> */}
                                   
                                       {/* <h2 className="analysis-name mt-5">معلومات عن  <span>الطالب</span></h2> */}
                                                            
                                       </div>
                                    <div className="analysis-platform mb-5 mt-5">
                                    <h2 className="analysis-name mb-5">احصائياتك علي  <span>المنصة</span></h2>

                                        <div className="box">
                                            <h3>عدد الحصص اللي اشترتها</h3>
                                            <span className="one">{myStats.numOfMyWeeks}</span>
                                            </div>

                                            <span className="line"></span>
                                        <div className="box">
                                            <h3>عدد الأسئلة في الكويزات والواجبات اللي انت حليتها</h3>
                                            <span className="two">{myStats.allSolvedQuizzes}</span>
                                            </div>

                                            <span className="line"></span>
                                        <div className="box">
                                        <h3>عدد الفديوهات اللي  شوفتها </h3>

                                            <span className="three">{myStats.viewedVideos}</span>
                                            </div>
                                            <span className="line"></span>
                                        <div className="box">
                                            <h3>عدد مرات فتح الفديوهات </h3>
                                            <span className="one">{myStats.numberOfWatchingVideos}</span>
                                            </div>      

                                            <span className="line"></span>
                                            <div className="box">
                                            <h3>عدد الأكواد اللي انت استخدمتها</h3>
                                            <span className="four">{myStats.numberOfCodes}</span>
                                            </div> 

                                            <span className="line"></span>
                                    </div> 
                                        </div>
                                  </div>
                                    </Col>
                                    <Col lg={4}>
                                        <SidebarProfileWithAdmin />
                                    </Col>
                                </Row>
                                </Container>
                            </div>
                    </Col>
                </Row>

            </Container>
        </div>
        </>
    )
}
export default StudentStatistic;