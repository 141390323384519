import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


const currentYear = new Date().getFullYear();


function Footer () {
    return (
        <>
            <footer className="footer">
                <Container>
                    <Row>
                        <Col lg={12}>
                        <div className="wrapper d-flex justify-content-center align-items-center mt-5 pt-3">
             
               <div className="button">
            <div className="icon">
               <i className="fab fa-facebook-f"></i>
            </div>
            <Link to="https://www.facebook.com/profile.php?id=100063107442579" target="_blank">
               <span>Facebook</span>
               </Link>
         </div>
              
         <div className="button">
            <div className="icon">
               <i className="fab fa-twitter"></i>
            </div>
            <span>Twitter</span>
         </div>
         <div className="button">
            <div className="icon">
               <i className="fab fa-instagram"></i>
            </div>
            <span>Instagram</span>
         </div>
      
        <div className="button">
            <div className="icon">
               <i className="fab fa-youtube"></i>
            </div>
          
        <Link to="https://www.youtube.com/@user-nt1qn3yk3l">
        <span className>YouTube</span>
        </Link>
         </div>
        
                      </div>
                      <h1>اللهم إنا نسألك علماَ نافعاَ ورزقاَ طيباَ وعملاَ متقبلاَ</h1>
                      <div className="develop text-center mt-5  mb-5 pb-3">
                        <span>Developed by</span> <Link to="https://www.facebook.com/said.sayed.3386"  target="_blank" alt="">Said</Link> , <Link to="https://www.facebook.com/ahmed.abdelhamidnassef" target="_blank" alt="">Nassef</Link> <span>All Copy Rights Reserved &copy; {currentYear}</span>
                      </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}


export default Footer;