import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import '../../styles/allstdudentdata.css'
import '../../styles/table.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { BASE_URL, tableCustomStyles } from "../../App";


function AllStudentData() {



  const {id} = useParams();

    const [name, setName] = useState([]);
    const [ role , setRole] = useState("")
  

      
   
  
      useEffect(() => {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
            'Authorization': `Bearer ${token}`,
          }
        };
        axios.get(`${BASE_URL}/api/Auth`, config)
          .then(response => {
            setRole(response.data.role)
            setName(response.data);
          })
          .catch(error => {
            // console.error(error);
          });
      }, [role]);

      const roled = localStorage.getItem("sha1");


  const [student , setStudent] = useState([]);
  
  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
        Authorization: `Bearer ${token}`,
    },
});




const getStudent = async () => {
    try {

      const response = await axiosInstance.get('/api/user');
        // console.log(response.data.data);
        setStudent(response.data.data)
        const data = response.data.data;
                 return data;
    } catch (error) {
        if(error.response.status === 404) {
          // console.log(error.response.status);
        }
    }
}

useEffect (() =>{
  getStudent();
}, [])


const [rows, setRows] = useState([]);

const deleteRow = async(id, token) => {
  try {
      const response = await axios.delete(`${BASE_URL}/api/user/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
  } catch (error) {
      // console.error(error);
  }
};

const blockUser = async(id, token) => {
  try {
      const response = await axios.get(`${BASE_URL}/api/approve-user/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "any",
          },
      });
  } catch (error) {
      // console.error(error);
  }
};

const unBlockUser = async(id, token) => {
  try {
      const response = await axios.get(`${BASE_URL}/api/disapprove-user/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "any",
          },
      });
  } catch (error) {
      // console.error(error);
  }
};

const handleDeleteClick = async (id) => {
  const token = localStorage.getItem('token');
  try {
      await deleteRow(id, token);
      const updateQuiz = student.filter(row => row.id !== id);
      setStudent(updateQuiz);
  } catch (error) {
      // console.error(error);
  }
};


const handleBlockClick = async (id) => {
  const token = localStorage.getItem('token');
  // console.log(id);
  blockUser(id, token);
  const updatedStudent = student.map(row => {
    if (row.id === id) {
      return { ...row, attributes: { ...row.attributes, approved: "0" } };
    } else {
      return row;
    }
  });
  setStudent(updatedStudent);
};


const handleUnblockClick = async (id) => {
  const token = localStorage.getItem('token');
  // console.log(id);
  unBlockUser(id, token);
  const updatedStudent = student.map(row => {
    if (row.id === id) {
      return { ...row, attributes: { ...row.attributes, approved: "1" } };
    } else {
      return row;
    }
  });
  setStudent(updatedStudent);
};

const [updatedBalance, setUpdatedBalance] = useState(0);
const [value, setValue] = useState("");
const [errors , setErrors] = useState("")
  const navigate = useNavigate()
const handleCharge = async (event , id) => {
  event.preventDefault();
  try {
    const response =  await axiosInstance.post(`/api/charge-with-admin/${id}`, {
        value         
      });
      
      setValue("")
      const updatedStudent = student.map((s) => {
        if (s.id === id) {
          return {
            ...s,
            attributes: {
              ...s.attributes,
              balance: response.data.data.balance,
            },
          };
        }
        return s;
      });
      setStudent(updatedStudent);
      
      swal({
          title: "تم الشحن بنجاح!",
          icon: "success",
          button: "حسناّ!",
        });
        navigate("/admin/allstudentdata")
  } catch(e) {
      if(e.response.status === 500) {
          setErrors(e.response.data.errors);
          console.log(e);  
          console.log(id); 
      }
  }
}


const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = student.filter((item) => {
    return (
      item.attributes.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.id.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.attributes.email.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
  });
  setFilteredData(searchResults);
};


  
    const columns = [
        {
            name: "ID ",
            selector: (row) => row.id,
            sortable : true,
          },
      {
        name: "Name",
        selector: (row) => row.attributes.name,
        sortable : true,
      grow:5,
      },
      {
        name: "Phone",
        selector: (row) => row.attributes.email,
        sortable : true,
 
      grow:5,
      },
      {
        name: "Parent Phone",
        selector: (row) => row.attributes.pphone,
        sortable : true,
        grow:5,

      },
      {
        name: "Level",
        selector: (row) => row.attributes.level,
        sortable : true,
    
      },
      {
        name: "Balance",
        selector: (row) => row.attributes.balance,
        sortable : true,
    
      },
      {
        name: "Approved",
        selector: (row) => (row.attributes.approved === "1") ? "Avaliable" : "Blocked",
        sortable : true,
    
      },
  
  
      {
        
        name: "Charge",
        cell: row => role > 1
        ?

        <Form className="d-flex align-items-center mt-3" onSubmit={(e)=>{handleCharge(e , row.id)}} >
          <Form.Group className="mb-3 w-100" controlId="inputcharge">
        <Form.Control type="number" placeholder=" price" name="value" onChange={(e) => {setValue(e.target.value)}}/>
      </Form.Group>
      <Button variant="primary" className="charge ps-0 pe-0" type="submit">
            Charge      
</Button>
        </Form>
        :
        "",
        grow:10,
      },
      {
        name: "Profile",
        cell: row =>  <Link to={`/admin/studentprofile/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-danger pe-2 ps-2" >Profile</button>
        </Link>,
        grow:5,
      },
      {
        name: "Status",
        cell: row =>  <button style={{background:"#28a745" , border: "none"}} className="btn btn-success pe-3 ps-3" onClick={() => alert("هل تريد وقف حساب هذا الطالب") | handleBlockClick(row.id)}>Block</button>,
        grow:5,
      },
    
      {
        name: "Status",
        cell: row => <button style={{background:"" , border: "none"}} className="btn btn-secondary pe-1 ps-1" onClick={() => alert("هل تريد تنشيط هذا الحساب") | handleUnblockClick(row.id)}>UnBlock</button>,
        grow:5,
      },
    
      {
        name: "Edit",
        cell: row => <Link to={`/admin/editstudent/${row.id}`}>
        <button className="btn btn-warning ps-lg-3 pe-lg-3">Edit</button>
        </Link>,
          grow:2,
      },

      {
        name: "Delete",
        cell: row => <button style = {
            { background: "#", border: "none" }
        }
        className = "btn btn-danger ps-2 pe-2"
        onClick = {() => alert('هل انت متأكد من حذف هذا الطالب') | handleDeleteClick(row.id)} > Delete </button>,
        grow:3,
    },
     
    ];
   
    
    return (
        <>
      <div className="d-flex">
    
       <div className="allstudent-admin ">
      
      <div className="main-title"> بيانات الطلاب </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                             customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : student} 
                pagination 
                fixedHeader
                defaultSortFieldId
                // pagination={5}
                // selectableRows
                // fixedHeaderScrollHeight="500px"
                highlightOnHover
             
                // actions={
                //     <button className="btn btn-info">Export</button>
                // }
                subHeader
                subHeaderComponent={
                  <input type="text" className="form-control mt-4 mb-4" placeholder="Search of Student or Id" onChange={handleSearch} />
                
                }
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default AllStudentData;