



import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import SidebarAdmin from "./SidebarAdmin";
import '../styles/videodata.css'
import SidebarAdminMain from "./SidebarAdminMain";
import swal from "sweetalert";
import { BASE_URL, tableCustomStyles } from "../App";







function ShowLevelQuiz() {
  
  const navigate = useNavigate();
    const {id} = useParams()
  const [quiz , SetQuiz] = useState([]);
  

  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});



const getLevelQuiz = async () => {
    try {
      const token = localStorage.getItem('token' );
      const response = await axiosInstance.get(`/api/show-level-quizzes/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        SetQuiz(response.data.data)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
    getLevelQuiz();
}, [])


const [rows, setRows] = useState([]);

const deleteRow = async(id, token) => {
  try {
      const response = await axios.delete(`${BASE_URL}/api/quizzes/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      swal({
        title: "تم حذف الكويز  بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
      // navigate("/admin/quizdata")
  } catch (error) {
      // console.error(error);
  }
};

const handleDeleteClick = async (id) => {
  const token = localStorage.getItem("token");
  try {
  const confirmDelete = await swal({
  title: "هل أنت متأكد من حذف هذا الكويز",
  icon: "warning",
  buttons: ["لا", "نعم"],
  dangerMode: true,
  });
  if (confirmDelete) {
    await deleteRow(id, token);
      const updateQuiz = quiz.filter(row => row.id !== id);
      SetQuiz(updateQuiz);
  }
  } catch (error) {
  // console.error(error);
  }
  };
;

   
const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = quiz.filter((item) => {
    return (
      item.attributes.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
  });
  setFilteredData(searchResults);
};


  
    const columns = [
 
      {
        name: "ID ",
        selector: (row) => row.id,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",

      },
  
      {
        name: "Week_Id ",
        selector: (row) => row.attributes.week_id,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }

      },
      {
        name: "Name ",
        selector: (row) => row.attributes.title,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },
  
      
      {
        name: "Time",
        selector: (row) => row.attributes.minutes,
      
      },
      {
        name: "Degree ",
        selector: (row) => row.attributes.degree,
        // grow:3,
      },
      {
        name: "Minimum Degree",
        selector: (row) => row.attributes.mindegree,
        // grow:3,
      },
     
      {
        name: "Start Date",
        selector: (row) => row.attributes.start,
        grow:3,
      },
      {
        name: "End Date",
        selector: (row) => row.attributes.end,
        grow:3,
      },
      {
        name: "Level",
        selector: (row) => row.attributes.level,
        // grow:3,
      },
     
      // {
      //   name: "Country Flag",
      //   selector: (row) => <img width={10} height={10} src={row.flag} alt="" />,
      // },
      {
        name: "Questions",
        cell: row => <Link to={`/admin/questionselector/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-secondary pe-1 ps-1 pt-2 pb-2">Questions</button>,
        </Link>,
      grow:3,
        
      },
      {
        name: "Edit",
        cell: row => <Link to={`/admin/editquiz/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-warning pe-3 ps-3 pt-2 pb-2">Edit</button>,
        </Link>

        
      },
      {
        name: "Students",
        cell: row => <Link to={`/admin/quizstudents/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-primary pe-2 ps-2 pt-2 pb-2">Students</button>,
        </Link>,
          grow:3,
      },
      {
        name: "Delete",
        cell: row => <button style={{background:"#" , border: "none"}} className="btn btn-danger pe-3 ps-3 pt-2 pb-2" onClick={() =>handleDeleteClick(row.id)}>Delete</button> ,
    
        grow:3,   // grow:2,
    },
     
    ];
    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  بيانات الكويزات  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                                           customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : quiz} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                  <Link to="/admin/addquiz">
                                       <button className="btn btn-primary mt-4 mb-4 fs-5 fw-bold pt-2 pb-2 pe-4 ps-4 me-2">اضافة كويز</button>   
                  </Link>
               }
                subHeader
                subHeaderComponent={
                  <input type="text" className="form-control mt-4 mb-4" placeholder="Search of Quiz" onChange={handleSearch} />

                }
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default ShowLevelQuiz;