
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import '../../styles/videodata.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { BASE_URL, tableCustomStyles } from "../../App";







function Adminhomework() {
  
  const [homework , setHomework] = useState([]);
  
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        'Content-Type': 'application/json',
        "ngrok-skip-browser-warning": "any",
    },
});

const getHomeWork = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/api/homeworks' , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data);
        setHomework(response.data.data)
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
  getHomeWork();
}, [])






const [rows, setRows] = useState([]);

const deleteRow = async(id, token) => {
  try {
      const response = await axios.delete(`${BASE_URL}/api/homeworks/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
  } catch (error) {
      // console.error(error);
  }

};
const handleDeleteClick = async (id) => {
  const token = localStorage.getItem('token');
  try {
      await deleteRow(id, token);
      const updateQuestion = homework.filter(row => row.id !== id);
      setHomework(updateQuestion);
  } catch (error) {
      // console.error(error);
  }
};



const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");

const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = homework.filter((item) => {
    return (
      item.attributes.title.toLowerCase().includes(e.target.value.toLowerCase()) |
      item.attributes.level.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
  });
  setFilteredData(searchResults);
};


    const columns = [
 
      {
        name: "ID ",
        selector: (row) => row.id,
        sortable : true,
      },
  
      {
        name: "Title ",
        selector: (row) => row.attributes.title,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:6,
      },
      {
        name: "Degree ",
        selector: (row) => row.attributes.degree,
        sortable : true,
      },
      {
        name: "MinDegree ",
        selector: (row) => row.attributes.mindegree,
        sortable : true,
        grow:3,
      },
      {
        name: "Time ",
        selector: (row) => row.attributes.minutes,
        sortable : true,
        grow:3,
      },
      {
        name: "Level ",
        selector: (row) => row.attributes.level,
        sortable : true,
  
      },
      {
        name: "Week_ID ",
        selector: (row) => row.attributes.week_id,
        sortable : true,

      },
      {
        name: "Created_At ",
        selector: (row) => row.attributes.created_at,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },
      {
        name: "Updated_At ",
        selector: (row) => row.attributes.updated_at,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },
      {
        name: "Questions",
        cell: row => <Link to={`/admin/questionselectorhomework/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-secondary pe-1 ps-1 pt-2 pb-2">Questions</button>,
        </Link>,
      grow:3,
        
      },
  
      {
        name: "Edit",
        cell: row => <Link to={`/admin/edithomework/${row.id}`}>
        <button style={{background:"#" , border: "none"}} className="btn btn-warning pe-3 ps-3 pt-2 pb-2">Edit</button>,
        </Link>

        
      },
      {
        name: "Student",
        cell: row => <Link to={`/admin/studenthomework/${row.id}`}>
         <button style={{background:"#" , border: "none"}} className="btn btn-primary pe-lg-2 ps-lg-2 ps-1 pe-1 pt-2 pb-2">Student</button>,
        </Link>,
          grow:3
        
      },
      {
        name: "Delete",
        cell: row => <button style={{background:"#" , border: "none"}} className="btn btn-danger pe-lg-3 ps-lg-3 ps-2 pe-2 pt-2 pb-2" onClick={() => alert('هل انت متأكد من حذف هذا الواجب') | handleDeleteClick(row.id)}>Delete</button> ,
        grow:3,
    },
     
    ];

    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  بيانات الواجبات  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                             customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : homework} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                  <Link to="/admin/addhomework">
                                       <button className="btn btn-primary mt-4 mb-4 fs-5 fw-bold pt-2 pb-2 pe-4 ps-4 me-2">اضافة واجب</button>   
                  </Link>
               }
                subHeader
                subHeaderComponent={
                  <input type="text" className="form-control mt-4 mb-4" placeholder="Search of Homework" onChange={handleSearch} />

                }
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default Adminhomework;