
import axios from "axios";
import { useEffect, useId, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import '../../styles/videodata.css'
import SidebarAdminMain from "../SidebarAdminMain";
import { CSVLink } from "react-csv";
import swal from "sweetalert";
import { BASE_URL, tableCustomStyles } from "../../App";


function StudentVideo() {
    

    const navigate = useNavigate();
  
  
  const [student , setStudent] = useState([]);
  
    const {id} = useParams()
    // console.log(id);

    const token = localStorage.getItem('token' );
    const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
  
});



const getVideoStudent = async () => {
    try {
      const token = localStorage.getItem('token' );
      const response = await axiosInstance.get(`/api/getVideoWatchers/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
        });
        setStudent(response.data.data)

    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
    getVideoStudent();
}, [])



let totalStudent = student.length

const ClearRow = async(userId, token , row) => {
  try {
      const response = await axios.get(`${BASE_URL}/api/clearUserWatches/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
      }
      ) ;
      swal({
        title: "تم اعادة مشاهدات الفيديو للطالب  بنجاح!",
        icon: "success",
        button: "حسناّ!",
      });
  } catch (error) {
      if(error.response.status === 500) {
        console.log(error.response);
        console.log(userId);

      }
  }

};
const handleClearClick = async (id) => {
  const token = localStorage.getItem('token' );
  try {
      await ClearRow(id, token);
      const updateStudentQuiz = student.filter(row => row.id !== id);
      setStudent(updateStudentQuiz);
      // console.log(id);
  } catch (error) {
    if(error.response.status === 500) {
      console.log(error.response);  
    }
    }
};






const [filteredData, setFilteredData] = useState([]);
const [searchTerm, setSearchTerm] = useState("");



const handleSearch = (e) => {
  setSearchTerm(e.target.value);
  const searchResults = student.filter((item) => {
    return (
      item.attributes.user_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.id.toLowerCase().includes(e.target.value.toLowerCase()) 
    );
  });
  setFilteredData(searchResults);
};





    const columns = [
 
      {
        name: "ID ",
        selector: (row) => row.id,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",

      },
      {
        name: "Name ",
        selector: (row) => row.attributes.user_name,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },
      {
        name: "Count ",
        selector: (row) => row.attributes.count,
        sortable : true,
      //   style: {
      //     background: "#e6e6e6",
      //   }
      grow:2,
      },


      {
        name: "Clear",
        cell: row =>  <button style={{background:"#" , border: "none"}} className="btn btn-success pe-lg-2 ps-lg-2 pe-2 ps-2 pt-2 pb-2" onClick={() => alert('هل انت متأكد من اعادة الفيديو لهذا الطالب  ') | handleClearClick(row.id)}>Clear</button> ,
        
      },

     
    ];
    
    return (
        <>
      <div className="d-flex">
    
       <div className="video-data">
      
      <div className="main-title">  الطلاب المشاهدين للفيديو </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
              <DataTable
                customStyles={tableCustomStyles}

               columns={columns}
               data={filteredData.length > 0 ? filteredData : student} 
                pagination 
                fixedHeader
                // selectableRows
                // fixedHeaderScrollHeight="400px"
                highlightOnHover
                actions={
                    <CSVLink
                    data = {student}
                    filename = "الطلاب المشاهدين للفيديو"
                    className = "btn btn-success mb-3 mt-4 me-2" > Export File Excel </CSVLink>
                 
                  
               }
           
                subHeader
                subHeaderComponent={
                  <>
                      <div className="total-search">
                  <h3 className="total-student mb-4"> <span>{totalStudent}</span> : اجمالي عدد الطلاب  اللي شافو الفيديو  </h3>
                      <input type="text" className="form-control mt-4 mb-4 w-100" placeholder="Search of Student or ID" onChange={handleSearch} /> 
                      </div>
                  </>
                
                }
                
                />
              </div>
                                          </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
    )
}

export default StudentVideo;

