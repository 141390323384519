import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import '../../styles/addquiz.css'
import SidebarAdminMain from "../SidebarAdminMain";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../App";






function EditQuiz () {
  

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
});

// const {id} = useParams();
const getQuiz = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/quizzes/${id}` , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
          },
        });
        // console.log(response.data.data.map(item => item.id))
        // console.log(response.data.data.attributes);
        // setQuestion(response.data.data)
        // console.log(response);

        setEdit(response.data.data.attributes);
        // setFilteredData(response.data.data);
        const data = response.data.data;
                 return data;
    } catch (error) {
        // console.log(error);
    }
}

useEffect (() =>{
    getQuiz();
}, [])

// const handleInputChange = (event) => {
//   const { name, value } = event.target;
//   setEdit({ ...edit, [name]: value });
// };


const { id } = useParams();
const [edit, setEdit] = useState({
    week_id: "",
    title: "",
    cdn: "",
    minutes: "",
    degree: "",
    mindegree: "",
    prize:"",
    prizeDegree:"",
    noquestions: "",
    answerTime: "",
    start: "",
    end: "",
    level: "",
});
const [errors, setErrors] = useState({});

const navigate = useNavigate();

const handleEdit = async (event) => {
  event.preventDefault();
  try {
    await axiosInstance.patch(
      `/api/quizzes/${id}`,
      {
        ...edit,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "ngrok-skip-browser-warning": "any",
        },
      }
    );
    setEdit({
        week_id: "",
        title: "",
        cdn: "",
        minutes: "",
        degree: "",
        mindegree: "",
        prize:"",
        prizeDegree:"",
        noquestions: "",
        answerTime: "",
        start: "",
        end: "",
        level: "",
    });
    swal({
      title: "تم تعديل الكويز بنجاح!",
      icon: "success",
      button: "حسناّ!",
    });
    navigate("/admin/quizdata");

  } catch (e) {
    if (e.response.status === 500) {
    
   
      // console.log(e);
      setErrors(e.response.data.errors);
    }
  }
};

 
      // console.log(edit);  
    

const [quiz , setQuiz] = useState([]);
const getEditQuiz = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axiosInstance.get('/api/weeks-selection' , {
        headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "any",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.data.map(item => item.id))
      setQuiz(response.data.data)
      const data = response.data.data;
               return data;
  } catch (error) {
      // console.log(error);
  }
}

useEffect (() =>{
  getEditQuiz();
}, [])









    return (
        <>
                  <div className="d-flex">
    
       <div className="add-quiz ">
      
      <div className="main-title"> تعديل كويز رقم {id}  </div>
    
      <Container>
    
          <Row>
         
          <Col lg={12}>
                     <div className="quiz-content">
                       <div className="codes pt-5">
                      
                        <div className="create-box">
                        <h2 className="create mb-4">  اضافة الكويز </h2>
                        <Form onSubmit={handleEdit}>
        

     <div className="chooices d-lg-flex align-items-center mt-5">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText  ">
        <Form.Label className="ms-2">اسم الكويز</Form.Label>
        <Form.Control type="text" placeholder="ادخل اسم الكويز" name="title" value={edit.title}  onChange={(e) => setEdit((prev) => ({ ...prev, title: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> وقت الكويز</Form.Label>
        <Form.Control type="number" placeholder="ادخل وقت الكويز" name="minutes" value={edit.minutes} onChange={(e) => setEdit((prev) => ({ ...prev, minutes: e.target.value }))}/>
      </Form.Group>
      </div>
     {/* <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> درجة الكويز</Form.Label>
        <Form.Control type="number" placeholder="ادخل درجة الكويز" name="degree" onChange={(e)=>{setDegree(e.target.value)}}/>
      </Form.Group>
      <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2">  الدرجة الصغري</Form.Label>
        <Form.Control type="number" placeholder="ادخل الدرجة الصغري" name="mindegree" onChange={(e)=>{setDegree(e.target.value)}}/>
      </Form.Group>
      </div> */}
      
     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> الدرجة الصغري</Form.Label>
        <Form.Control type="number" placeholder=" ادخل   الدرجة الصغري للكويز" value={edit.mindegree} name="mindegree" onChange={(e) => setEdit((prev) => ({ ...prev, mindegree: e.target.value }))}/>
        {errors.minutes && <div className="error mt-3">{errors.minutes[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 group " controlId="formBasicText  ">
        <Form.Label className="ms-2"> الدرجة العظمي</Form.Label>
        <Form.Control type="number" placeholder=" ادخل الدرجة العظمي للكويز " value={edit.degree} name="degree" onChange={(e) => setEdit((prev) => ({ ...prev, degree: e.target.value }))}/>
        {errors.degree && <div className="error mt-3">{errors.degree[0]}</div>}
      </Form.Group>
      </div>
      <div className="chooices d-lg-flex align-items-center">

      <Form.Group className="mb-3 me-lg-4 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> عدد الأسئلة</Form.Label>
        <Form.Control type="number" placeholder="ادخل عدد الأسئلة" value={edit.noquestions}  name="noquestions" onChange={(e) => setEdit((prev) => ({ ...prev, noquestions: e.target.value }))}/>
        {errors.end && <div className="error mt-3">{errors.end[0]}</div>}
      </Form.Group>
      <Form.Group controlId="formFile" className="group mt-3">
      <Form.Select aria-label="Default select example" name="answerTime" value={edit.answerTime}  onChange={(e) => setEdit((prev) => ({ ...prev, answerTime: e.target.value }))}>
      <option> اختر معاد ظهور  الإجابات</option>
      <option value="0">بعد   انهاء الطالب للامتحان فورا</option>
      <option value="1">  بعد وقت الكويز كامل</option>
    </Form.Select>
          {errors.answerTime && <div className="error mt-3">{errors.answerTime[0]}</div>}

      </Form.Group>
      </div>

      <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> جائزة الأوائل</Form.Label>
        <Form.Control type="text" placeholder=" ادخل جائزة الأوائل  " name="prize" value={edit.prize} onChange={(e) => setEdit((prev) => ({ ...prev, prize: e.target.value }))}/>
        {errors.prize && <div className="error mt-3">{errors.prize[0]}</div>}

      </Form.Group>
     <Form.Group className="mb-3 group " controlId="formBasicText  ">
        <Form.Label className="ms-2"> الحد الأدني لدرجة الأوائل</Form.Label>
        <Form.Control type="text" placeholder=" ادخل الحد الأدني لدرجة الأوائل "  value={edit.prizeDegree} name="prizeDegree" onChange={(e) => setEdit((prev) => ({ ...prev, prizeDegree: e.target.value }))}/>
        {errors.prizeDegree && <div className="error mt-3">{errors.prizeDegree[0]}</div>}
      </Form.Group>
      </div>


     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ البداية</Form.Label>
        <Form.Control type="datetime-local"   name="start" value={edit.start}  onChange={(e) => setEdit((prev) => ({ ...prev, start: e.target.value }))}/>
      </Form.Group>
     <Form.Group className="mb-3 group" controlId="formBasicText ">
        <Form.Label className="ms-2"> تاريخ النهاية</Form.Label>
        <Form.Control type="datetime-local"  name="end" value={edit.end}  onChange={(e) => setEdit((prev) => ({ ...prev, end: e.target.value }))}/>
      </Form.Group>
      </div>
   
     <div className="chooices d-lg-flex align-items-center">
     <Form.Group className="mb-3 group me-lg-4" controlId="formBasicText ">
     <Form.Select aria-label="Default select example" name="week_id" value={edit.week_id} onChange={(e) => setEdit((prev) => ({ ...prev, week_id: e.target.value }))}> 
      <option>اختر رقم الحصة  </option>
      {
        quiz.map(item => (
          <option key={item.id} value={item.id} name="week_id">{item.name}</option>
        ))
      }
    </Form.Select>
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3 group ">
      <Form.Select aria-label="Default select example" name="level" value={edit.level}  onChange={(e) => setEdit((prev) => ({ ...prev, level: e.target.value }))}>
      <option> اختر السنه الدراسية</option>
      <option value="1">الصف الأول الثانوي</option>
      <option value="2">الصف الثاني الثانوي</option>
      <option value="3">الصف الثالث الثانوي</option>
    </Form.Select>
      </Form.Group>
    
      </div>
   
   


   
  
    
              
      <Button variant="primary" type="submit" className="create-button">
        تعديل
      </Button>
    </Form>
                        </div>
                  
                        </div> 
              </div>
                   </Col>
          </Row>
      </Container>

    
      </div>
      <div className="sidebar">
       <SidebarAdminMain />
       </div>
       </div>
        </>
        
    )
}

export default EditQuiz;


